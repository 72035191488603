export const MONTH_COUNT = 36;

export const PROJECT = 1;
export const SCENARIO = 1;

export const CONTRACTOR_HOURS = 40;

export const locations = {
  login: "/auth/login",
  signup: "/auth/signup",
  home: "/",
};

export const GENERAL_VIEW = "general_view";
export const PAYROLL_VIEW = "payroll_view";
export const DEPARTMENT_VIEW = "department_view";
export const DEBT_DETAILS_VIEW = "debt_details_view";
export const SUMMARY_VIEW = "summary_view";

export type ViewType =
  | typeof GENERAL_VIEW
  | typeof PAYROLL_VIEW
  | typeof DEPARTMENT_VIEW
  | typeof DEBT_DETAILS_VIEW
  | typeof SUMMARY_VIEW;

export const STORAGE_CREDENTIALS = "credentials";

export const EMAIL = import.meta.env.VITE_EMAIL ?? "example@example.org";
