import { useState } from "react";
import { format, endOfQuarter, endOfMonth, endOfYear, isEqual } from "date-fns";
import { formatSalary } from "../../utils/format";
import { CollapseIcon } from "../../assets";
import { Line, LineVariants, Months, TableLineProps } from "./types";
import { getPeriods, groupMonths } from "./utils";
import { variantDataStyles, variantNameStyles } from "./styles";

export type Periods = {
  period: string;
  year: string;
  isLast: boolean;
  colorIndex: number;
}[];

export const HeaderCaption = ({ caption }: { caption: string }) => {
  return (
    <th
      scope="col"
      className="sticky left-0 w-60 border bg-gray-200 py-2 pl-8 text-left text-xs font-semibold leading-none tracking-tight text-slate-700"
    >
      {caption}
    </th>
  );
};

export const HeaderPeriodSimple = ({
  period,
  year,
  colorIndex = 2,
  isLast,
  width = "w-10",
}: {
  period: string;
  year: string;
  colorIndex?: number;
  isLast?: boolean;
  width?: string;
}) => {
  return (
    <th
      scope="col"
      className={`border pr-2 ${
        colorIndex === 1
          ? "bg-header2"
          : colorIndex === 2
            ? "bg-header3"
            : "bg-header1"
      }
      ${width}
      ${isLast ? "border-r-2 border-r-red-950" : ""}`}
    >
      <div className="my-1 pl-2 text-right text-xs font-semibold leading-none tracking-tight text-slate-700">
        {period}
      </div>
      <div className="my-1 pl-2 text-right text-xs font-normal leading-none tracking-tight text-slate-500">
        {year}
      </div>
    </th>
  );
};

export const HeaderPeriod = ({
  month,
  showMonths,
  colorIndex = 2,
}: {
  month: Date;
  showMonths: boolean;
  colorIndex?: number;
}) => {
  const isLast = isEqual(
    showMonths ? endOfMonth(month) : endOfQuarter(month),
    endOfYear(month),
  );
  return (
    <HeaderPeriodSimple
      period={showMonths ? format(month, "MMM") : format(month, "QQQ")}
      year={format(month, "yyyy")}
      colorIndex={colorIndex}
      isLast={isLast}
    />
  );
};

export const HeaderWidePeriod = ({
  month,
  showMonths,
  colorIndex = 0,
}: {
  month: Date;
  showMonths: boolean;
  colorIndex?: number;
}) => {
  const isLast = isEqual(
    showMonths ? endOfMonth(month) : endOfQuarter(month),
    endOfYear(month),
  );
  return (
    <HeaderPeriodSimple
      period={showMonths ? format(month, "MMM") : format(month, "QQQ")}
      year={format(month, "yyyy")}
      colorIndex={colorIndex}
      isLast={isLast}
      width="w-28"
    />
  );
};

export const TableCellSimple = ({
  className,
  value,
  isLast,
}: {
  className: string;
  value: number;
  isLast: boolean;
}) => {
  return (
    <td
      className={`${className} ${isLast ? "border-r-2 border-r-red-950" : ""}`}
    >
      {value < 0 ? (
        <span className="text-red-600">{formatSalary(String(value))}</span>
      ) : (
        formatSalary(String(value))
      )}
    </td>
  );
};

export const TableCell = ({
  className,
  value,
  months,
  index,
  showMonths,
}: {
  className: string;
  value: number;
  months: {
    index: number;
    date: Date;
  }[];
  index: number;
  showMonths: boolean;
}) => {
  const isLast = isEqual(
    showMonths
      ? endOfMonth(months[index].date)
      : endOfQuarter(months[index].date),
    endOfYear(months[index].date),
  );
  return (
    <TableCellSimple className={className} value={value} isLast={isLast} />
  );
};

export const TableLineSimple = ({
  line,
  periods,
  isOpen = true,
  variant = "regular",
}: {
  line: Line;
  periods?: Periods;
  isOpen?: boolean;
  variant?: LineVariants;
}) => {
  return (
    <tr className={`${isOpen ? "" : "hidden"}`}>
      <td className={variantNameStyles[variant]}>{line.name}</td>
      {line.values.map((value, index) => (
        <TableCellSimple
          key={index}
          className={variantDataStyles[variant]}
          value={value}
          isLast={periods ? periods[index].isLast : false}
        />
      ))}
    </tr>
  );
};

export const TableLine = ({
  line,
  showMonths,
  months,
  isOpen = true,
  variant = "regular",
}: TableLineProps) => {
  const values = groupMonths(months, line.values);
  const periods = getPeriods(months, showMonths);

  return (
    <>
      <tr className={`${isOpen ? "" : "hidden"}`}>
        <td className={variantNameStyles[variant]}>{line.name}</td>
        {values.map((value, index) => (
          <TableCell
            key={index}
            className={variantDataStyles[variant]}
            value={value}
            months={periods}
            index={index}
            showMonths={showMonths}
          />
        ))}
      </tr>
      {line.children?.map((child) => (
        <TableLine
          key={child.name}
          line={child}
          showMonths={showMonths}
          months={months}
          variant={variant}
        />
      ))}
    </>
  );
};

export const GroupLineSimple = ({
  data,
  periods,
  isInitiallyOpen,
}: {
  data: Line;
  periods?: Periods;
  isInitiallyOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen ?? false);

  return (
    <>
      <tr>
        <td className="sticky left-0 overflow-visible border-y bg-white py-2">
          <button className="flex" onClick={() => setIsOpen((prev) => !prev)}>
            <CollapseIcon isOpen={isOpen} />
            {data.name}
          </button>
        </td>
        {data.values.map((value, index) => (
          <TableCellSimple
            key={index}
            className={variantDataStyles["group"]}
            value={value}
            isLast={periods ? periods[index].isLast : false}
          />
        ))}
      </tr>
      {data.children?.map((line, index) =>
        line.values.every((count) => count === 0) ? (
          <></>
        ) : line.children ? (
          <GroupLineSimple key={index} data={line} periods={periods} />
        ) : (
          <TableLineSimple
            key={index}
            line={line}
            periods={periods}
            isOpen={isOpen}
          />
        ),
      )}
    </>
  );
};

export const GroupLine = ({
  data,
  showMonths,
  months,
  isInitiallyOpen,
}: {
  data: Line;
  showMonths: boolean;
  months: Months;
  isInitiallyOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen ?? false);
  const values = groupMonths(months, data.values);
  const periods = getPeriods(months, showMonths);

  return (
    <>
      <tr>
        <td className="sticky left-0 overflow-visible border-y bg-white py-2">
          <button className="flex" onClick={() => setIsOpen((prev) => !prev)}>
            <CollapseIcon isOpen={isOpen} />
            {data.name}
          </button>
        </td>
        {values.map((value, index) => (
          <TableCell
            key={index}
            className={variantDataStyles["group"]}
            value={value}
            months={periods}
            index={index}
            showMonths={showMonths}
          />
        ))}
      </tr>
      {data.children?.map((line) =>
        line.values.every((count) => count === 0) ? (
          <></>
        ) : line.children ? (
          <GroupLine
            key={line.name}
            data={line}
            showMonths={showMonths}
            months={months}
          />
        ) : (
          <TableLine
            key={line.name}
            isOpen={isOpen}
            line={line}
            showMonths={showMonths}
            months={months}
          />
        ),
      )}
    </>
  );
};
