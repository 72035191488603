import { useEffect, useState } from "react";
import { Input } from "../Input";
import { EditInitialData } from "./EditInitialData";

type EditNameDialogProps = {
  isOpen: boolean;
  value: string;
  setIsOpen: (isOpen: boolean) => void;
  confirm: (value: string) => void;
};

function EditNameDialog({
  value,
  isOpen,
  setIsOpen,
  confirm,
}: EditNameDialogProps) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(
    function updateLocalValue() {
      setLocalValue(value);
    },
    [value, isOpen],
  );

  const handleConfirm = () => {
    confirm(localValue);
    setIsOpen(false);
  };

  return (
    <EditInitialData
      caption="Edit name"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={handleConfirm}
    >
      <div className="mt-4 flex w-[550px] items-center gap-2 p-5">
        <Input
          value={localValue}
          label="Enter new name"
          onChange={setLocalValue}
          type="text"
          id="newName"
          fullWidth
        />
      </div>
    </EditInitialData>
  );
}

export { EditNameDialog };
