import { SVGProps } from "react";
export const QuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="CurrentColor"
      d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm0 11.5A.75.75 0 1 1 8 11a.75.75 0 0 1 0 1.5Zm.571-3.877v1.25H7.446V7.5h1.063a1.188 1.188 0 1 0 0-2.377h-.75A1.189 1.189 0 0 0 6.57 6.311v.319H5.446v-.319A2.314 2.314 0 0 1 7.759 4h.75a2.312 2.312 0 0 1 .062 4.623Z"
    />
  </svg>
);
