import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../../components/Button";
import { useSignupMutation } from "../../services/patric";
import { Input } from "../../components/Input";
import { useEffect, useState } from "react";
import { isBackendError } from "../../models/utils";

export const Signup = () => {
  const { user } = useAuth();
  const [doSignup, result] = useSignupMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (result.isSuccess) {
      setSuccessMessage(
        "Account created! Please check your email for activation link"
      );
    } else if (
      result.isError &&
      isBackendError(result.error)
    ) {
      setErrorMessage(result.error.data.detail);
    }
  }, [result]);

  if (user) {
    navigate("/home");
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isProcessing) {
      return;
    }
    setIsProcessing(true);

    const name = (event.target as any).name.value;
    const username = (event.target as any).username.value;
    const password = (event.target as any).password.value;
    const passwordRepeat = (event.target as any).passwordRepeat.value;

    if (!username || !password || !passwordRepeat || !name) {
      setErrorMessage("Please fill all fields");
      setIsProcessing(false);
      return;
    }

    const [firstname, lastname] = name.split(" ");

    if (password !== passwordRepeat) {
      setErrorMessage("Passwords don't match");
      setIsProcessing(false);
      return;
    }

    await doSignup({ username, password, firstname, lastname });
    setIsProcessing(false);
  };

  return (
    <div className="max-h-screen">
      <div className="flex items-center justify-between">
        <div className="w-64">
          <img src={logo} alt="Logo" />
        </div>
        <div>
          Have an account?{" "}
          <Link className="text-blue-700 cursor-pointer" to={"/auth/login"}>
            Login
          </Link>
        </div>
      </div>
      <h1 className="mt-16 text-3xl">Create your account!</h1>
      <p className="mt-4">Let's count your business</p>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6">
          <Input type="text" id="name" label="Name" fullWidth />

          <Input type="text" id="username" label="Email" fullWidth />

          <Input type="password" id="password" label="Password" fullWidth />

          <Input
            type="password"
            id="passwordRepeat"
            label="Repeat password"
            fullWidth
          />
        </div>
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        {successMessage && (
          <div className="text-green-500">{successMessage}</div>
        )}
        <div className="mt-10 w-full flex flex-col gap-10">
          <Button type="submit" width="w-full" isDisabled={isProcessing}>
            {isProcessing ? "Processing..." : "Sign up"}
          </Button>
          <Button type="button" style="outline" width="w-full" onClick={() => navigate('/')}>Cancel</Button>
        </div>
      </form>
    </div>
  );
};
