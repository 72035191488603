import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/patric";
import { PlusSquareIcon } from "../../assets/icons/PlusSquareIcon";
import { Logo } from "../../assets/Logo";
import { UserMenu } from "./UserMenu";

export const ProjectsHeader = () => {
  const { data } = useGetProjectsQuery();
  const { pid } = useParams();
  const navigate = useNavigate();

  const handleCreateScenario = () => {
    navigate("/new");
  };

  return (
    <div className="flex w-full flex-row items-center border-b px-10">
      <div className="flex w-44">
        <Link to="/home"><Logo /></Link>
      </div>
      <div className="flex-grow items-center">
        <div className="flex gap-2">
          {data &&
            data.map((project) => (
              <div key={project.project_id}>
                <NavLink
                  to={`/${project.project_id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "inline-flex h-16 w-32 items-center justify-center gap-1 border-b-4 border-normal p-2.5 text-primaryText"
                      : "inline-flex h-16 w-32 items-center justify-center gap-1 p-2.5 text-placeholderText"
                  }
                >
                  <span className="text-center text-base font-semibold leading-normal">
                    {project.project_name}
                  </span>
                </NavLink>
              </div>
            ))}

          {pid !== "new" ? (
            <button onClick={handleCreateScenario} className="flex items-center">
              <div className="flex items-center rounded-md pr-2 border-normal border-2">
                <PlusSquareIcon fill="white" color="#343B58" />
                <span className="font-semibold">Add new project</span></div>
            </button>
          ) : (
            <div className="inline-flex h-16 w-32 items-center justify-center gap-1 border-b-4 border-normal p-2.5">
              <span className="text-center text-base font-semibold leading-normal text-gray-800">
                New project
              </span>
            </div>
          )}
        </div>
      </div>
      <UserMenu />
    </div>
  );
};
