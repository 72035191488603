import { ReactNode } from "react";
import { QuestionMark } from "../../assets";

export const Helper = ({ children, header }: { children: ReactNode, header: string }) => {
  return (
    <div className="mt-9 max-w-5xl rounded-md border border-dashed border-successNormal p-3">
      <div className="flex w-max items-center justify-between gap-1 py-1 text-successNormal">
        <QuestionMark width={24} height={24} viewBox="0 0 16 16" />
        <h1 className="text-primaryText font-semibold">{header}</h1>
      </div>
      <div className="p-3 pl-6 pt-0 text-justify">{children}</div>
    </div>
  );
};
