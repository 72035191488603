import { Outlet, useNavigate } from "react-router-dom";

export const AuthLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 z-50 flex inset-0 flex-row justify-center bg-gray-950 bg-opacity-65 overflow-y-scroll" onClick={() => navigate("/")}>
      <div className="flex h-fit max-w-6xl flex-row justify-center bg-gray-400 static" onClick={(e) => e.stopPropagation()}>
        <div className="flex items-stretch">
          <div className="w-full bg-white px-16 lg:w-2/4">
            <Outlet />
          </div>
          <div className="hidden lg:block">
            <img src="/login_back.png" />
          </div>
        </div>
      </div>
    </div>
  )
};
