import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  useCreateScenarioMutation,
  useGetScenariosQuery,
} from "../../services/patric";
import {
  DEBT_DETAILS_VIEW,
  DEPARTMENT_VIEW,
  GENERAL_VIEW,
  PAYROLL_VIEW,
  SUMMARY_VIEW,
} from "../../const";
import { FolderIcon, SettingsIcon } from "../../assets";
import { Pages } from "./Pages";
import { Button } from "../Button";
import { PlusCircleIcon } from "../../assets/icons/PlusCircleIcon";
import { useError } from "../../hooks/useError";
import { isBackendError } from "../../models/utils";

export const Sidebar = () => {
  const { pid, sid } = useParams();
  const { data } = useGetScenariosQuery(
    { projectID: Number(pid) },
    {
      skip: isNaN(Number(pid)),
    },
  );
  const [createScenario] = useCreateScenarioMutation();
  const { handleError } = useError({ overall: "" });
  const navigate = useNavigate();

  const pathWithoutLastPart = `${pid}/${sid}`;

  const pages = [
    {
      name: "General info",
      path: `${pathWithoutLastPart}/general`,
      view: GENERAL_VIEW,
    },
    {
      name: "Payroll",
      path: `${pathWithoutLastPart}/payroll`,
      view: PAYROLL_VIEW,
    },
    {
      name: "Department expenses",
      path: `${pathWithoutLastPart}/depexp`,
      view: DEPARTMENT_VIEW,
    },
    {
      name: "Debt repayment",
      path: `${pathWithoutLastPart}/debt`,
      view: DEBT_DETAILS_VIEW,
    },
    {
      name: "Summary",
      path: `${pathWithoutLastPart}/summary`,
      view: SUMMARY_VIEW,
    },
  ];

  const currentScenario = data?.find(
    (value) => value.scenario_id === Number(sid),
  );

  const currentPathId = pages.findIndex(
    (value) => currentScenario?.scenario_view === value.view,
  );

  const handleAddScenario = async () => {
    const scenarioName = `Scenario #${(data?.length || 1) + 1}`;
    if (scenarioName) {
      try {
        const result = await createScenario({ project_id: Number(pid), scenario_name: scenarioName }).unwrap();
        navigate(`${pid}/${result.scenario_id}`)
      } catch (error) {
        console.error(error);
        if (isBackendError(error)) {
          handleError("overall", error.data.detail);
        } else {
          handleError("overall", "Can't create scenario");
        }
      }
    }
  };

  return (
    <div className="pl-6 pt-12 flex w-64 flex-shrink-0 flex-col bg-secondaryBackground">
      <Link to={`/${pid}`} className="flex items-start gap-2 font-bold">
        <SettingsIcon />
        Project settings
      </Link>
      <div className="my-8 pr-6">
        <Button onClick={handleAddScenario} style="outline" width="w-full">
          <div className="flex items-center justify-center gap-2">
            <PlusCircleIcon />
            Add scenario
          </div>
        </Button>
      </div>
      {data?.map((scenario) => (
        <div key={scenario.scenario_id} className="my-4">
          <NavLink
            to={`${pid}/${scenario.scenario_id}`}
            key={scenario.scenario_id}
            className="flex items-center"
          >
            <FolderIcon />
            <span className="ml-2 font-semibold">{scenario.scenario_name}</span>
          </NavLink>
          {scenario.scenario_id === Number(sid) && (
            <div className="ml-6 mt-4">
              <Pages pages={pages} currentPathId={currentPathId} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
