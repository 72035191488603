import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import {
  Department,
  IHpPosition,
  IHpPositionEmployee,
} from "../../models/department";
import { DepartmentPlan } from "../../components/DepartmentPlan";
import { HeadcountTable } from "../../components/HeadcountTable";
import { Button } from "../../components/Button";
import { TotalHeadcountTable } from "../../components/TotalHeadcountTable/TotalHeadcountTable";
import { TotalPayrollTable } from "../../components/TotalPayrollTable";

import { fillHiringPlan, headcountToHiringPlan } from "./utils";
import { PeriodSelector } from "../../components/PeriodSelector/PeriodSelector";
import { Tip } from "../../components/Tip";
import { Caption } from "../../components/Caption";
import {
  useGetDepartmentsQuery,
  useGetHiringPlanQuery,
  useGetProjectQuery,
  useGetRolesQuery,
  useGetSalariesQuery,
  useSaveHiringPlanMutation,
  useUpdateStepMutation,
} from "../../services/patric";
import { Section } from "../../components/Section";
import { DEPARTMENT_VIEW, MONTH_COUNT } from "../../const";
import { convertStringToDate } from "../../utils/convert";
import { useError } from "../../hooks/useError";
import { isBackendError } from "../../models/utils";
import { Alert } from "../../components";
import { EditInitialData } from "../../components/Dialog/EditInitialData";
import { getMonths } from "../../components/Table";

export const Payroll = () => {
  const { pid, sid } = useParams<{ pid: string; sid: string }>();
  const { data: projectData } = useGetProjectQuery(Number(pid));
  const { data: departmentsData } = useGetDepartmentsQuery(Number(sid));
  const { data: rolesData } = useGetRolesQuery();
  const { data: salariesData } = useGetSalariesQuery(Number(sid));
  const { data: hiringPlanBackend } = useGetHiringPlanQuery(Number(sid));
  const [saveHiringPlan] = useSaveHiringPlanMutation();
  const [updateScenario] = useUpdateStepMutation();

  const navigate = useNavigate();

  const [isDepartmentOpen, setIsDepartmentOpen] = useState<
    Record<string, boolean>
  >({});
  const [hiringPlan, setHiringPlan] = useState<Department[]>([]);
  const [hpPositions, setHpPositions] = useState<IHpPosition[]>([]);
  const [hpEmployees, setHpEmployees] = useState<IHpPositionEmployee[]>([]);

  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [isTotalEnabled, setIsTotalEnabled] = useState(true);

  const [showMonthHeadcount, setShowMonthHeadcount] = useState<boolean>(true);
  const [showMonthPayroll, setShowMonthPayroll] = useState<boolean>(true);
  const [showMonthTotal, setShowMonthTotal] = useState<boolean>(true);

  const { error, handleError } = useError({ overall: "" });

  const [employeeDialogData, setEmployeeDialogData] = useState({
    id: 0,
    count: 0,
    startMonth: 0,
    endMonth: 0,
  });
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);

  useEffect(() => {
    if (!departmentsData || !rolesData) {
      return;
    }

    const hiringPlanDepartments = fillHiringPlan(
      departmentsData,
      rolesData,
      hiringPlanBackend,
    );
    setHiringPlan(hiringPlanDepartments);
  }, [departmentsData, rolesData, hiringPlanBackend]);

  useEffect(() => {
    const fetchData = async () => {
      if (!departmentsData || !rolesData || !salariesData) {
        return;
      }

      // Fullfill positions as separate array
      let hpPositions: IHpPosition[] = [];
      for (const role of rolesData) {
        const salary = salariesData.find((value) => value.role === role.role);
        const position: IHpPosition = {
          role_id: role.id,
          role_name: role.role,
          department_id:
            departmentsData.find((value) => value.name === role.department)
              ?.id ?? 0,
          custom: false,
          annual: salary?.annual ?? 0,
          fulltime: {
            annual: salary?.full_time_annual ?? 0,
            monthly: salary?.full_time_monthly ?? 0,
          },
          freelance: {
            annual: salary?.freelancer_annual ?? 0,
            monthly: salary?.freelancer_monthly ?? 0,
          },
          contractor: {
            annual: salary?.contractor_monthly ?? 0,
            monthly: salary?.contractor_hourly ?? 0,
          },
        };
        hpPositions.push(position);
      }

      if (!hiringPlanBackend) {
        setHpPositions(hpPositions);
        return;
      }

      if (hiringPlanBackend.custom_roles)
        for (const role of hiringPlanBackend.custom_roles) {
          const salary = salariesData.find(
            (value) => value.role === role.role_name,
          );
          const position: IHpPosition = {
            role_id: role.role_id,
            role_name: role.role_name,
            department_id: role.department_id,
            custom: true,
            annual: salary?.annual ?? 0,
            fulltime: {
              annual: salary?.full_time_annual ?? 0,
              monthly: salary?.full_time_monthly ?? 0,
            },
            freelance: {
              annual: salary?.freelancer_annual ?? 0,
              monthly: salary?.freelancer_monthly ?? 0,
            },
            contractor: {
              annual: salary?.contractor_monthly ?? 0,
              monthly: salary?.contractor_hourly ?? 0,
            },
          };
          hpPositions.push(position);
        }

      setHpPositions(hpPositions);

      let hpEmployeeMaxCount = 1;
      // Fullfill employees as separate array
      const hpEmployees: IHpPositionEmployee[] = [];
      if (hiringPlanBackend.full_times)
        for (const pos of hiringPlanBackend.full_times) {
          const role = hpPositions.find(
            (value) => value.role_id === pos.role_id,
          );
          if (!role) continue;
          let employee = hpEmployees.find(
            (value) =>
              value.role_id === pos.role_id &&
              value.typeOfEmployment === "Full time",
          );
          if (!employee) {
            employee = {
              id: hpEmployeeMaxCount++,
              role_id: pos.role_id,
              role_name: role.role_name,
              department_id: role.department_id,
              typeOfEmployment: "Full time",
              numberOfEmployees: 0,
              count: Array(MONTH_COUNT).fill(0),
            };
            hpEmployees.push(employee);
          }

          employee.count[pos.month - 1] = pos.count;
          employee.numberOfEmployees = pos.count;
        }

      if (hiringPlanBackend.freelancers)
        for (const pos of hiringPlanBackend.freelancers) {
          const role = hpPositions.find(
            (value) => value.role_id === pos.role_id,
          );
          if (!role) continue;
          let employee = hpEmployees.find(
            (value) =>
              value.role_id === pos.role_id &&
              value.typeOfEmployment === "Freelance",
          );
          if (!employee) {
            employee = {
              id: hpEmployeeMaxCount++,
              role_id: pos.role_id,
              role_name: role.role_name,
              department_id: role.department_id,
              typeOfEmployment: "Freelance",
              numberOfEmployees: 0,
              count: Array(MONTH_COUNT).fill(0),
            };
            hpEmployees.push(employee);
          }

          employee.count[pos.month - 1] = pos.count;
          employee.numberOfEmployees = pos.count;
        }

      if (hiringPlanBackend.contractors)
        for (const pos of hiringPlanBackend.contractors) {
          const role = hpPositions.find(
            (value) => value.role_id === pos.role_id,
          );
          if (!role) continue;
          let employee = hpEmployees.find(
            (value) =>
              value.role_id === pos.role_id &&
              value.typeOfEmployment === "Contract",
          );
          if (!employee) {
            employee = {
              id: hpEmployeeMaxCount++,
              role_id: pos.role_id,
              role_name: role.role_name,
              department_id: role.department_id,
              typeOfEmployment: "Contract",
              numberOfEmployees: 0,
              count: Array(MONTH_COUNT).fill(0),
            };
            hpEmployees.push(employee);
          }

          employee.count[pos.month - 1] = pos.count;
          employee.numberOfEmployees = pos.count;
        }

      for (const employee of hpEmployees) {
        let last = 0;
        for (let i = 0; i < MONTH_COUNT; i++) {
          if (!employee.count[i]) {
            employee.count[i] = last;
          } else {
            last = employee.count[i];
          }
        }
      }
      setHpEmployees(hpEmployees);
    };

    fetchData().catch((error) => {
      console.error(error);
    });
  }, [sid, departmentsData, rolesData, hiringPlanBackend, salariesData]);

  const handleDepartmentClick = (departmentName: string) => () => {
    setIsDepartmentOpen((prev) => {
      const isOpen = prev[departmentName] ?? false;
      return {
        ...prev,
        [departmentName]: !isOpen,
      };
    });
  };

  const handleExpandAll = () => {
    const allOpen = hiringPlan.reduce(
      (acc, department) => {
        acc[department.name] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setIsDepartmentOpen(allOpen);
  };

  const handleShowTotal = async () => {
    const body = headcountToHiringPlan(hpEmployees, hpPositions);
    try {
      await saveHiringPlan({ scenarioId: Number(sid), body }).unwrap();
      setShowTotal(true);
    } catch (error) {
      if (isBackendError(error)) {
        handleError("overall", error.data.detail);
      } else {
        handleError("overall", "Error saving data");
      }
    }
    setIsTotalEnabled(false);
  };

  const handleNext = async () => {
    const body = headcountToHiringPlan(hpEmployees, hpPositions);
    try {
      await saveHiringPlan({ scenarioId: Number(sid), body }).unwrap();
      await updateScenario({ scenarioId: sid!, step: DEPARTMENT_VIEW });
      navigate("../depexp");
    } catch (error) {
      if (isBackendError(error)) {
        handleError("overall", error.data.detail);
      } else {
        handleError("overall", "Error saving data");
      }
    }
  };

  const handlePositionChange = (position: IHpPositionEmployee): void => {
    if (position.id === 0) {
      const maxEmployeeId = Math.max(...hpEmployees.map((empl) => empl.id));
      position.id = maxEmployeeId + 1;
      const newEmployees = [...hpEmployees, position];
      setHpEmployees(newEmployees);
    } else {
      const newEmployees = hpEmployees.map((pos) =>
        pos.id === position.id ? position : pos,
      );
      setHpEmployees(newEmployees);
    }
    setIsTotalEnabled(true);
  };

  const handlePositionAdd = (position: IHpPosition) => {
    const maxEmployeeId = Math.max(...hpEmployees.map((empl) => empl.id));
    const newEmployee: IHpPositionEmployee = {
      id: maxEmployeeId + 1,
      role_id: position.role_id,
      role_name: position.role_name,
      department_id: position.department_id,
      typeOfEmployment: "Full time", // TODO: detect missing type
      numberOfEmployees: 0,
      count: Array(MONTH_COUNT).fill(0),
    };
    const newEmployees = [...hpEmployees, newEmployee];
    setHpEmployees(newEmployees);
    setIsTotalEnabled(true);
  };

  const handleCustomPositionDelete = (position: IHpPosition): void => {
    if (hpPositions.find((pos) => pos.role_id === position.role_id)) {
      const newPositions = hpPositions.map((pos) =>
        pos.role_id === position.role_id ? position : pos,
      );
      setHpPositions(newPositions);
      const newEmployees = hpEmployees.map((empl) =>
        empl.role_id === position.role_id
          ? { ...empl, role_name: position.role_name }
          : empl,
      );
      setHpEmployees(newEmployees);
    } else {
      const newPositions = [...hpPositions, position];
      setHpPositions(newPositions);
      const maxEmployeeId =
        hpEmployees.length > 0
          ? Math.max(...hpEmployees.map((empl) => empl.id))
          : 1;
      const newEmployee: IHpPositionEmployee = {
        id: maxEmployeeId + 1,
        role_id: position.role_id,
        role_name: position.role_name,
        department_id: position.department_id,
        typeOfEmployment: "Full time",
        numberOfEmployees: 0,
        count: Array(MONTH_COUNT).fill(0),
      };
      const newEmployees = [...hpEmployees, newEmployee];
      setHpEmployees(newEmployees);
    }
    setIsTotalEnabled(true);
  };

  const handleEmployeeNumberChange =
    (typeOfEmployment: string) =>
    (value: number, index: number, role: string, department: string) => {
      const employee = hpEmployees.find(
        (empl) =>
          empl.role_name === role && empl.typeOfEmployment === typeOfEmployment,
      );
      if (!employee || !department) {
        return;
      }

      setEmployeeDialogData({
        id: employee.id,
        count: value,
        startMonth: index,
        endMonth: MONTH_COUNT - 1,
      });
      setEmployeeDialogOpen(true);
    };

  const handleEmployeeNumberChangeSubmit = async () => {
    const newEmployee = hpEmployees.map((empl) => {
      if (empl.id === employeeDialogData.id) {
        empl.count = empl.count.fill(
          employeeDialogData.count,
          employeeDialogData.startMonth,
          employeeDialogData.endMonth + 1,
        );
      }
      return empl;
    });
    setHpEmployees(newEmployee);
    setEmployeeDialogOpen(false);
    setIsTotalEnabled(true);
  };

  if (!projectData) {
    return <div>Loading...</div>;
  }

  const startDate = convertStringToDate(projectData.start_month);
  const months = getMonths(startDate, true, MONTH_COUNT);

  return (
    <>
      <div className="mt-8 flex flex-col gap-8">
        <Caption noMargin>Payroll</Caption>
        <Tip caption="Task №3">
          <p className="font-semibold">
            Indicate the roles you would like to hire and whether they will be
            full-time employees, freelancers, or contractors
          </p>
          <ul className="mt-2 list-inside list-disc text-sm font-normal">
            <li>
              Full time - requires standard benefits such as health insurance
            </li>
            <li>
              Freelancer - works full-time but handles their own benefits, so
              they are cheaper
            </li>
            <li>
              Contractor - works a pre-determined number of hours per month
              without benefits
            </li>
          </ul>
        </Tip>
        <div className="w-full">
          <Section
            title="Hiring plan"
            button={
              <button className="text-normal" onClick={handleExpandAll}>
                Expand all
              </button>
            }
          >
            <div className="w-full">
              {salariesData && (
                <table className="w-full table-auto">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="sticky top-0 z-20 bg-gray-200 pl-7 text-left text-xs font-semibold leading-none tracking-tight text-slate-700">
                        Roles
                      </th>
                      <th className="sticky top-0 z-20 bg-gray-200 py-2 text-xs font-semibold leading-none tracking-tight text-slate-700">
                        Annual, USD
                      </th>
                      <th className="sticky top-0 z-20 bg-gray-200 py-2">
                        <div className="text-xs font-semibold leading-none tracking-tight text-slate-700">
                          Full-time, USD
                        </div>
                        <div className="text-xs font-normal leading-none tracking-tight text-slate-500">
                          month / annual
                        </div>
                      </th>
                      <th className="sticky top-0 z-20 bg-gray-200 py-2">
                        <div className="text-xs font-semibold leading-none tracking-tight text-slate-700">
                          Freelancer, USD
                        </div>
                        <div className="text-xs font-normal leading-none tracking-tight text-slate-500">
                          month / annual
                        </div>
                      </th>
                      <th className="sticky top-0 z-20 bg-gray-200 py-2">
                        <div className="text-xs font-semibold leading-none tracking-tight text-slate-700">
                          Contractor, USD
                        </div>
                        <div className="text-xs font-normal leading-none tracking-tight text-slate-500">
                          hour / month
                        </div>
                      </th>
                      <th className="sticky top-0 z-20 bg-gray-200 py-2 text-xs font-semibold leading-none tracking-tight text-slate-700">
                        Type of employment
                      </th>
                      {/* <th className="sticky top-0 z-20 w-24 bg-gray-200 py-2 text-xs font-semibold leading-none tracking-tight text-slate-700">
                        Number of employees
                      </th> */}
                      <th className="sticky top-0 z-20 bg-gray-200">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hiringPlan.map((department) => {
                      if (!department.active) {
                        return false;
                      }
                      return (
                        <DepartmentPlan
                          key={department.name}
                          department={department}
                          hpPositions={hpPositions.filter(
                            (pos) => pos.department_id === department.id,
                          )}
                          hpEmployees={hpEmployees.filter(
                            (empl) => empl.department_id === department.id,
                          )}
                          isOpen={isDepartmentOpen[department.name] ?? false}
                          onClick={handleDepartmentClick(department.name)}
                          onPositionChange={handlePositionChange}
                          onPositionAdd={handlePositionAdd}
                          onPositionDelete={(position) => {
                            const newEmployees = hpEmployees.filter(
                              (pos) => pos.id !== position.id,
                            );
                            setHpEmployees(newEmployees);
                            setIsTotalEnabled(true);
                          }}
                          onCustomPositionChange={handleCustomPositionDelete}
                          onCustomPositionDelete={(position) => {
                            const newPositions = hpPositions.filter(
                              (pos) => pos.role_id !== position.role_id,
                            );
                            setHpPositions(newPositions);
                            const newEmployees = hpEmployees.filter(
                              (empl) => empl.role_id !== position.role_id,
                            );
                            setHpEmployees(newEmployees);
                            setIsTotalEnabled(true);
                          }}
                          onCustomPositionAdd={() => {
                            const maxRoleId = Math.max(
                              ...hpPositions.map((pos) => pos.role_id),
                            );
                            const newRole: IHpPosition = {
                              role_id: maxRoleId + 1,
                              role_name: "",
                              department_id: department.id,
                              custom: true,
                              annual: 0,
                              fulltime: {
                                annual: 0,
                                monthly: 0,
                              },
                              freelance: {
                                annual: 0,
                                monthly: 0,
                              },
                              contractor: {
                                annual: 0,
                                monthly: 0,
                              },
                            };
                            const newPositions = [...hpPositions, newRole];
                            setHpPositions(newPositions);
                            const maxEmployeeId = Math.max(
                              ...hpEmployees.map((empl) => empl.id),
                            );
                            const newEmployee: IHpPositionEmployee = {
                              id: maxEmployeeId + 1,
                              role_id: newRole.role_id,
                              role_name: newRole.role_name,
                              department_id: newRole.department_id,
                              typeOfEmployment: "Full time",
                              numberOfEmployees: 0,
                              count: Array(MONTH_COUNT).fill(0),
                            };
                            const newEmployees = [...hpEmployees, newEmployee];
                            setHpEmployees(newEmployees);
                            setIsTotalEnabled(true);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </Section>
        </div>

        <Section
          title="Headcount"
          description="Determine the month when you will hire each person so that your expenses are delayed as much as possible. You probably don't need everyone on Day 1."
        >
          <div className="my-4 w-full">
            <TabGroup>
              <TabList className="flex items-stretch justify-start">
                <Tab className="border p-4 first:rounded-tl-lg last:rounded-tr-lg ui-selected:bg-gray-200">
                  <span className="text-base font-semibold">Full time</span>
                </Tab>
                <Tab className="border p-4 first:rounded-tl-lg last:rounded-tr-lg ui-selected:bg-gray-200">
                  <span className="text-base font-semibold">Freelance</span>
                </Tab>
                <Tab className="border p-4 first:rounded-tl-lg last:rounded-tr-lg ui-selected:bg-gray-200">
                  <span className="text-base font-semibold">Contract</span>
                </Tab>
                <div className="flex-grow">
                  <div className="flex items-end justify-end gap-4">
                    <PeriodSelector
                      showMonths={showMonthHeadcount}
                      onChange={setShowMonthHeadcount}
                    />
                  </div>
                </div>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <div className="w-full overflow-y-clip overflow-x-scroll rounded-lg rounded-tl-none border border-gray-200">
                    {departmentsData && (
                      <HeadcountTable
                        startDate={convertStringToDate(projectData.start_month)}
                        departments={departmentsData}
                        employees={hpEmployees.filter(
                          (empl) => empl.typeOfEmployment === "Full time",
                        )}
                        showMonths={showMonthHeadcount}
                        onChange={handleEmployeeNumberChange("Full time")}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full overflow-y-clip overflow-x-scroll rounded-lg rounded-tl-none border border-gray-200">
                    {departmentsData && (
                      <HeadcountTable
                        startDate={convertStringToDate(projectData.start_month)}
                        departments={departmentsData}
                        employees={hpEmployees.filter(
                          (empl) => empl.typeOfEmployment === "Freelance",
                        )}
                        showMonths={showMonthHeadcount}
                        onChange={handleEmployeeNumberChange("Freelance")}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full overflow-y-clip overflow-x-scroll rounded-lg rounded-tl-none border border-gray-200">
                    {departmentsData && (
                      <HeadcountTable
                        startDate={convertStringToDate(projectData.start_month)}
                        departments={departmentsData}
                        employees={hpEmployees.filter(
                          (empl) => empl.typeOfEmployment === "Contract",
                        )}
                        showMonths={showMonthHeadcount}
                        onChange={handleEmployeeNumberChange("Contract")}
                      />
                    )}
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </Section>

        <div className="mt-8">
          <Button onClick={handleShowTotal} isDisabled={!isTotalEnabled}>
            Show total
          </Button>
        </div>

        {showTotal ? (
          <div className="w-full">
            <Section
              title="Total"
              button={
                <PeriodSelector
                  showMonths={showMonthTotal}
                  onChange={setShowMonthTotal}
                />
              }
            >
              <div className="mt-4 w-full overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <TotalHeadcountTable
                  startDate={convertStringToDate(projectData.start_month)}
                  scenarioId={Number(sid)}
                  showMonths={showMonthTotal}
                />
              </div>
            </Section>
            <Section
              title="Payroll"
              button={
                <PeriodSelector
                  showMonths={showMonthPayroll}
                  onChange={setShowMonthPayroll}
                />
              }
            >
              <div className="mt-4 w-full overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <TotalPayrollTable
                  startDate={convertStringToDate(projectData.start_month)}
                  scenarioID={Number(sid)}
                  showMonths={showMonthPayroll}
                  positions={hpPositions}
                />
              </div>
            </Section>
          </div>
        ) : (
          <Section
            title="Payroll"
            description='There is no data here yet. The data will appear after you look at the "total" table.'
          />
        )}
        {error.overall && <Alert type="error" text={error.overall} />}
        <div className="my-16 flex justify-between gap-4">
          <Button onClick={handleNext}>Continue</Button>
          <Button style="outline" isDisabled={showTotal}>
            Save and exit
          </Button>
        </div>
      </div>
      <EditInitialData
        caption="Change Staff Count"
        isOpen={employeeDialogOpen}
        setIsOpen={setEmployeeDialogOpen}
        onConfirm={handleEmployeeNumberChangeSubmit}
      >
        <div className="mt-4 flex w-[550px] flex-col gap-4 p-5">
          <div>
            <label htmlFor="employeeNumber" className="float-left w-1/2">
              How many employees
            </label>
            <input
              id="employeeNumber"
              type="number"
              value={employeeDialogData.count}
              onChange={(e) =>
                setEmployeeDialogData({
                  ...employeeDialogData,
                  count: Number(e.target.value),
                })
              }
              className="w-1/2 rounded-lg border border-gray-200 p-2"
            />
          </div>
          <div>
            <label htmlFor="startMonth" className="float-left w-1/2">
              Start month
            </label>
            <select
              id="startMonth"
              value={employeeDialogData.startMonth}
              onChange={(e) =>
                setEmployeeDialogData({
                  ...employeeDialogData,
                  startMonth: Number(e.target.value),
                })
              }
              className="w-1/2 rounded-lg border border-gray-200 p-2"
            >
              {months.map((i, index) => (
                <option key={i.date.toISOString()} value={index}>
                  {i.date.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="endMonth" className="float-left w-1/2">
              End month
            </label>
            <select
              id="endMonth"
              value={employeeDialogData.endMonth}
              onChange={(e) =>
                setEmployeeDialogData({
                  ...employeeDialogData,
                  endMonth: Number(e.target.value),
                })
              }
              className="w-1/2 rounded-lg border border-gray-200 p-2"
            >
              {months.map((i, index) => (
                <option key={i.date.toISOString()} value={index}>
                  {i.date.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </option>
              ))}
            </select>
          </div>
        </div>
      </EditInitialData>
    </>
  );
};
