import { ReactNode } from "react";
import { PencilIcon } from "../../assets";

type CardWithImageProps = {
  title: string;
  value: string;
  image: ReactNode;
  onClick?: () => void;
};

export const CardWithImage = ({
  title,
  value,
  image,
  onClick,
}: CardWithImageProps) => {
  return (
    <div className="flex w-[413px] justify-between rounded-md bg-white">
      <div className="m-5 inline-flex flex-col items-start justify-start gap-2.5">
        <div className="font-['Noto Sans'] text-sm font-semibold leading-normal text-slate-700">
          {title}
        </div>
        <div className="inline-flex h-8 items-center justify-start gap-2">
          <div className="font-['Noto Sans'] text-2xl font-bold leading-loose text-gray-800">
            {value}
            {onClick && (
              <button className="text-normal" onClick={onClick}>
                <PencilIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="m-5 flex items-center">{image}</div>
    </div>
  );
};
