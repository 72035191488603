type CaptionProps = {
  noMargin?: boolean;
  children: React.ReactNode;
};

export const Caption = ({ noMargin = false, children }: CaptionProps) => {
  return (
    <h1
      className={`text-gray-800 text-2xl font-bold leading-loose ${
        noMargin ? "" : "mt-10 mb-8"
      }`}
    >
      {children}
    </h1>
  );
};
