import { SVGProps } from "react";
export const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#1F2436"
      d="M8.825 3.826a1.672 1.672 0 0 0-1.183-.492H3.332c-.917 0-1.658.75-1.658 1.667l-.008 10c0 .916.75 1.666 1.666 1.666h13.334c.916 0 1.666-.75 1.666-1.666V6.667c0-.916-.75-1.666-1.666-1.666H10L8.825 3.826Z"
    />
  </svg>
);
