import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

export const useError = (errors: Record<string, string>) => {
  const [error, setError] = useState(errors);
  const [errorText, setErrorText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useCallback((field: string, errorText: string) => {
    setError((prevError) => {
      const newState = { ...prevError, [field]: errorText };
      if (errorText !== "") {
        enqueueSnackbar(errorText, { variant: "error" });
        setErrorText(errorText);
      } else {
        const errorText = Object.values(newState).find((x) => x !== "");
        setErrorText(errorText ?? "");
      }

      return newState;
    });
  }, [enqueueSnackbar]);

  const resetError = useCallback(() => {
    const emptyError = Object.keys(errors).reduce(
      (acc, key) => ({ ...acc, [key]: "" }),
      {},
    );
    setError(emptyError);
    setErrorText("");
  }, [errors]);

  return { error, handleError, resetError, errorText };
};
