import { useState } from "react";
import {
  Line,
  LineVariants,
  Months,
  TableCell,
  TableCellSimple,
  getPeriods,
  groupMonths,
  variantDataStyles,
} from "../Table";
import { CollapseIcon, PencilIcon } from "../../assets";
import { endOfMonth, endOfQuarter, endOfYear, isEqual } from "date-fns";

export const HeadCountGroupLine = ({
  data,
  showMonths,
  months,
  isInitiallyOpen,
  onChange,
}: {
  data: Line;
  showMonths: boolean;
  months: Months;
  isInitiallyOpen?: boolean;
  onChange: (value: number, index: number, name: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen ?? false);
  const values = groupMonths(months, data.values);
  const periods = getPeriods(months, showMonths);

  return (
    <>
      <tr>
        <td className="sticky left-0 overflow-visible border-y bg-white py-2">
          <button className="flex" onClick={() => setIsOpen((prev) => !prev)}>
            <CollapseIcon isOpen={isOpen} />
            {data.name}
          </button>
        </td>
        {values.map((value, index) => (
          <TableCell
            key={index}
            className={variantDataStyles["group"]}
            value={value}
            months={periods}
            index={index}
            showMonths={showMonths}
          />
        ))}
      </tr>
      {data.children?.map((line) => (
        <HeadCountTableLine
          key={line.name}
          isOpen={isOpen}
          line={line}
          showMonths={showMonths}
          months={months}
          onChange={onChange}
        />
      ))}
    </>
  );
};

export const HeadCountTableLine = ({
  line,
  showMonths,
  months,
  isOpen = true,
  variant = "regular",
  onChange,
}: {
  line: Line;
  showMonths: boolean;
  months: Months;
  isOpen?: boolean;
  variant?: LineVariants;
  onChange: (value: number, index: number, name: string) => void;
}) => {
  const values = groupMonths(months, line.values);
  const periods = getPeriods(months, showMonths);

  return (
    <tr className={`${isOpen ? "" : "hidden"}`}>
      <td
        className="sticky left-0 cursor-pointer border bg-white pl-10 text-sm font-bold leading-normal text-gray-800 hover:bg-gray-200"
        onClick={() => onChange(values[0], 0, line.name)}
      >
        <div className="flex items-center gap-2">
          {line.name}
          <span className="text-normal">
            <PencilIcon />
          </span>
        </div>
      </td>
      {values.map((value, index) => (
        <HeadCountTableCell
          key={index}
          className={variantDataStyles[variant]}
          value={value}
          months={periods}
          index={index}
          showMonths={showMonths}
          onClick={(value) => onChange(value, index, line.name)}
        />
      ))}
    </tr>
  );
};

export const HeadCountTableCell = ({
  className,
  value,
  months,
  index,
  showMonths,
  onClick,
}: {
  className: string;
  value: number;
  months: {
    index: number;
    date: Date;
  }[];
  index: number;
  showMonths: boolean;
  onClick: (value: number) => void;
}) => {
  const isLast = isEqual(
    showMonths
      ? endOfMonth(months[index].date)
      : endOfQuarter(months[index].date),
    endOfYear(months[index].date),
  );

  if (showMonths) {
    return (
      <HeadCountTableCellSimple
        className={className}
        value={value}
        isLast={isLast}
        onClick={onClick}
      />
    );
  } else {
    return (
      <TableCellSimple className={className} value={value} isLast={isLast} />
    );
  }
};

export const HeadCountTableCellSimple = ({
  className,
  value,
  isLast,
  onClick,
}: {
  className: string;
  value: number;
  isLast: boolean;
  onClick: (value: number) => void;
}) => {
  return (
    <td
      className={`cursor-pointer hover:bg-gray-200 ${className} ${isLast ? "border-r-2 border-r-red-950" : ""}`}
      onClick={() => onClick(value)}
    >
      {value}
    </td>
  );
};
