import { IHpPositionEmployee } from "../../models/department";
import { MONTH_COUNT } from "../../const";
import {
  HeaderCaption,
  HeaderPeriod,
  Line,
  getMonths,
  getPeriods,
} from "../Table";
import { differenceInCalendarYears } from "date-fns";
import { IDepartment } from "../../models/backend";
import { HeadCountGroupLine } from "./HeadCountTableLine";

const convert = (
  employees: IHpPositionEmployee[],
  departments: IDepartment[],
): Line[] => {
  const data: Line[] = [];
  for (const department of departments) {
    if (
      employees.some((employee) => employee.department_id === department.id)
    ) {
      const departmentGroup: Line = {
        name: department.name,
        values: [],
        children: [],
      };
      for (const employee of employees.filter(
        (employee) => employee.department_id === department.id,
      )) {
        const positionGroup: Line = {
          name: employee.role_name,
          values: employee.count,
        };
        for (let i = 0; i < MONTH_COUNT; i++) {
          departmentGroup.values[i] =
            (departmentGroup.values[i] || 0) + (employee.count[i] || 0);
        }
        if (!departmentGroup.children) {
          departmentGroup.children = [];
        }
        departmentGroup.children.push(positionGroup);
      }
      data.push(departmentGroup);
    }
  }
  return data;
};

export const HeadcountTable = ({
  startDate,
  departments,
  employees,
  showMonths,
  onChange,
}: {
  startDate: Date;
  departments: IDepartment[];
  employees: IHpPositionEmployee[];
  showMonths: boolean;
  onChange: (
    value: number,
    index: number,
    role: string,
    department: string,
  ) => void;
}) => {
  const months = getMonths(startDate, showMonths, MONTH_COUNT);
  const periods = getPeriods(months, showMonths);

  const data = convert(employees, departments);

  const handleChange =
    (department: Line) => (value: number, index: number, name: string) => {
      onChange(value, index, name, department.name);
    };

  return (
    <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="Role" />
          {periods.map((month) => (
            <HeaderPeriod
              key={month.date.toISOString()}
              month={month.date}
              showMonths={showMonths}
              colorIndex={differenceInCalendarYears(month.date, startDate) % 3}
            />
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-100 border-t border-gray-100">
        {data.map((department, index) => (
          <HeadCountGroupLine
            key={department.name}
            data={department}
            showMonths={showMonths}
            months={months}
            onChange={handleChange(department)}
            isInitiallyOpen={index === 0}
          />
        ))}
      </tbody>
    </table>
  );
};
