import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { AuthLayout, HomeLayout, MainLayout } from "./components/Layout";
import {
  General,
  Payroll,
  Login,
  DepExp,
  Debts,
  Project,
  WelcomePage,
  Otp,
  Signup,
  PasswordRecovery,
  Home,
  Summary,
  Root,
} from "./pages";
import { store } from "./store";
import { ProvideAuth } from "./hooks/useAuth";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);


const BrowserRouter = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <WelcomePage />,
    children: [
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          { path: "login", element: <Login /> },
          { path: "signup", element: <Signup /> },
          { path: "activate", element: <Otp /> },
          { path: "reset", element: <PasswordRecovery /> },
        ],
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      { path: "/home/:pid", element: <Home /> },
      {
        path: "/:pid/:sid",
        children: [
          { index: true, element: <Root /> },
          { path: "general", element: <General /> },
          { path: "payroll", element: <Payroll /> },
          { path: "depexp", element: <DepExp /> },
          { path: "debt", element: <Debts /> },
          { path: "summary", element: <Summary /> },
        ],
      },
    ],
  },
  {
    element: <HomeLayout />,
    children: [{ path: "/:pid", element: <Project /> }],
  },
  {
    element: <HomeLayout showProjects={false} />,
    children: [{ path: "/home", element: <Home /> }],
  },
]);

export const App = () => (
  <Provider store={store}>
    <ProvideAuth>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <RouterProvider router={BrowserRouter} />
      </SnackbarProvider>
    </ProvideAuth>
  </Provider>
);
