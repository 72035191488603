import { SVGProps } from "react";

export const MarketingDpt = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <g>
        <path
          fill="#343B58"
          d="M17.333 7.333h-.666V2.667a.669.669 0 00-.915-.62L5.905 6H2.633c-1.102 0-2 .897-2 2v2.667c0 1.102.898 2 2 2h.034v4.666a.667.667 0 00.666.667H6a.666.666 0 00.667-.667v-4.361l9.085 3.646a.671.671 0 00.837-.306.666.666 0 00.078-.312v-4.667h.666c1.103 0 2-.897 2-2 0-1.102-.897-2-2-2zM1.967 10.667V8c0-.367.298-.667.666-.667h2.734v4H2.633a.667.667 0 01-.666-.666zm3.366 6H4v-4h1.333v4zm10-1.653L6.7 11.549V7.117l8.633-3.464v11.361zm2-5.014h-.666V8.667h.666a.667.667 0 010 1.333z"
        ></path>
      </g>
    </svg>
  );
};
