import { Description, Dialog, DialogPanel } from "@headlessui/react";
import { Caption } from "../Caption";
import { Button } from "../Button";
import { CloseIcon } from "../../assets/icons/CloseIconn";

type EditNameDialogProps = {
  isOpen: boolean;
  caption: string;
  description?: string;
  children: React.ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

function EditInitialData({
  caption,
  description,
  children,
  isOpen,
  setIsOpen,
  onConfirm,
}: Readonly<EditNameDialogProps>) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50 flex flex-col"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel className="mx-auto my-auto max-w-xl rounded-lg bg-white">
            <div className="flex w-full justify-between p-5">
              <Caption noMargin>{caption}</Caption>
              <Button style="ghost" onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </Button>
            </div>
            {description && (
              <Description className="m-5 font-semibold">
                {description}
              </Description>
            )}
            {children}
            <div className="flex gap-5 p-5">
              <Button
                style="outline"
                variant="secondary"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button onClick={onConfirm}>Save</Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export { EditInitialData };
