import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCheckOtpMutation,
  useResendActivationMutation,
} from "../../services/patric";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";

export const Otp = () => {
  const location = useLocation();
  const [checkOtp, result] = useCheckOtpMutation();
  const [reactivate, reactivateResult] = useResendActivationMutation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const searchParams = useMemo(() => {
    const parsedValue = new URLSearchParams(location.search);
    return {
      token: parsedValue.get("otp") as string,
    };
  }, [location.search]);

  useEffect(() => {
    if (searchParams.token) {
      checkOtp(searchParams.token);
    }
  }, [searchParams.token]);

  useEffect(() => {
    if (result.isSuccess) {
      setTimeout(() => navigate("/auth/login"), 3000);
    }
  }, [result, navigate]);

  useEffect(() => {
    if (reactivateResult.isSuccess) {
      setTimeout(() => navigate("/auth/login"), 3000);
    }
  }, [reactivateResult, navigate]);

  const handleResend = () => {
    reactivate(email);
  };

  return (
    <>
      <h1 className="mb-8 font-semibold">One Time Password</h1>
      {result.isLoading && <p>Loading...</p>}
      {result.isError && <p>Wrong or expired OTP</p>}
      {result.isSuccess && <p>OTP verified, you will be redirected to login</p>}
      {reactivateResult.isSuccess && <p>Activation link sent</p>}
      {reactivateResult.isError && <p>Something went wrong</p>}
      {(result.isError || !searchParams.token) && (
        <>
          <p>Resend activation link to email?</p>
          <div className="mt-8">
            <Input
              type="text"
              id="username"
              label="Enter email"
              value={email}
              setValue={(e) => setEmail(e as string)}
            />
            <Button type="button" onClick={handleResend} isDisabled={!email}>
              Submit
            </Button>
          </div>
        </>
      )}
    </>
  );
};
