import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "../../assets";
import { useAuth } from "../../hooks/useAuth";
import { useGetUserQuery } from "../../services/patric";
import { Avatar } from "../Avatar/Avatar";
import { useState } from "react";
import { Contact } from "../Contact";

export const UserMenu = () => {
  const { logout, user } = useAuth();
  const { data: userData } = useGetUserQuery(undefined, { skip: !user });
  const [isContactOpen, setIsContactOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  const handleContact = () => {
    setIsContactOpen(true);
  };

  const first = userData?.info.firstname ? userData.info.firstname[0] : "?";
  const second = userData?.info.lastname ? userData?.info.lastname[0] : "?";
  const initials = `${first}${second}`;

  return (
    <>
      <Menu>
        <MenuButton className="flex">
          <Avatar name={initials} />
          <ChevronDownIcon />
        </MenuButton>
        <Transition
          enter="transition ease-out duration-75"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="w-52 origin-top-right rounded-xl border bg-white p-1 text-sm/6 [--anchor-gap:var(--spacing-1)] focus:outline-none"
          >
            <MenuItem>
              <button
                className="group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-normal/10 data-[disabled]:text-placeholderText"
                onClick={handleLogout}
              >
                Log out
              </button>
            </MenuItem>
            <MenuItem>
              <button
                className="group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-normal/10 data-[disabled]:text-placeholderText"
                onClick={handleContact}
              >
                Contact us
              </button>
            </MenuItem>
            <MenuItem disabled>
              <button className="group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-normal/10 data-[disabled]:text-placeholderText">
                Help
              </button>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
      <Dialog
        open={isContactOpen}
        onClose={() => setIsContactOpen(false)}
        className="relative z-50 flex flex-col"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel className="relative p-2">
              <Contact onSubmit={() => setIsContactOpen(false)} />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};
