export const variantNameStyles = {
    regular:
        "text-gray-800 text-sm font-bold leading-normal border pl-10 sticky left-0 bg-white",
    total:
        "text-gray-800 text-sm font-bold leading-normal border pl-10 sticky left-0 bg-green-50",
};

export const variantDataStyles = {
    regular:
        "text-gray-800 text-sm font-normal leading-normal text-right border pr-2 py-2",
    total:
        "text-gray-800 text-sm font-semibold leading-normal text-right border bg-green-50 pr-2 py-2",
    group: "border px-2 text-right",
};