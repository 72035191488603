import { IBackendError } from "./backend";

export const isBackendError = (error: unknown): error is IBackendError => {
  return (
    typeof error === "object" &&
    !!error &&
    "data" in error &&
    !!error.data &&
    typeof error.data === "object" &&
    "detail" in error.data &&
    typeof error.data.detail === "string"
  );
};
