import { useState } from "react";

export const InfoPoint = ({
  children,
  x,
  y,
}: {
  children: React.ReactNode;
  x: number;
  y: number;
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="absolute" style={{ left: x, top: y, zIndex: 40 }}>
      <div
        className={`flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-[#0167ba] p-2 text-6xl text-white shadow-lg transition-opacity
          ${show ? "opacity-100" : "opacity-40"}`}
        onClick={() => setShow(!show)}
      >
        +
      </div>
      <div
        className={`relative -left-1/3 mt-4 min-w-80 max-w-80 border border-disabledButton bg-white p-8 shadow-lg ${show ? "block" : "hidden"}`}
      >
        {children}
        <div
          className="absolute right-2 top-2 cursor-pointer text-disabledButtonText"
          onClick={() => setShow(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
