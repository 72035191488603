import { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "../../assets";

type Props = {
  value?: number;
  error: boolean;
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
};

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const MonthSelector = ({ value, onChange, onBlur, error }: Props) => {
  const [selectedMonth, setSelectedMonth] = useState(
    () => months.find((month) => month.id === value) || { id: 0, name: "" },
  );

  useEffect(() => {
    setSelectedMonth(
      months.find((month) => month.id === value) || { id: 0, name: "" },
    );
  }, [value]);

  const handleChange = (month: (typeof months)[0]) => {
    onChange(month.id);
  };

  return (
    <Listbox
      as="div"
      className={`relative flex flex-col text-left`}
      value={selectedMonth}
      onChange={handleChange}
      onBlur={() => onBlur && onBlur(value || 0)}
    >
      <label className="mb-2 text-sm font-semibold">Month</label>

      <Listbox.Button
        className={`inline-flex w-80 justify-between gap-x-1.5 rounded-md bg-white px-3 py-3 shadow-sm ring-1 ring-inset hover:bg-gray-50 ${
          error ? "ring-red-500" : "ring-gray-300"
        }`}
      >
        {selectedMonth?.name || (
          <span className="text-placeholderText text-opacity-70">Month</span>
        )}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Listbox.Button>

      <Listbox.Options className="absolute right-0 top-16 z-10 mt-2 w-80 origin-top-right cursor-pointer rounded-md bg-white pl-3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {months.map((month) => (
          <Listbox.Option key={month.id} value={month}>
            {month.name}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};
