import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Caption, SubCaption } from "../../components/Caption";
import { Tip } from "../../components/Tip";
import { PlusCircleFilledIcon, TrashIcon } from "../../assets";
import { Button } from "../../components/Button";
import { RepaymentSchedule } from "./RepaymentSchedule";
import {
  useCloseScenarioMutation,
  useGetDebtQuery,
  useGetProjectQuery,
  useSaveDebtMutation,
  useUpdateStepMutation,
} from "../../services/patric";
import {
  IDebtDetailsResponse,
  IListDebtDetailsRequest,
} from "../../models/backend";
import { PeriodSelector } from "../../components/PeriodSelector";
import { SUMMARY_VIEW } from "../../const";
import { convertStringToDate } from "../../utils/convert";

const predefinedDebts = ["Credit Card", "Bank loan", "Line of credit"];

const emptyDebt: IDebtDetailsResponse = {
  name: "",
  interest: 0,
  term: 0,
  total_debt: 0,
};

const HeaderCaption = ({
  caption,
  first = false,
  width,
}: {
  caption: string;
  first?: boolean;
  width?: string;
}) => {
  return (
    <th
      scope="col"
      className={`sticky top-0 border bg-gray-200 py-4 text-left text-xs font-semibold leading-none tracking-tight text-slate-700 ${first ? "pl-8" : ""
        } ${width ? `w-${width}` : "w-60"}`}
    >
      {caption}
    </th>
  );
};

const DebtInput = ({
  id,
  label,
  value,
  setValue,
  onChange,
  onBlur,
}: {
  id: string;
  type: React.HTMLInputTypeAttribute;
  label?: string;
  value?: string;
  postfix?: string;
  fullWidth?: boolean;
  setValue?: (value: string) => void;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  onBlur?: (value: string) => void;
  error?: string;
  showLabel?: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(value || "");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(event.target.value);
    }
    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <input
      id={id}
      placeholder={label}
      className="rounded-md border border-outlines py-1 pr-2 text-right"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export const Debts = () => {
  const { sid, pid } = useParams();
  const { isLoading, data } = useGetDebtQuery(Number(sid));
  const { isSuccess: projectIsSuccess, data: projectData } = useGetProjectQuery(
    Number(pid),
  );
  const [saveDebt, resultSaveDebt] = useSaveDebtMutation();
  const [closeScenario] = useCloseScenarioMutation();
  const [updateStep] = useUpdateStepMutation();
  const navigate = useNavigate();
  const [localData, setLocalData] = useState<Array<IDebtDetailsResponse>>([]);
  const [showMonth, setShowMonth] = useState(true);

  useEffect(() => {
    if (data) {
      const tempData = [...data];
      for (let predefinedName of predefinedDebts) {
        if (!tempData.find((value) => value.name === predefinedName)) {
          tempData.unshift({ ...emptyDebt, name: predefinedName });
        }
      }
      setLocalData(tempData);
    } else {
      setLocalData(predefinedDebts.map((name) => ({ ...emptyDebt, name })));
    }
  }, [data]);

  if (isLoading || !projectIsSuccess || !projectData) {
    return <div>Loading...</div>;
  }

  const handleChange =
    (index: number, key: keyof IDebtDetailsResponse) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = key === "name" ? e.target.value : Number(e.target.value);
        if (typeof value === "number" && isNaN(value)) return;
        const tempData = [...localData];
        tempData[index] = {
          ...tempData[index],
          [key]: value,
        };
        setLocalData(tempData);
      };

  const handleChangeString =
    (index: number, key: keyof IDebtDetailsResponse) =>
      (input: string) => {
        const value = key === "name" ? input : Number(input);
        const tempData = [...localData];
        tempData[index] = {
          ...tempData[index],
          [key]: typeof value === "number" && isNaN(value) ? 0 : value,
        };
        setLocalData(tempData);
      };

  const handleAdd = () => {
    const tempData = [...localData];
    tempData.push({ ...emptyDebt });
    setLocalData(tempData);
  };

  const handleDel = (index: number) => () => {
    const tempData = [...localData];
    tempData.splice(index, 1);
    setLocalData(tempData);
  };

  const handleShowSchedule = () => {
    const body: IListDebtDetailsRequest = {
      debts: localData.filter(
        ({ total_debt, interest }) => total_debt && interest,
      ),
    };
    saveDebt({ scenarioID: Number(sid), body });
  };

  const handleNext = () => {
    handleShowSchedule();
    updateStep({ scenarioId: sid!, step: SUMMARY_VIEW });
    closeScenario(Number(sid));
    navigate("../summary");
  };

  return (
    <div className="flex flex-col gap-8">
      <Caption>Debt Repayment</Caption>
      <Tip
        caption="Task №4"
        text="Enter the repayment details for any debt your business currently has, such as bank loans, lines of credit, credit card debts, or other kinds of loans like investments that have a due date."
      />
      <div className="flex items-center gap-2">
        <SubCaption>Debt Details</SubCaption>
      </div>
      <div className="my-4 max-w-7xl">
        <table className="w-full table-fixed border-separate border-spacing-0">
          <thead>
            <tr className="text-left">
              <HeaderCaption caption="Name" first />
              <HeaderCaption caption="Total Debt ($)" />
              <HeaderCaption caption="Interest rate (%)" />
              <HeaderCaption caption="Repayment period (years)" />
              <HeaderCaption caption="" width="24" />
            </tr>
          </thead>
          <tbody className="text-sm">
            {localData.map((debt, index) => (
              <tr key={`${index}`}>
                <td className="border-b border-l py-2 pl-8 font-semibold">
                  {predefinedDebts.includes(debt.name) ? (
                    <>{debt.name}</>
                  ) : (
                    <input
                      placeholder="Enter debt name"
                      className="rounded-md border-outlines py-1 font-semibold focus:border"
                      value={debt.name}
                      onChange={handleChange(index, "name")}
                    />
                  )}
                </td>
                <td className="border-b py-2 pr-8">
                  <input
                    placeholder="Enter number"
                    className="rounded-md border border-outlines py-1 pr-2 text-right"
                    value={debt.total_debt}
                    onChange={handleChange(index, "total_debt")}
                  />
                </td>
                <td className="border-b py-2 pr-8">
                  <DebtInput
                    id="interest"
                    value={String(debt.interest)}
                    setValue={handleChangeString(index, "interest")}
                    type="text"
                  />
                </td>
                <td className="border-b py-2">
                  <input
                    placeholder="Enter number"
                    className="rounded-md border border-outlines py-1 pr-2 text-right"
                    value={debt.term}
                    onChange={handleChange(index, "term")}
                  />
                </td>
                <td className="border-b border-r">
                  <div className="flex items-center gap-2">
                    {!predefinedDebts.includes(debt.name) && (
                      <button className="py-2 pl-3" onClick={handleDel(index)}>
                        <TrashIcon />
                      </button>
                    )}
                    {index === localData.length - 1 && (
                      <button className="py-2 pl-3" onClick={handleAdd}>
                        <PlusCircleFilledIcon />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-10 flex max-w-7xl items-center gap-2">
        <SubCaption>Repayment Schedule</SubCaption>
        <Button onClick={handleShowSchedule}>Show schedule</Button>
        {resultSaveDebt.isSuccess && (
          <div className="flex-grow">
            <div className="flex items-end justify-end gap-4">
              <PeriodSelector showMonths={showMonth} onChange={setShowMonth} />
            </div>
          </div>
        )}
      </div>
      {resultSaveDebt.isSuccess && (
        <div className="mt-5 max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
          <RepaymentSchedule
            showMonths={showMonth}
            startDate={convertStringToDate(projectData.start_month)}
            scenarioID={Number(sid)}
          />
        </div>
      )}
      <div className="mt-16 flex justify-between gap-6">
        <Button onClick={handleNext}>Continue</Button>
        <Button isDisabled>Save and Exit</Button>
      </div>
    </div>
  );
};
