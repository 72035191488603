import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

/**
 * Log a warning and show a toast!
 */
export const apiErrorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload &&
        typeof action.payload === "object" &&
        "status" in action.payload &&
        action.payload.status === 401
      ) {
        const { dispatch } = api;
        dispatch({ type: "auth/logout" });
        console.warn("Logged out due to 401 error");
      }
    }

    return next(action);
  };
