import { IRepaymentScheduleResponse } from "../../models/backend";

// Conversion function to get the data from format in variable mock to format in variable result
export const convert = (input: IRepaymentScheduleResponse) => {
  const data = input.debts.map((debt: string, index: number) => {
    return {
      name: debt,
      values: input.payments.reduce((acc, payment, ind) => {
        acc[ind + 1] = payment.debt[index];
        return acc;
      }, [] as number[]),
    };
  });

  const total = {
    name: "Total",
    values: input.payments.reduce((acc, payment, ind) => {
      acc[ind + 1] = payment.total;
      return acc;
    }, [] as number[]),
  };

  return { data, total };
};
