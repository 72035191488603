import { Fragment } from "react";
import { NavLink } from "react-router-dom";

const Divider = () => <div className="ml-3 h-7 border-l border-black" />;

const Stroke = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.98499 12.6666C5.8756 12.6666 5.77304 12.649 5.67732 12.614C5.5816 12.5794 5.49272 12.5204 5.41067 12.4369L1.8827 8.84556C1.73228 8.69244 1.66035 8.49394 1.66692 8.25006C1.67403 8.00674 1.75279 7.80852 1.90321 7.6554C2.05363 7.50228 2.24507 7.42572 2.47753 7.42572C2.70999 7.42572 2.90143 7.50228 3.05185 7.6554L5.98499 10.6412L12.9384 3.56293C13.0888 3.40981 13.2838 3.33325 13.5234 3.33325C13.7624 3.33325 13.9571 3.40981 14.1075 3.56293C14.258 3.71605 14.3332 3.91427 14.3332 4.15759C14.3332 4.40147 14.258 4.59997 14.1075 4.75309L6.55932 12.4369C6.47727 12.5204 6.38839 12.5794 6.29267 12.614C6.19695 12.649 6.09439 12.6666 5.98499 12.6666Z"
      fill="white"
    />
  </svg>
);

const Circle = ({
  finished,
  active,
  index,
}: {
  finished: boolean;
  active: boolean;
  index: number;
}) => {
  if (finished) {
    return (
      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-green-600">
        <Stroke />
      </div>
    );
  }
  if (active) {
    return (
      <div className="flex h-5 w-5 items-center justify-center rounded-full border border-green-600 text-green-600">
        {index + 1}
      </div>
    );
  }
  return (
    <div className="flex h-5 w-5 items-center justify-center rounded-full border border-black">
      {index + 1}
    </div>
  );
};

const style = ({
  isActive,
  isPending,
}: {
  isActive: boolean;
  isPending: boolean;
}) =>
  `flex items-center ml-1 ${
    isPending ? "font-light" : isActive ? "font-semibold" : ""
  }`;

export const Pages = ({
  pages,
  currentPathId,
}: {
  pages: Array<{ name: string; path: string; view: string }>;
  currentPathId: number;
}) => {
  return (
    <>
      {pages.map((page, index) => (
        <Fragment key={index}>
          {!!index && <Divider />}
          <div>
            <NavLink to={page.path} className={style}>
              {({ isActive }) => (
                <>
                  <Circle
                    finished={index < currentPathId}
                    active={isActive}
                    index={index}
                  />
                  <span className="ml-2">{page.name}</span>
                </>
              )}
            </NavLink>
          </div>
        </Fragment>
      ))}
    </>
  );
};
