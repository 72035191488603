import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../services/patric";
import { format, parse } from "date-fns";

const F = ({ children }: { children: ReactNode }) => {
  return (
    <div className="text-sm leading-normal text-secondaryText">{children}</div>
  );
};

const V = ({ children }: { children: ReactNode }) => {
  return (
    <span className="text-sm leading-loose text-primaryText">{children}</span>
  );
};

export const Reminder = () => {
  const { pid } = useParams();
  const { data, isError, isLoading } = useGetProjectQuery(Number(pid), {
    skip: isNaN(Number(pid)),
  });

  if (isError || isLoading || !data) {
    return false;
  }

  return (
    <div
      className={`flex w-full items-center justify-start gap-4 rounded-lg border border-dashed border-text2 px-4 py-4`}
    >
      <div className="font-bold leading-loose text-active">Reminder</div>
      <F>
        Start date:{" "}
        <V>
          {format(parse(data.start_month, "yyyy-mm", new Date()), "MMM yyyy")}
        </V>
      </F>
      {data.location && (
        <F>
          Location: <V>{data.location}</V>
        </F>
      )}
      <F>
        Tax rate: <V>{data.tax_rate || 0}%</V>
      </F>
      <F>
        Growth rate: <V>{data.growth_rate}%</V>
      </F>
    </div>
  );
};
