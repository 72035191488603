import { Listbox } from "@headlessui/react";
import { CollapseIcon } from "../../assets";

export const PeriodSelector = ({
  showMonths,
  onChange,
}: {
  showMonths: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <div className="flex flex-row relative gap-4">
      <label htmlFor="period-selector">Show:</label>
      <Listbox value={showMonths} onChange={onChange}>
        <Listbox.Button className="flex font-semibold">
          {showMonths ? "Month" : "Quarter"} <CollapseIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute bg-white right-0 top-6 shadow-lg cursor-pointer gap-4">
          <Listbox.Option key="Month" value={true} className="m-4">
            Month
          </Listbox.Option>
          <Listbox.Option key="Quarter" value={false} className="m-4">
            Quarter
          </Listbox.Option>
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
