import { SVGProps } from "react";
export const GeneralDpt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <g>
        <mask
          id="mask0_1069_2543"
          style={{ maskType: "luminance" }}
          width="20"
          height="20"
          x="2"
          y="2"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <path fill="#fff" d="M2 2h20v20H2V2z"></path>
          </g>
        </mask>
        <g mask="url(#mask0_1069_2543)">
          <g>
            <path
              fill="#343B58"
              fillRule="evenodd"
              d="M8.646 4.317a2.5 2.5 0 000 3.535l1.586 1.586a2.5 2.5 0 003.536 0l1.585-1.586a2.5 2.5 0 000-3.535l-1.586-1.585a2.5 2.5 0 00-3.536 0L8.646 4.317zm1.179 2.358a.833.833 0 010-1.18l1.586-1.585a.834.834 0 011.179 0l1.586 1.586a.833.833 0 010 1.178L12.59 8.26a.834.834 0 01-1.18 0L9.826 6.675zM2.733 10.23a2.5 2.5 0 000 3.536l1.586 1.586a2.5 2.5 0 003.535 0l1.586-1.586a2.499 2.499 0 000-3.536L7.853 8.645a2.5 2.5 0 00-3.535 0L2.733 10.23v-.001zm1.179 2.357a.833.833 0 010-1.179l1.586-1.586a.833.833 0 011.178 0l1.586 1.586a.833.833 0 010 1.18l-1.587 1.586a.833.833 0 01-1.178 0L3.91 12.588zm4.735 7.092a2.5 2.5 0 010-3.535l1.585-1.586a2.501 2.501 0 013.536 0l1.586 1.586a2.5 2.5 0 010 3.535l-1.587 1.588a2.501 2.501 0 01-3.536 0L8.645 19.68l.002-.002zm1.178-2.357a.833.833 0 000 1.179l1.586 1.586a.835.835 0 001.179 0l1.586-1.586a.833.833 0 000-1.179l-1.586-1.585a.833.833 0 00-1.18 0l-1.585 1.586zm4.737-7.091a2.5 2.5 0 000 3.535l1.586 1.586a2.5 2.5 0 003.535 0l1.585-1.586a2.5 2.5 0 000-3.536l-1.585-1.586a2.5 2.5 0 00-3.535 0l-1.586 1.587zm1.178 2.356a.834.834 0 010-1.179l1.586-1.586a.833.833 0 011.178 0l1.586 1.586a.832.832 0 010 1.18l-1.586 1.585a.833.833 0 01-1.178 0l-1.586-1.586z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
