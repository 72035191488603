import {
  GroupLineSimple,
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
} from "../../components/Table";
import { IRevenue } from "../../models/backend";

export const DetailsTableCommon = ({
  periods,
  data,
}: {
  periods: Periods;
  data: IRevenue;
}) => {
  const payingUsers: Line = {
    name: "Paying users",
    values: data.sales.map((value) => value.paying_users),
  };

  const monthlyIncrease: Line = {
    name: "Monthly increase, %",
    values: data.marketing.map((value) => value.monthly_increase),
  };

  const leads: Line = {
    name: "Leads",
    values: data.marketing.map((value) => value.leads),
  };

  const newCustomers: Line = {
    name: "New",
    values: data.customers.map((value) => value.new),
  };

  const existingCustomers: Line = {
    name: "Existing",
    values: data.customers.map((value) => value.existing),
  };

  const totalCustomers: Line = {
    name: "Total",
    values: data.customers.map((value) => value.final),
  };

  const lines: Line[] = [
    { name: "Marketing", values: [], children: [monthlyIncrease, leads] },
    {
      name: "Customers",
      values: [],
      children: [newCustomers, existingCustomers, totalCustomers],
    },
    { name: "Sales", values: [], children: [payingUsers] },
  ];

  return (
    <>
      <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
        <thead className="bg-gray-200">
          <tr>
            <HeaderCaption caption="" />
            {periods.map((month) => (
              <HeaderPeriodSimple
                period={month.period}
                year={month.year}
                width="w-28"
                isLast={month.isLast}
                colorIndex={month.colorIndex}
              />
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {lines.map((value) => (
            <GroupLineSimple
              data={value}
              periods={periods}
              isInitiallyOpen={value.name === "Marketing"}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
