import { useNavigate, useParams } from "react-router-dom";
import { Caption } from "../../components/Caption";
import { InitialData } from "./InitialData";
import { Recomendations } from "./Recomendations";
import {
  useGetGeneralInfoQuery,
  useGetProjectQuery,
  useGetSummaryQuarterlyQuery,
  useGetSummaryQuery,
  useSaveGeneralInfoMutation,
  useSaveProjectMutation,
} from "../../services/patric";
import { TotalRevenueCostChart } from "./TotalRevenueCostChart";
import { CashFlowChart } from "./CashFlowChart";
import { Chart } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { EditInitialData } from "../../components/Dialog/EditInitialData";
import {
  customAxisTitlePlugin,
  customBackgroundPlugin,
  customTitlePlugin,
} from "./utils";
import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { IProduct } from "../../models/backend";
import { PlusCircleFilledIcon, TrashIcon } from "../../assets";
import { ProfitLostTable } from "./ProfitLostTable";
import { CustomersTable } from "./CustomersTable";
import { RevenueTable } from "./RevenueTable";
import { ExpensesTable } from "./ExpensesTable";
import { DetailsTable } from "./DetailsTable";
import { Button } from "../../components/Button";
import { Section } from "../../components/Section";
import { PeriodSelector } from "../../components/PeriodSelector";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  isEqual,
  parse,
} from "date-fns";
import { formatUSD } from "../../utils/format";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "../../assets/icons/CloseIconn";

Chart.register(CategoryScale);
Chart.register(customBackgroundPlugin);
Chart.register(customTitlePlugin);
Chart.register(customAxisTitlePlugin);

export const Summary = () => {
  const { sid, pid } = useParams();
  const navigate = useNavigate();

  const { data: generalData } = useGetGeneralInfoQuery(Number(sid));
  const [generalTrigger] = useSaveGeneralInfoMutation();
  const { data: projectData } = useGetProjectQuery(Number(pid), {
    skip: isNaN(Number(pid)),
  });
  const { data: summaryData, isLoading } = useGetSummaryQuery({ scenarioID: Number(sid) });
  const { data: summaryQuarterlyData } = useGetSummaryQuarterlyQuery({
    scenarioID: Number(sid),
  });

  const [projectTrigger] = useSaveProjectMutation();
  const [isEditMoneyVisible, setIsEditMoneyVisible] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);

  const [isEditCustomersVisible, setIsEditCustomersVisible] = useState(false);
  const [customers, setCustomers] = useState(0);

  const [isEditGrowthRateVisible, setIsEditGrowthRateVisible] = useState(false);
  const [growthRate, setGrowthRate] = useState(0);

  const [isEditMarketingPlanVisible, setIsEditMarketingPlanVisible] =
    useState(false);
  const [monthlyLeads, setMonthlyLeads] = useState(0);
  const [monthlyCost, setMonthlyCost] = useState(0);

  const [isEditProductsVisible, setIsEditProductsVisible] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [isDetailsTableVisible, setIsDetailsTableVisible] = useState(false);

  const [showMonthProfitLost, setShowMonthProfitLost] = useState(false);
  const [showMonthCustomers, setShowMonthCustomers] = useState(false);
  const [showMonthRevenue, setShowMonthRevenue] = useState(false);
  const [showMonthExpeneses, setShowMonthExpeneses] = useState(false);

  useEffect(() => {
    if (generalData) {
      setCurrentBalance(generalData.current_balance);
      setCustomers(generalData.products.customers);
      setGrowthRate(projectData?.growth_rate || 0);
      setMonthlyLeads(generalData.marketing.monthly_leads);
      setMonthlyCost(generalData.marketing.monthly_cost);
      setProducts(generalData.products.products || []);
    }
  }, [generalData]);

  const saveCurentBalance = () => {
    if (!generalData) return;

    setIsEditMoneyVisible(false);
    generalTrigger({
      body: {
        ...generalData,
        current_balance: currentBalance,
      },
      scenarioId: Number(sid),
    });
  };

  const cancelCurrentBalance = () => {
    setCurrentBalance(generalData?.current_balance || 0);
    setIsEditMoneyVisible(false);
  };

  const saveCustomers = () => {
    if (!generalData) return;

    setIsEditCustomersVisible(false);
    generalTrigger({
      body: {
        ...generalData,
        products: {
          ...generalData.products,
          customers: customers,
        },
      },
      scenarioId: Number(sid),
    });
  };

  const cancelCustomers = () => {
    setCustomers(generalData?.products.customers || 0);
    setIsEditCustomersVisible(false);
  };

  const saveGrowthRate = () => {
    if (!projectData) return;

    setIsEditGrowthRateVisible(false);
    projectTrigger({
      ...projectData,
      growth_rate: growthRate,

      project_id: Number(pid),
    });
  };

  const cancelGrowthRate = () => {
    setCurrentBalance(generalData?.current_balance || 0);
    setIsEditGrowthRateVisible(false);
  };

  const saveMarketingPlan = () => {
    if (!generalData) return;

    setIsEditMarketingPlanVisible(false);
    generalTrigger({
      body: {
        ...generalData,
        marketing: {
          ...generalData.marketing,
          monthly_leads: monthlyLeads,
          monthly_cost: monthlyCost,
        },
      },
      scenarioId: Number(sid),
    });
  };

  const cancelMarketingPlan = () => {
    setCurrentBalance(generalData?.current_balance || 0);
    setIsEditMarketingPlanVisible(false);
  };

  const handleChange =
    (index: number, field: string) => (e: string | number) => {
      const value = field !== "name" ? Number(e) : e;

      let newProducts = products.map((product, i) => {
        if (i === index) {
          return { ...product, [field]: value };
        }
        return product;
      });
      setProducts(newProducts);
    };

  const handleProductAdd = () => {
    setProducts([...products, { name: "", price: 0, conversion: 0 }]);
  };

  const handleProductDel = (index: number) => () => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const saveProducts = () => {
    setIsEditProductsVisible(false);

    if (!generalData) return;

    generalTrigger({
      body: {
        ...generalData,
        products: {
          ...generalData.products,
          products: products,
        },
      },
      scenarioId: Number(sid),
    });
  };

  const cancelProducts = () => {
    setProducts(generalData?.products.products || []);
    setIsEditProductsVisible(false);
  };

  const sendBack = () => {
    navigate(`../general`);
  }

  if (isLoading) return <div>Loading...</div>;

  if (!summaryData || !projectData || !summaryQuarterlyData) return (
    <Dialog
      open={true}
      onClose={sendBack} // dummy function
      className="relative z-50 flex flex-col"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="mx-auto my-auto max-w-xl rounded-lg bg-white">
            <div className="flex w-full justify-between p-5">
              <Caption noMargin>Summary Unavailable</Caption>
              <Button style="ghost" onClick={sendBack}>
                <CloseIcon />
              </Button>
            </div>
            <Dialog.Description className="m-8 text-justify">
              <p className="my-2">We're unable to display a summary at this time because we did not receive enough data to perform an analysis.</p>
              <p className="my-2">Please ensure that all required information is provided to take full advantage of the features and insights we offer.</p>
              <p className="my-2">If you need help or have any questions about the data submission process, please contact our support team.</p>
              <p className="my-2">Thank you!</p>
            </Dialog.Description>

            <div className="flex gap-5 p-5">
              <Button onClick={sendBack}>OK</Button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );

  let colorIndex = 0;
  const periods = summaryData.periods.map((period) => {
    const date = parse(period, "yyyy-MM", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfMonth(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "MMM"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  colorIndex = 0;
  const periodsQuarterly = summaryQuarterlyData.periods.map((period) => {
    const date = parse(period, "yyyy-Q", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfQuarter(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "QQQ"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  const revenueCost =
    summaryQuarterlyData.revenue.net_revenues.net_revenues.map(
      (value, index) => ({
        id: index,
        year: `${periodsQuarterly[index].period}/${periodsQuarterly[index].year.slice(2, 4)}`,
        userGain: value.total,
        userLost: summaryQuarterlyData.costs.total_cost[index],
      }),
    );

  const cashPaying = summaryQuarterlyData.cash_flow.cash_flows.map(
    (value, index) => ({
      id: index,
      year: `${periodsQuarterly[index].period}/${periodsQuarterly[index].year.slice(2, 4)}`,
      cashFlow: value,
      payingUser: summaryQuarterlyData.revenue.sales[index].paying_users,
    }),
  );

  const profitability = summaryData.cash_flow.profit_and_losses.findIndex(
    (value) => value > 0,
  );
  const profitabilityQ =
    summaryQuarterlyData.cash_flow.profit_and_losses.findIndex(
      (value) => value > 0,
    );
  const profitUsers =
    profitability > 0 ? summaryData.revenue.customers[profitability].final : 0;

  const positiveCashFlow = summaryData.cash_flow.cash_flows.findIndex(
    (value) => value > 0,
  );

  const positiveCashFlowQ = summaryQuarterlyData.cash_flow.cash_flows.findIndex(
    (value) => value > 0,
  );

  const deepestDebt = Math.min(...summaryData.cash_flow.cash_flows);
  const firstYearCost = Math.round(
    summaryData.costs.total_cost.slice(0, 12).reduce((a, b) => a + b, 0),
  );

  const profitabilityMonth =
    profitability > 0
      ? `${periods[profitability].period}/${periods[profitability].year}`
      : "-";
  const profitabilityQuarter =
    profitabilityQ > 0
      ? `${periodsQuarterly[profitabilityQ].period}/${periodsQuarterly[profitabilityQ].year}`
      : "-";

  const positiveCashFlowMonth =
    positiveCashFlow > 0
      ? `${periods[positiveCashFlow].period}/${periods[positiveCashFlow].year}`
      : "-";
  const positiveCashFlowQuarter =
    positiveCashFlowQ > 0
      ? `${periodsQuarterly[positiveCashFlowQ].period}/${periodsQuarterly[positiveCashFlowQ].year}`
      : "-";

  return (
    <>
      <div className="inline-flex w-[1184px] flex-col items-start justify-start  bg-white pb-10">
        <Caption>Summary</Caption>
        <div className="inline-flex items-start justify-start gap-8 self-stretch">
          <div className="relative w-[619px]">
            <div className="text-lg font-bold leading-loose text-black">
              Based on your settings, here is the outcome for your business...
            </div>
            <div className="mb-10 w-[611px] rounded-lg bg-white">
              <div className="flex flex-col gap-8">
                <TotalRevenueCostChart data={revenueCost} />
                <CashFlowChart data={cashPaying} />
              </div>

              <div className="mt-5 inline-flex w-full flex-col items-start justify-start gap-4">
                <div className="flex flex-col items-start justify-start gap-2.5 self-stretch border-b border-gray-200 pb-2 pt-0.5">
                  <div className="text-center text-base font-semibold leading-normal text-slate-700">
                    Number of users needed to make a profit each month
                  </div>
                  <div className="inline-flex items-start justify-start gap-5">
                    <div className="text-center text-sm font-bold leading-normal text-greenNormal">
                      {profitUsers}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start gap-2.5 self-stretch border-b border-gray-200 pb-2 pt-0.5">
                  <div className="text-center text-base font-semibold leading-normal text-slate-700">
                    Quarter/month when the business will start to make a profit
                  </div>
                  <div className="inline-flex items-start justify-start gap-5">
                    <div className="text-center text-sm font-bold leading-normal text-greenNormal">
                      {profitabilityQuarter} {profitabilityMonth}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start gap-2.5 self-stretch border-b border-gray-200 pb-2 pt-0.5">
                  <div className="text-center text-base font-semibold leading-normal text-slate-700">
                    Quarter/month when the business will be out of debt
                  </div>
                  <div className="inline-flex items-start justify-start gap-5">
                    <div className="text-center text-sm font-bold leading-normal text-greenNormal">
                      {positiveCashFlowQuarter} {positiveCashFlowMonth}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start gap-2.5 self-stretch border-b border-gray-200 pb-2 pt-0.5">
                  <div className="text-center text-base font-semibold leading-normal text-slate-700">
                    The deepest debt the business will have to endure
                  </div>
                  <div className="inline-flex items-start justify-start gap-5">
                    <div className="text-center text-sm font-bold leading-normal text-errorNormal">
                      {formatUSD(String(deepestDebt))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start gap-2.5 self-stretch border-b border-gray-200 pb-2 pt-0.5">
                  <div className="text-center text-base font-semibold leading-normal text-slate-700">
                    Money needed to run the business for the first year
                  </div>
                  <div className="inline-flex items-start justify-start gap-5">
                    <div className="text-center text-sm font-bold leading-normal text-greenNormal">
                      {formatUSD(String(firstYearCost))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Recomendations
              onChangePrice={() => setIsEditProductsVisible(true)}
              onChangeSalaries={() => navigate(`../payroll`)}
              onMarketing={() => setIsEditMarketingPlanVisible(true)}
              onGrowthRate={() => setIsEditGrowthRateVisible(true)}
              onPayroll={() => navigate(`../payroll`)}
              onDepartment={() => navigate(`../depexp`)}
              onMarketingCost={() => setIsEditMarketingPlanVisible(true)}
            />
          </div>
          {generalData && projectData && (
            <InitialData
              currentBalance={generalData.current_balance}
              customers={generalData.products.customers}
              growthRate={projectData.growth_rate}
              monthlyLeads={generalData.marketing.monthly_leads}
              monthlyCost={generalData.marketing.monthly_cost}
              taxRate={projectData.tax_rate || 0}
              paymentGateway={generalData.products.payment_gateway}
              products={generalData.products.products}
              onCurrentBalanceClick={() => setIsEditMoneyVisible(true)}
              onCustomersClick={() => setIsEditCustomersVisible(true)}
              onGrowthRateClick={() => setIsEditGrowthRateVisible(true)}
              onMarketingPlanClick={() => setIsEditMarketingPlanVisible(true)}
              onProductsClick={() => setIsEditProductsVisible(true)}
            />
          )}
        </div>
        {summaryData && projectData && (
          <div className="flex w-full flex-col gap-8">
            <Caption noMargin>Full report</Caption>
            <Section
              title=""
              button={
                <PeriodSelector
                  showMonths={showMonthProfitLost}
                  onChange={setShowMonthProfitLost}
                />
              }
            >
              <div className="max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <ProfitLostTable
                  data={
                    showMonthProfitLost
                      ? summaryData.cash_flow
                      : summaryQuarterlyData.cash_flow
                  }
                  periods={showMonthProfitLost ? periods : periodsQuarterly}
                />
              </div>
            </Section>
            <Section
              title="Customers"
              button={
                <PeriodSelector
                  showMonths={showMonthCustomers}
                  onChange={setShowMonthCustomers}
                />
              }
            >
              <div className="max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <CustomersTable
                  data={
                    showMonthCustomers
                      ? summaryData.revenue.sales
                      : summaryQuarterlyData.revenue.sales
                  }
                  periods={showMonthCustomers ? periods : periodsQuarterly}
                />
              </div>
            </Section>
            <Section
              title={
                <>
                  Revenue
                  <Button
                    style="ghost"
                    variant="tertiary"
                    onClick={() => setIsDetailsTableVisible(true)}
                  >
                    <span className="text-normal">See details</span>
                  </Button>
                </>
              }
              button={
                <PeriodSelector
                  showMonths={showMonthRevenue}
                  onChange={setShowMonthRevenue}
                />
              }
            >
              <div className="max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <RevenueTable
                  data={
                    showMonthRevenue
                      ? summaryData.revenue.net_revenues
                      : summaryQuarterlyData.revenue.net_revenues
                  }
                  periods={showMonthRevenue ? periods : periodsQuarterly}
                />
              </div>
            </Section>
            <Section
              title="Expenses"
              button={
                <PeriodSelector
                  showMonths={showMonthExpeneses}
                  onChange={setShowMonthExpeneses}
                />
              }
            >
              <div className="max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                <ExpensesTable
                  data={
                    showMonthExpeneses
                      ? summaryData.costs
                      : summaryQuarterlyData.costs
                  }
                  periods={showMonthExpeneses ? periods : periodsQuarterly}
                />
              </div>
            </Section>
          </div>
        )}
      </div>
      <EditInitialData
        caption="Edit initial data"
        description="Money we intend to put into the project"
        isOpen={isEditMoneyVisible}
        setIsOpen={cancelCurrentBalance}
        onConfirm={saveCurentBalance}
      >
        <div className="flex flex-col gap-5 p-5 pt-0">
          <div className="mt-4 flex items-center gap-2">
            <Input
              value={currentBalance.toString()}
              label="Sum"
              setValue={(value) => setCurrentBalance(Number(value))}
              type="text"
              id="currentBalance"
            />
          </div>
        </div>
      </EditInitialData>
      <EditInitialData
        caption="Edit initial data"
        description="Users that are already paying you"
        isOpen={isEditCustomersVisible}
        setIsOpen={cancelCustomers}
        onConfirm={saveCustomers}
      >
        <div className="flex flex-col gap-5 p-5 pt-0">
          <div className="mt-4 flex items-center gap-2">
            <Input
              value={customers.toString()}
              label="Count"
              setValue={(value) => setCustomers(Number(value))}
              type="text"
              id="currentCustomers"
            />
          </div>
        </div>
      </EditInitialData>
      <EditInitialData
        caption="Edit initial data"
        description="Growth rate"
        isOpen={isEditGrowthRateVisible}
        setIsOpen={cancelGrowthRate}
        onConfirm={saveGrowthRate}
      >
        <div className="flex flex-col gap-5 p-5 pt-0">
          <div className="mt-4 flex items-center gap-2">
            <Input
              value={growthRate.toString()}
              label="Percentage"
              setValue={(value) => setGrowthRate(Number(value))}
              type="text"
              id="currentGrowthRate"
            />
          </div>
        </div>
      </EditInitialData>
      <EditInitialData
        caption="Edit initial data"
        description="Our marketing plan"
        isOpen={isEditMarketingPlanVisible}
        setIsOpen={cancelMarketingPlan}
        onConfirm={saveMarketingPlan}
      >
        <div className="flex flex-col gap-5 p-5 pt-0">
          <div className="mt-4 flex items-center gap-2">
            <Input
              value={monthlyLeads.toString()}
              label="Monthly leads"
              setValue={(value) => setMonthlyLeads(Number(value))}
              type="text"
              id="currentLeads"
              showLabel
            />
            <Input
              value={monthlyCost.toString()}
              label="Monthly cost"
              setValue={(value) => setMonthlyCost(Number(value))}
              type="text"
              id="currentCosts"
              showLabel
            />
          </div>
        </div>
      </EditInitialData>
      <EditInitialData
        caption="Edit initial data"
        isOpen={isEditProductsVisible}
        setIsOpen={cancelProducts}
        onConfirm={saveProducts}
      >
        <div className="flex flex-col gap-5 bg-white p-5 pt-0">
          <div className="mt-4 flex items-center gap-2">
            <div className="text-base font-semibold leading-normal  text-slate-700">
              Products
            </div>
            <button onClick={handleProductAdd} className="text-normal">
              <PlusCircleFilledIcon />
            </button>
          </div>
          <div className="mt-4 flex flex-col items-center gap-6">
            {products.map((product, index) => (
              <div key={product.name} className="my-5 flex flex-wrap justify-between gap-5">
                <div className="w-[476px] shrink-0">
                  <Input
                    id={`name-${index}`}
                    label="Name"
                    type="text"
                    value={product.name}
                    setValue={handleChange(index, "name")}
                    fullWidth
                  />
                </div>
                {products.length > 1 && (
                  <button onClick={handleProductDel(index)}>
                    <TrashIcon />
                  </button>
                )}
                <Input
                  id={`price-${index}`}
                  label="Price"
                  type="text"
                  postfix="$"
                  value={String(product.price)}
                  setValue={handleChange(index, "price")}
                />
                <Input
                  id={`conversion-${index}`}
                  label="Conversion"
                  type="text"
                  postfix="%"
                  value={String(product.conversion)}
                  setValue={handleChange(index, "conversion")}
                />
              </div>
            ))}
          </div>
        </div>
      </EditInitialData>
      {summaryData && projectData && (
        <DetailsTable
          isOpen={isDetailsTableVisible}
          onClose={() => setIsDetailsTableVisible(false)}
        />
      )}
    </>
  );
};
