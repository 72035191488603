import { useEffect, useState } from "react";
import { MONTH_COUNT } from "../../const";
import {
  useGetDepartmentsQuery,
  useGetPayrollSummmaryQuery,
} from "../../services/patric";
import {
  HeaderCaption,
  HeaderPeriod,
  Line,
  TableLine,
  getMonths,
  getPeriods,
} from "../Table";
import { differenceInCalendarYears } from "date-fns";

type Props = {
  startDate: Date;
  scenarioId: number;
  showMonths: boolean;
};

type TableData = Line[];

export const TotalHeadcountTable = ({
  startDate,
  scenarioId,
  showMonths,
}: Props) => {
  const [tableData, setTableData] = useState<TableData>([]);
  const [total, setTotal] = useState<number[]>([]);
  // Receive departments and roles, then create list of departments and roles
  const { data: departments, isLoading: isLoadingDepartments } =
    useGetDepartmentsQuery(undefined);

  const { data: summary, isLoading } = useGetPayrollSummmaryQuery(scenarioId);

  useEffect(() => {
    if (departments && summary) {
      const tableData: TableData = departments
        .filter((department) =>
          summary.departments.some((s) => s.department_id === department.id),
        )
        .map((department) => ({
          name: department.name,
          values: summary.departments
            .filter((s) => s.department_id === department.id)
            .reduce((acc, s) => {
              acc[s.month - 1] = s.count;
              return acc;
            }, [] as number[]),
        }));

      tableData.forEach((department) => {
        if (!department.values[1]) department.values[1] = 0;
        for (let i = 1; i < MONTH_COUNT; i++)
          department.values[i] = department.values[i]
            ? department.values[i]
            : department.values[i - 1];
      });

      setTableData(tableData);

      const total = summary.total.reduce((acc, s) => {
        acc[s.month - 1] = s.count;
        return acc;
      }, [] as number[]);

      if (!total[0]) total[0] = 0;
      for (let i = 1; i < MONTH_COUNT; i++)
        total[i] = total[i] ? total[i] : total[i - 1];

      setTotal(total);
    }
  }, [departments, summary, showMonths]);

  if (isLoading || isLoadingDepartments) {
    return <div>Loading...</div>;
  }
  const months = getMonths(startDate, showMonths, MONTH_COUNT);
  const periods = getPeriods(months, showMonths);

  return (
    <table className="w-full table-fixed border-separate border-spacing-0">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="Departments" />
          {periods.map((month) => (
            <HeaderPeriod
              key={month.date.toISOString()}
              month={month.date}
              showMonths={showMonths}
              colorIndex={differenceInCalendarYears(month.date, startDate) % 3}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((dep) => (
          <TableLine
            key={dep.name}
            line={dep}
            showMonths={showMonths}
            months={months}
          />
        ))}
        <TableLine
          line={{ name: "Total", values: total }}
          showMonths={showMonths}
          months={months}
          variant="total"
        />
      </tbody>
    </table>
  );
};
