import { compareAsc, startOfQuarter } from "date-fns";
import { Months } from "./types";

export const getMonths = (
  startDate: Date,
  showMonths: boolean,
  monthCount: number,
) => {
  const result = Array.from({ length: monthCount }).map((_, index) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + index);
    const group = showMonths ? date : startOfQuarter(date);
    return { index: index + 1, date, group };
  });
  return result;
};

/**
 * Groups the values by month.
 *
 * @param months - An array of month objects.
 * @param values - An array of numbers representing the values.
 * @returns An array of numbers representing the grouped values.
 */
export const groupMonths = (months: Months, values: number[]) => {
  const result = values
    .map((value, index) => ({
      month: months[index]?.group,
      value,
    }))
    .reduce(
      (acc, { month, value }) => {
        const index = acc.findIndex(
          (item) => compareAsc(item.month, month) === 0,
        );
        if (index === -1) {
          return [...acc, { month, value: isNaN(value) ? 0 : value }];
        }
        const newValue = Math.round((acc[index].value + value) * 100) / 100;
        return [
          ...acc.slice(0, index),
          { month, value: isNaN(newValue) ? 0 : newValue },
          ...acc.slice(index + 1),
        ];
      },
      [] as Array<{ month: Date; value: number }>,
    )
    .map((value) => value.value);

  return result;
};

export const getPeriods = (months: Months, showMonths: boolean) => {
  const result = showMonths
    ? months
    : months
      .map(({ index, date }) => ({ index, date: startOfQuarter(date) }))
      .reduce(
        (acc, { index, date }) => {
          if (acc.find((period) => compareAsc(period.date, date) === 0)) {
            return acc;
          }
          return [...acc, { index, date }];
        },
        [] as Array<{ index: number; date: Date }>,
      );
  return result;
};
