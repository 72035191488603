import { Navigate, useParams } from "react-router-dom";
import { useGetScenarioQuery } from "../../services/patric";
import {
  DEBT_DETAILS_VIEW,
  DEPARTMENT_VIEW,
  GENERAL_VIEW,
  PAYROLL_VIEW,
  SUMMARY_VIEW,
} from "../../const";

export const Root = () => {
  const { sid } = useParams();
  const { data } = useGetScenarioQuery(Number(sid), {
    skip: isNaN(Number(sid)),
  });

  if (!sid || isNaN(Number(sid))) {
    return <Navigate to="/home" />;
  } else if (data) {
    switch (data.scenario_view) {
      case GENERAL_VIEW:
        return <Navigate to="general" />;
      case PAYROLL_VIEW:
        return <Navigate to="payroll" />;
      case DEPARTMENT_VIEW:
        return <Navigate to="depexp" />;
      case DEBT_DETAILS_VIEW:
        return <Navigate to="debt" />;
      case SUMMARY_VIEW:
        return <Navigate to="summary" />;
      default:
        return <Navigate to="general" />;
    }
  }

  return (
    <div>
      <h1>Loading</h1>
    </div>
  );
};
