import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { Caption } from "../../components/Caption";
import { Tip } from "../../components/Tip";
import { DepartmentCost } from "./DepartmentCost";
import {
  useGetDepartmentCostsQuery,
  useGetDepartmentsQuery,
  useGetProjectQuery,
  useSaveDepartmentCostsMutation,
} from "../../services/patric";
import { convertStringToDate } from "../../utils/convert";
import { ICost } from "../../models/backend";
import { DepartmentsExpensesTable } from "./components/DepartmentsExpensesTable";

export const DepExp = () => {
  const { sid, pid } = useParams();
  const { data: departments, isSuccess: depatrmentsSuccess } =
    useGetDepartmentsQuery(Number(sid));
  const { data: projectData, isSuccess: projectSuccess } = useGetProjectQuery(
    Number(pid),
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [departmentID, setDepartmentID] = useState(() =>
    departments ? departments[selectedIndex].id : 0,
  );
  useEffect(() => {
    if (departments && selectedIndex < departments.length)
      setDepartmentID(departments ? departments[selectedIndex].id : 0);
  }, [departments, selectedIndex]);

  const [fixedCosts, setFixedCosts] = useState<Array<ICost>>([]);
  const [perEmployeeCosts, setPerEmployeeCosts] = useState<Array<ICost>>([]);

  const {
    data: departmentCost,
    //isLoading,
    isError,
  } = useGetDepartmentCostsQuery(
    {
      departmentID: departmentID,
      scenarioID: sid ? Number(sid) : 0,
    },
    // { skip: !departments || !sid },
  );

  const [saveCosts] = useSaveDepartmentCostsMutation();

  useEffect(() => {
    if (departmentCost && !isError) {
      const fixedCosts = departmentCost.fixed_costs.filter((cost) =>
        Boolean(cost),
      );
      if (fixedCosts.length === 0) {
        fixedCosts.push({ cost_name: "", cost_value: "" });
      }
      setFixedCosts(fixedCosts);

      const perEmployeeCosts = departmentCost.per_employee_costs.filter(
        (cost) => Boolean(cost),
      );
      if (perEmployeeCosts.length === 0) {
        perEmployeeCosts.push({ cost_name: "", cost_value: "" });
      }
      setPerEmployeeCosts(perEmployeeCosts);
    } else {
      setFixedCosts([{ cost_name: "", cost_value: "" }]);
      setPerEmployeeCosts([{ cost_name: "", cost_value: "" }]);
    }
  }, [departmentCost, isError]);

  const [showSummary, setShowSummary] = useState(false);

  const saveCostsHandler = async () => {
    try {
      await saveCosts({
        departmentID,
        scenarioID: Number(sid ?? 0),
        body: {
          departmentID,
          fixed_costs: fixedCosts.filter((cost) => Boolean(cost.cost_name)),
          per_employee_costs: perEmployeeCosts.filter((cost) =>
            Boolean(cost.cost_name),
          ),
        },
      }).unwrap();

      return true;
    } catch (e) {
      return false;
    }
  };

  const handlePageChange = (index: number) => {
    saveCostsHandler();
    setSelectedIndex(index);
    setShowSummary(false);
  };

  if (!projectSuccess || !depatrmentsSuccess) return <div>Loading...</div>;

  return (
    <>
      <Caption>Department expenses</Caption>
      <Tip caption="Task №4">
        <p>
          For each department, specify the tools or services that you will need
          to pay. <br />These come in two categories - Fixed and Per Person. <br />To help
          you understand the kinds of expenses that might occur, we have offered
          some examples in the drop downs.
        </p>
        <p className="font-normal">
          <span className="font-semibold text-[#C35E00]">NOTE:</span> all costs should be
          monthly recurring charges. If anything is paid annually, please
          calculate the monthly amount by dividing by 12.
        </p>
      </Tip>

      <div className="my-8 w-full">
        <Tab.Group selectedIndex={selectedIndex} onChange={handlePageChange}>
          <Tab.List className="flex items-stretch justify-start">
            {departments?.map((department) => (
              <Tab key={department.id} className="border p-4 first:rounded-tl-lg last:rounded-tr-lg ui-selected:bg-secondaryBackground ui-selected:border-b-0">
                <span className="text-base font-semibold">
                  {department.name}
                </span>
              </Tab>
            ))}
            <Tab className="border p-4 first:rounded-tl-lg rounded-tr-lg ui-selected:bg-secondaryBackground bg-greenBackground ui-selected:border-b-0">
              <span className="text-base font-semibold uppercase">
                Total all departments
              </span>
            </Tab>
            <div className="border-b shrink flex-grow" />
          </Tab.List>
        </Tab.Group>
        {selectedIndex === departments.length ?
          <DepartmentsExpensesTable setNextIndex={() => setSelectedIndex(0)} />
          :
          <DepartmentCost
            departmentID={departments[selectedIndex].id}
            scenarioID={Number(sid)}
            setNextIndex={() => setSelectedIndex(selectedIndex + 1)}
            startDate={convertStringToDate(projectData?.start_month)}
            fixedCosts={fixedCosts}
            setFixedCosts={setFixedCosts}
            perEmployeeCosts={perEmployeeCosts}
            setPerEmployeeCosts={setPerEmployeeCosts}
            saveCosts={saveCostsHandler}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
          />
        }
      </div>
    </>
  );
};
