import { Button } from "../Button";
import { EMAIL } from "../../const";

export const Contact = ({ onSubmit }: { onSubmit?: () => void }) => {
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const email = encodeURIComponent(form.email.value);
    const body = encodeURIComponent(form.body.value);
    const subject = encodeURIComponent("Contact from GrowCalc");
    window.open(
      `mailto:${EMAIL}?subject=${subject}&body=${body}&email=${email}`,
    );
    setTimeout(() => {
      onSubmit && onSubmit();
    }, 1000);
  };
  return (
    <div className="min-w-[1100px]">
      <img src="/contact_back.png" alt="" />
      <div className="absolute top-8 p-8">
        <p className="text-6xl text-white">Get in touch</p>
        <form
          className="mt-8 flex w-[550px] flex-col gap-6"
          onSubmit={handleSubmit}
        >
          <input name="subject" type="hidden" value="Contact from GrowCalc" />
          <input
            className="rounded-md border border-solid border-outlines px-4 py-2"
            placeholder="Your email address"
            name="email"
            type="email"
          />
          <textarea
            className="tex min-h-36 rounded-md border border-solid border-outlines px-4 py-2"
            placeholder="Your message"
            name="body"
          />
          <Button width="w-fit" type="submit">
            Send
          </Button>
        </form>
      </div>
    </div>
  );
};
