const customTitlePlugin = {
  id: "customTitle",
  beforeDraw: (chart: any) => {
    const { ctx, width, options } = chart;
    ctx.font = "bold 14px NotoSans, sans-serif";
    const { text } = options.plugins.customTitle;
    const fullText = text.map((t: any) => ({
      text: t.text,
      color: t.color,
      w: Math.round(ctx.measureText(t.text).width),
    }));
    const totalWidth =
      fullText.reduce((acc: number, { w }: { w: number }) => acc + w, 0) + 15;
    let firstWidth = 0;
    ctx.save();

    for (let i = 0; i < fullText.length; i++) {
      const { text, color, w } = fullText[i];
      ctx.fillStyle = color;
      ctx.fillText(
        text,
        (width + totalWidth) / 2 - totalWidth + firstWidth,
        15,
      );
      firstWidth += w;
    }
    ctx.restore();
  },
};

const customAxisTitlePlugin = {
  id: "customAxisTitle",
  beforeDraw: (chart: any) => {
    const { ctx, width, options } = chart;
    ctx.font = "bold 12px NotoSans, sans-serif";
    const { leftText, rightText } = options.plugins.customAxisTitle;

    leftText && ctx.fillText(leftText, 0, 15);

    rightText &&
      ctx.fillText(rightText, width - ctx.measureText(rightText).width, 15);

    ctx.restore();
  },
};

const customBackgroundPlugin = {
  id: "customBackground",
  beforeDraw: (chart: any) => {
    const { ctx, width, options, scales, chartArea } = chart;
    ctx.save();
    const {
      position,
      color,
      fromStart = false,
    } = options.plugins.customBackground;
    const x = Math.round(scales.x.getPixelForValue(position || 0));
    const xStart = Math.round(chartArea.left);
    //const xEnd = Math.round(chartArea.right);

    ctx.globalAlpha = 0.2;
    const lineargradient = ctx.createLinearGradient(
      x,
      chart.scales.x.top,
      x,
      0,
    );
    lineargradient.addColorStop(0, color);
    lineargradient.addColorStop(0.7, "white");

    ctx.fillStyle = lineargradient;
    if (fromStart) {
      ctx.fillRect(xStart, 0, x - xStart, chart.scales.x.top);
    } else {
      ctx.fillRect(x, 0, width, chart.scales.x.top);
    }

    ctx.restore();
  },
};

export { customTitlePlugin, customAxisTitlePlugin, customBackgroundPlugin };
