export const formatSalary = (sum?: string | number) => {
  try {
    const result = typeof sum === "string" ? parseFloat(sum) : sum ?? 0;
    return result !== 0
      ? result.toLocaleString("en-US", { maximumFractionDigits: 2 })
      : "-";
  } catch (e) {
    console.error(e);
  }
  return "-";
};

export const formatUSD = (sum?: string) => {
  try {
    const result = sum ? parseFloat(sum) : "-";
    return result !== 0
      ? result.toLocaleString("en-US", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "USD",
        })
      : "-";
  } catch (e) {
    console.error(e);
  }
  return "-";
};
