export const PencilIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 15.8334H5.33333L12.5208 8.64592L11.3542 7.47925L4.16667 14.6667V15.8334ZM16.0833 7.43758L12.5417 3.93758L13.7083 2.77091C14.0278 2.45147 14.4203 2.29175 14.8858 2.29175C15.3508 2.29175 15.7431 2.45147 16.0625 2.77091L17.2292 3.93758C17.5486 4.25703 17.7153 4.64258 17.7292 5.09425C17.7431 5.54536 17.5903 5.93064 17.2708 6.25008L16.0833 7.43758ZM3.33333 17.5001C3.09722 17.5001 2.89944 17.4201 2.74 17.2601C2.58 17.1006 2.5 16.9029 2.5 16.6667V14.3126C2.5 14.2015 2.52083 14.094 2.5625 13.9901C2.60417 13.8856 2.66667 13.7917 2.75 13.7084L11.3333 5.12508L14.875 8.66675L6.29167 17.2501C6.20833 17.3334 6.11472 17.3959 6.01083 17.4376C5.90639 17.4792 5.79861 17.5001 5.6875 17.5001H3.33333ZM11.9375 8.06258L11.3542 7.47925L12.5208 8.64592L11.9375 8.06258Z"
      fill="currentColor"
    />
  </svg>
);
