import { SVGProps } from "react";
export const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#343B58"
        fillRule="evenodd"
        d="m10.807 8.036 3.779-7.56a.863.863 0 0 1 1.543 0l3.78 7.56a.863.863 0 0 1-.772 1.249h-7.56a.863.863 0 0 1-.77-1.249ZM.714.713A.714.714 0 0 0 0 1.428V8.57a.714.714 0 0 0 .714.714h7.143a.714.714 0 0 0 .714-.714V1.428a.714.714 0 0 0-.714-.715H.714Zm7.857 15a4.286 4.286 0 1 1-8.57 0 4.286 4.286 0 0 1 8.57 0Zm3.572 2.143a1.072 1.072 0 0 0 0 2.143h6.428a1.071 1.071 0 1 0 0-2.143h-6.428Zm-1.072-2.143a1.072 1.072 0 0 1 1.072-1.071h6.428a1.071 1.071 0 1 1 0 2.143h-6.428a1.072 1.072 0 0 1-1.072-1.072Zm1.072-4.285a1.071 1.071 0 0 0 0 2.143h6.428a1.071 1.071 0 1 0 0-2.143h-6.428Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
