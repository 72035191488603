import { useEffect, useState } from "react";
import { compareAsc, startOfQuarter } from "date-fns";
import {
  GroupLine,
  HeaderCaption,
  HeaderWidePeriod,
  Line,
  TableLine,
  getMonths,
} from "../../components/Table";
import { MONTH_COUNT } from "../../const";
import { useGetDepartmentSummaryQuery } from "../../services/patric";

type SummaryTableProps = {
  departmentID: number;
  scenarioID: number;
  showMonths: boolean;
  startDate: Date;
};

type TableData = {
  fixedCosts: Array<Line>;
  perEmployeeCosts: Array<Line>;
};

const CostSection = ({
  name,
  data,
  showMonths,
  months,
  total,
}: {
  name: string;
  data: Line[];
  showMonths: boolean;
  months: Array<{ index: number; date: Date; group: Date }>;
  total: number[];
}) => {
  const groupLine: Line = {
    name,
    values: total,
    children: data,
  };

  return (
    <>
      <GroupLine data={groupLine} showMonths={showMonths} months={months} />
    </>
  );
};

export const SummaryTable = ({
  departmentID,
  scenarioID,
  showMonths,
  startDate,
}: SummaryTableProps) => {
  const { data: departmentCost, isLoading } = useGetDepartmentSummaryQuery({
    departmentID,
    scenarioID,
  });
  const [tableData, setTableData] = useState<TableData>({
    fixedCosts: [],
    perEmployeeCosts: [],
  });
  const [totalFixedCosts, setTotalFixedCosts] = useState<number[]>([]);
  const [totalPerEmployeeCost, setTotalPerEmployeeCost] = useState<number[]>(
    [],
  );
  const [total, setTotal] = useState<Line>({ name: "Total", values: [] });

  useEffect(
    function syncTableData() {
      const tableData: TableData = { fixedCosts: [], perEmployeeCosts: [] };
      let totalFixedCost = 0;

      departmentCost?.fixed_monthly_costs.forEach((cost) => {
        if (cost.cost_name === "Total Fixed") {
          totalFixedCost = Number(cost.cost_value);
          return;
        }

        const index = tableData.fixedCosts.findIndex(
          (costData) => costData.name === cost.cost_name,
        );
        if (index === -1) {
          tableData.fixedCosts.push({
            name: cost.cost_name,
            values: [],
          });
          tableData.fixedCosts[tableData.fixedCosts.length - 1].values[1] =
            Number(cost.cost_value);
        } else {
          tableData.fixedCosts[index].values[1] = Number(cost.cost_value);
        }
      });

      tableData.fixedCosts.forEach((cost) => {
        for (let i = 2; i <= MONTH_COUNT; i++)
          if (!cost.values[i]) cost.values[i] = cost.values[i - 1];
      });

      const totalFixedCosts: number[] = [];
      for (let i = 1; i <= MONTH_COUNT; i++)
        totalFixedCosts[i] = totalFixedCost;

      setTotalFixedCosts(totalFixedCosts);

      const totalPerEmployeeCost: number[] = [];
      departmentCost?.per_employee_monthly_costs.forEach((costs) => {
        totalPerEmployeeCost[costs.month] = Number(costs.total);
        costs.costs.forEach((cost) => {
          const index = tableData.perEmployeeCosts.findIndex(
            (costData) => costData.name === cost.cost_name,
          );
          if (index === -1) {
            tableData.perEmployeeCosts.push({
              name: cost.cost_name,
              values: [],
            });
            tableData.perEmployeeCosts[
              tableData.perEmployeeCosts.length - 1
            ].values[costs.month] = Number(cost.cost_value);
          } else {
            tableData.perEmployeeCosts[index].values[costs.month] = Number(
              cost.cost_value,
            );
          }
        });
      });

      tableData.perEmployeeCosts.forEach((cost) => {
        for (let i = 2; i <= MONTH_COUNT; i++)
          if (!cost.values[i]) cost.values[i] = cost.values[i - 1];
      });

      setTableData(tableData);

      for (let i = 1; i <= MONTH_COUNT; i++)
        if (!totalPerEmployeeCost[i])
          totalPerEmployeeCost[i] = totalPerEmployeeCost[i - 1];

      setTotalPerEmployeeCost(totalPerEmployeeCost);

      const total: Line = { name: "Total", values: [] };
      departmentCost?.total.forEach((cost) => {
        total.values[cost.month] = Number(cost.total_costs);
      });

      for (let i = 2; i <= MONTH_COUNT; i++)
        if (!total.values[i]) total.values[i] = total.values[i - 1];

      setTotal(total);
    },
    [departmentCost],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const months = getMonths(startDate, showMonths, MONTH_COUNT);

  const periods = showMonths
    ? months
    : months
        .map(({ index, date }) => ({ index, date: startOfQuarter(date) }))
        .reduce(
          (acc, { index, date }) => {
            if (acc.find((period) => compareAsc(period.date, date) === 0)) {
              return acc;
            }
            return [...acc, { index, date }];
          },
          [] as Array<{ index: number; date: Date }>,
        );

  return (
    <table className="mb-4 w-full table-fixed border-separate border-spacing-0 bg-white">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="Expense" />
          {periods.map((month) => (
            <HeaderWidePeriod month={month.date} showMonths={showMonths} />
          ))}
        </tr>
      </thead>
      <tbody>
        <CostSection
          data={tableData.fixedCosts}
          name="Fixed cost"
          showMonths={showMonths}
          months={months}
          total={totalFixedCosts}
        />
        <CostSection
          data={tableData.perEmployeeCosts}
          name="Per employee cost"
          showMonths={showMonths}
          months={months}
          total={totalPerEmployeeCost}
        />
        <TableLine
          line={total}
          showMonths={showMonths}
          months={months}
          variant="total"
        />
      </tbody>
    </table>
  );
};
