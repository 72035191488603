import { Link, useLocation, useParams } from "react-router-dom";
import { useGetProjectQuery, useGetScenarioQuery } from "../../services/patric";

export type BreadcrumbsProps = {
  links: { name: string; to: string }[];
};

const pages = [
  {
    name: "General info",
    path: `/general`,
  },
  {
    name: "Payroll",
    path: `/payroll`,
  },
  {
    name: "Department expenses",
    path: `/depexp`,
  },
  {
    name: "Debt repayment",
    path: `/debt`,
  },
  {
    name: "Summary",
    path: `/summary`,
  },
];

export const Breadcrumbs = () => {
  const { pid, sid } = useParams();
  const location = useLocation();
  const { data: projectData } = useGetProjectQuery(Number(pid), {
    skip: isNaN(Number(pid)),
  });
  const { data: scenarioData } = useGetScenarioQuery(Number(sid), {
    skip: isNaN(Number(sid)),
  });
  const pageName = pages.find((page) => location.pathname.includes(page.path));
  return (
    <div className="flex items-center text-sm text-placeholderText">
      <Link to="/home" className="hover:text-gray-700">
        Home
      </Link>
      {projectData && (
        <>
          <span className="mx-2">/</span>
          <Link to={`/${pid}`} className="hover:text-gray-700">
            {projectData.project_name}
          </Link>
        </>
      )}
      {scenarioData && (
        <>
          <span className="mx-2">/</span>
          <Link to={`/${pid}/${sid}`} className="hover:text-gray-700">
            {scenarioData.scenario_name}
          </Link>
        </>
      )}
      <span className="mx-2">/</span>
      <Link
        to={`${location.pathname}`}
        className="font-semibold text-text2 hover:text-gray-700"
      >
        {pageName?.name}
      </Link>
    </div>
  );
};
