import { PlusCircleFilledIcon, TrashIcon } from "../../../assets";
import { ICost } from "../../../models/backend";
import { InputWithProposals } from "../InputWithProposals";

export const CostLine = ({
  cost,
  placeholder,
  onNameChange,
  onValueChange,
  onRemove,
  proposals,
  onAdd
}: {
  cost: ICost;
  placeholder: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
  onAdd?: () => void;
  proposals?: string[];
}) => {
  return (
    <div className="my-4 flex gap-4">
      <InputWithProposals
        value={cost.cost_name}
        onChange={onNameChange}
        placeholder={placeholder}
        proposals={proposals}
      />
      <div
        className={`relative inline-flex justify-between gap-x-1.5 rounded-md px-0 py-0 shadow-sm ring-1 ring-inset ring-gray-300`}
      >
        <input
          className="inline-flex h-12 w-64 items-center justify-between rounded-md border border-gray-300 bg-white px-4 py-3"
          value={cost.cost_value}
          onChange={onValueChange}
          placeholder="Price"
        />
        <div className="absolute bottom-3.5 end-2.5 inline-flex h-5 w-5 items-center justify-center">
          {"$"}
        </div>
      </div>
      <div className="min-w-20">
        {onAdd && (
          <button
            onClick={onAdd}
          >
            <span className="text-text2">
              <PlusCircleFilledIcon />
            </span>
          </button>)}
        <button className="py-2 pl-3" onClick={onRemove}>
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
