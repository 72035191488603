import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "../../assets";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type ListboxItem = {
  item: string;
  itemId: string;
};

export const CustomListbox = ({
  selected,
  data,
  setSelected,
}: {
  selected: ListboxItem;
  data: Array<ListboxItem>;
  setSelected: (item: ListboxItem) => void;
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selected.item}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {data.map((item) => (
              <Menu.Item key={item.itemId}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "w-full px-4 py-2 text-left text-sm flex justify-between"
                    )}
                    onClick={() => setSelected(item)}
                  >
                    {item.item}
                    {item === selected ? (
                      <span className="flex items-center pl-3 text-[#279914]">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
