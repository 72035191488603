import {
  CheckIcon,
  EngineeringDpt,
  FinanceDpt,
  GeneralDpt,
  MarketingDpt,
  OperationDpt,
  ProductDpt,
  SalesDpt,
} from "../../assets";
import { SupportDpt } from "../../assets/icons/SupportDpt";

type DepartmentCardProps = {
  name: string;
  description: string;
  active: boolean;
  onClick?: () => void;
};

export const IconByName = ({ name }: { name: string }) => {
  const params = { width: 24, height: 24 };
  switch (name) {
    case "General":
      return <GeneralDpt {...params} />;
    case "Product":
      return <ProductDpt {...params} />;
    case "Finance":
      return <FinanceDpt {...params} />;
    case "Operations":
      return <OperationDpt {...params} />;
    case "Engineering / R&D":
      return <EngineeringDpt {...params} />;
    case "Marketing":
      return <MarketingDpt {...params} />;
    case "Sales (owned by CEO)":
      return <SalesDpt {...params} />;
    case "Support (owned by COO)":
      return <SupportDpt {...params} />;
    default:
      return <GeneralDpt {...params} />;
  }
};

export const DepartmentCard = ({
  name,
  description,
  active,
  onClick,
}: DepartmentCardProps) => (
  <div
    className={`flex h-56 w-60 cursor-pointer flex-col gap-2  rounded-lg border-2 bg-white px-4 pb-3.5 pt-1
  ${active ? "border-sky-700" : "border-gray-200"}`}
    onClick={onClick}
  >
    <div className="flex h-12 w-full justify-between">
      <div
        className={`flex h-10 w-10 items-center justify-center self-end rounded-full  ${active ? "bg-background" : "bg-disabledButton"}`}
      >
        <IconByName name={name} />
      </div>
      <div
        className={`h-6 w-6 self-start rounded-full text-white ${active ? "bg-sky-700" : "border bg-white"}`}
      >
        <CheckIcon />
      </div>
    </div>

    <div className="flex flex-col items-start justify-start gap-0.5">
      <div className="text-base font-bold leading-relaxed text-gray-800">
        {name}
      </div>
      <div className="text-xs font-normal leading-normal text-slate-700">
        {description}
      </div>
    </div>
  </div>
);
