import { QuestionMark } from "../../assets";

export const Description = (props: { children: React.ReactNode }) => {
  return (
    <div className="group relative">
      <div className=" text-successNormal">
        <QuestionMark />
      </div>
      <div className=" absolute -top-0 left-4 z-20 hidden w-max max-w-96 items-center justify-between gap-1 rounded-md border bg-white px-3 py-1 font-normal shadow-md group-hover:block">
        {props.children}
      </div>
    </div>
  );
};
