import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Button, Input } from "../../components";
import { CloseIcon } from "../../assets/icons/CloseIconn";

export const RenameProjectDialog = ({
    show,
    title,
    onClose,
    onSubmit
}: {
    show: boolean;
    title: string;
    onClose: () => void;
    onSubmit: (title:string) => void;
}) =>{
    const [newTitle, setNewTitle] = useState(title);

    return (<Transition appear show={show} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            className="flex justify-between"
                        >
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Edit name</h3>
                            <button onClick={() => onClose}><CloseIcon /></button>
                        </Dialog.Title>
                        <div className="mt-8">
                            <Input id="newTitle" type="text" value={newTitle} onChange={setNewTitle} label="Name" showLabel={true} fullWidth/>
                        </div>

                        <div className="w-full flex justify-start mt-8 gap-4">
                            <Button style="outline" onClick={onClose}>Cancel</Button>
                            <Button onClick={()=>onSubmit(newTitle)}>Save</Button>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </div>
    </Dialog>
</Transition>)}