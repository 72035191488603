import { CollapseIcon, PlusCircleFilledIcon, TrashIcon } from "../../assets";
import {
  Department,
  IHpPosition,
  IHpPositionEmployee,
  TypesOfEmployment,
} from "../../models/department";
import { employmentTypes } from "../../models/empolymentTypes";
import { CustomListbox, ListboxItem } from "../CustomListbox";
import { Input } from "../Input";
import { DepartmentPositions } from "./DepartmentPosition";

const CustomPosition = ({
  position,
  employee,
  onPositionChange,
  onEmployeeChange,
  onPositionAdd,
  onPositionDelete,
}: {
  position: IHpPosition;
  employee?: IHpPositionEmployee;
  onPositionChange: (position: IHpPosition) => void;
  onEmployeeChange: (employee: IHpPositionEmployee) => void;
  onPositionAdd: (position: IHpPosition) => void;
  onPositionDelete: (position: IHpPosition) => void;
}) => {
  return (
    <>
      <td colSpan={2} className="px-4 py-3">
        <Input
          label="Add role"
          id="custom-position"
          value={position.role_name}
          type="text"
          fullWidth
          onChange={(value) => {
            onPositionChange({ ...position, role_name: value });
          }}
        />
      </td>
      <td colSpan={3} className="px-4 py-3">
        <Input
          label="Enter the annual salary"
          id="custom-salary"
          value={position.annual ? position.annual.toString() : ""}
          type="number"
          fullWidth
          onChange={(value) => {
            const annual = parseFloat(value);
            onPositionChange({ ...position, annual });
          }}
        />
      </td>
      <td>
        {employee && (
          <CustomListbox
            selected={
              employmentTypes.find(
                (type) => type.item === employee.typeOfEmployment,
              ) ?? employmentTypes[0]
            }
            data={employmentTypes}
            setSelected={(item: ListboxItem) => {
              onEmployeeChange({
                ...employee,
                typeOfEmployment: item.item as TypesOfEmployment,
              });
            }}
          />
        )}
      </td>
      {/* <td>
        {employee && (
          <input
            placeholder="Enter number"
            className="w-20 rounded-md border border-outlines py-2 pl-3 pr-10"
            value={employee.numberOfEmployees}
            onChange={(e) => {
              onEmployeeChange({
                ...employee,
                numberOfEmployees: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
                count: Array.from({ length: MONTH_COUNT }, () => isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)),
              });
            }
            }
          />)}
      </td> */}
      <td className="flex w-20 flex-row items-center">
        <div className="my-1">
          <button
            className="py-2 pl-3"
            onClick={() => {
              onPositionDelete(position);
            }}
          >
            <TrashIcon />
          </button>
        </div>
        <div>
          <button
            className="py-2 pl-3 text-text2"
            onClick={() => {
              onPositionAdd(position);
            }}
            title="Add new for this role"
          >
            <PlusCircleFilledIcon />
          </button>
        </div>
      </td>
    </>
  );
};

export const DepartmentPlan = ({
  department,
  isOpen,
  onClick,
  onPositionChange,
  onPositionAdd,
  onPositionDelete,
  onCustomPositionChange,
  onCustomPositionDelete,
  onCustomPositionAdd,
  hpPositions,
  hpEmployees,
}: {
  department: Department;
  isOpen: boolean;
  onClick: () => void;
  onPositionChange: (position: IHpPositionEmployee) => void;
  onPositionAdd: (position: IHpPosition) => void;
  onPositionDelete: (position: IHpPositionEmployee) => void;
  onCustomPositionChange: (position: IHpPosition) => void;
  onCustomPositionDelete: (position: IHpPosition) => void;
  onCustomPositionAdd: () => void;
  hpPositions: IHpPosition[];
  hpEmployees: IHpPositionEmployee[];
}) => {
  const standartPositions = hpPositions.filter((position) => !position.custom);
  const customPositions = hpPositions.filter((position) => position.custom);
  if (customPositions.length === 0) {
    customPositions.push({
      role_id: department.id * 1000 + 1,
      role_name: "",
      department_id: department.id,
      custom: true,
      annual: 0,
      fulltime: {
        annual: 0,
        monthly: 0,
      },
      freelance: {
        annual: 0,
        monthly: 0,
      },
      contractor: {
        annual: 0,
        monthly: 0,
      },
    });
  }

  return (
    <>
      <tr>
        <td colSpan={8} className="border-y py-4">
          <button onClick={onClick} className="flex">
            <CollapseIcon isOpen={isOpen} />
            {department.name}
          </button>
        </td>
      </tr>
      {customPositions.map((position) => (
        <tr className={`bg-background ${isOpen ? "" : "hidden"}`}>
          <CustomPosition
            key={position.role_id}
            position={position}
            employee={hpEmployees.find(
              (employee) => employee.role_id === position.role_id,
            )}
            onPositionChange={onCustomPositionChange}
            onEmployeeChange={onPositionChange}
            onPositionAdd={onCustomPositionAdd}
            onPositionDelete={onCustomPositionDelete}
          />
        </tr>
      ))}
      {standartPositions.map((position) => (
        <DepartmentPositions
          key={`${position.role_name}-${department.name}`}
          hpPosition={position}
          hpEmployees={hpEmployees.filter(
            (employee) => employee.role_id === position.role_id,
          )}
          isOpen={isOpen}
          onPositionChange={(position) => {
            onPositionChange(position);
          }}
          onPositionAdd={() => {
            onPositionAdd(position);
          }}
          onPositionRemove={(position) => {
            onPositionDelete(position);
          }}
        />
      ))}
    </>
  );
};
