import {
  GroupLineSimple,
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
} from "../../components/Table";
import { IRevenueNetRevenues } from "../../models/backend";

export const RevenueTable = ({
  data,
  periods,
}: {
  data: IRevenueNetRevenues;
  periods: Periods;
}) => {
  const totalRevenue: Line = {
    name: "Total Revenue",
    values: data.net_revenues.map((value) => value.total),
    children: data.products.reduce((prev, current, index) => {
      prev[index] = {
        name: current,
        values: data.net_revenues.map((value) => value.net_revenues[index]),
      };
      return prev;
    }, [] as Line[]),
  };

  return (
    <>
      <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
        <thead className="bg-gray-200">
          <tr>
            <HeaderCaption caption="" />
            {periods.map((month) => (
              <HeaderPeriodSimple
                key={`${month.period}-${month.year}-revenue`}
                period={month.period}
                year={month.year}
                width="w-28"
                isLast={month.isLast}
                colorIndex={month.colorIndex}
              />
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          <GroupLineSimple data={totalRevenue} periods={periods} />
        </tbody>
      </table>
    </>
  );
};
