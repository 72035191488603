import { useState } from "react";
import { Button, Section } from "../../../components";
import { PeriodSelector } from "../../../components/PeriodSelector";
import {
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  TableLineSimple,
} from "../../../components/Table";
import { useGetDepartmentsQuery, useGetSummaryQuarterlyQuery, useGetSummaryQuery, useUpdateStepMutation } from "../../../services/patric";
import { useNavigate, useParams } from "react-router-dom";
import { endOfMonth, endOfQuarter, endOfYear, format, isEqual, parse } from "date-fns";
import { DEBT_DETAILS_VIEW } from "../../../const";

export const DepartmentsExpensesTable = ({ setNextIndex }: { setNextIndex: () => void; }) => {
  const { sid } = useParams();
  const { data: deps } = useGetDepartmentsQuery();
  const { data: summaryData } = useGetSummaryQuery({ scenarioID: Number(sid) });
  const { data: summaryQuarterlyData } = useGetSummaryQuarterlyQuery({
    scenarioID: Number(sid),
  });
  const [updateScenario] = useUpdateStepMutation();
  const navigate = useNavigate();
  const [showMonths, setShowMonths] = useState(false);

  if (!summaryData || !summaryQuarterlyData) return null;

  const data = showMonths ? summaryData?.costs : summaryQuarterlyData?.costs;

  let colorIndex = 0;
  const periodsMonthly = summaryData.periods.map((period) => {
    const date = parse(period, "yyyy-MM", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfMonth(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "MMM"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  colorIndex = 0;
  const periodsQuarterly = summaryQuarterlyData.periods.map((period) => {
    const date = parse(period, "yyyy-Q", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfQuarter(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "QQQ"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  const periods = showMonths ? periodsMonthly : periodsQuarterly;

  const totalRevenue: Line[] = [
    ...data.departments
      .flatMap((value) => {
        return value.departments.map((val) => ({
          month: value.month - 1 * (showMonths ? 1 : 0), //TODO: ask back to make it 0 based for monthly & quarterly data
          department_id: val.department_id,
          cost: val.cost,
        }));
      })
      .reduce(
        (prev, cur) => {
          if (!prev[cur.department_id]) {
            prev[cur.department_id] = [];
          }
          prev[cur.department_id][cur.month] = cur.cost;
          return prev;
        },
        [] as Array<Array<number>>,
      )
      .map(
        (value, index2): Line => ({
          name: deps?.find(({ id }) => id === index2)?.name ?? "",
          values: value,
        }),
      )
      .filter(Boolean),
  ];

  const totalLine: Line = totalRevenue.reduce((prev, current) => {
    for (let i = 0; i < current.values.length; i++) {
      prev.values[i] = (prev.values[i] ?? 0) + current.values[i];
    }
    return prev;
  }, { name: "Total", values: [] })

  const handleNextDepartment = () => {
    setNextIndex();
  };

  const handleNextPage = () => {
    updateScenario({ scenarioId: sid!, step: DEBT_DETAILS_VIEW });
    navigate("../debt");
  };

  return (
    <div className="bg-secondaryBackground border border-t-0 p-4">
      <Section
        title="Summary table"
        button={
          <PeriodSelector
            showMonths={showMonths}
            onChange={setShowMonths}
          />
        }
      >
        <div className="w-full overflow-y-clip overflow-x-scroll rounded-lg border  border-gray-200">
          <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
            <thead className="bg-gray-200">
              <tr>
                <HeaderCaption caption="" />
                {periods.map((month) => (
                  <HeaderPeriodSimple
                    key={`${month.period}-${month.year}`}
                    period={month.period}
                    year={month.year}
                    colorIndex={month.colorIndex}
                    isLast={month.isLast}
                    width="w-28"
                  />
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {totalRevenue.map((data) =>
                <TableLineSimple key={data.name} line={data} periods={periods} />)}
              <TableLineSimple variant="total" line={totalLine} periods={periods} />
            </tbody>
          </table>
        </div>
      </Section>
      <div className="mt-16 flex gap-6">
        <Button isDisabled>Save and Exit</Button>
        <Button onClick={handleNextDepartment}>Next department</Button>
        <Button onClick={handleNextPage}>Continue</Button>
      </div>
    </div>
  );
};
