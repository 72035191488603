import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ProjectsHeader } from "../Header";
import { Sidebar } from "../Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { locations } from "../../const";
import { Breadcrumbs } from "../Breadcrumbs";

export const MainLayout = () => {
  const { user, loading } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!user && !loading) {
    return <Navigate to={locations.login} />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ProjectsHeader />
      <div className="flex w-full min-w-[1230px] flex-row pr-2 ">
        <Sidebar />
        <div className="w-[calc(100vw-280px)] max-w-7xl flex-grow flex-shrink p-6">
          <div className="pt-6">
            <Breadcrumbs />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
