import { GraphUp, MoneyFinance, PencilIcon, People } from "../../assets";
import { IProduct } from "../../models/backend";
import { CardWithImage } from "./CardWithImage";

type Props = {
  currentBalance: number;
  customers: number;
  growthRate: number;
  monthlyLeads: number;
  monthlyCost: number;
  taxRate: number;
  paymentGateway: number;
  products: IProduct[];
  onCurrentBalanceClick: () => void;
  onCustomersClick: () => void;
  onGrowthRateClick: () => void;
  onMarketingPlanClick: () => void;
  onProductsClick: () => void;
};

export const InitialData = ({
  currentBalance,
  customers,
  growthRate,
  monthlyLeads,
  monthlyCost,
  taxRate,
  paymentGateway,
  products,
  onCurrentBalanceClick,
  onCustomersClick,
  onGrowthRateClick,
  onMarketingPlanClick,
  onProductsClick,
}: Props) => {
  return (
    <div className="relative flex w-[453px] flex-col gap-5 rounded-lg bg-gray-100 p-5 pb-16">
      <div className=" font-['Noto Sans'] text-lg font-bold leading-loose text-black">
        Initial data
      </div>

      <CardWithImage
        title="Money you intend to put in the project"
        value={`${currentBalance}`}
        image={<MoneyFinance />}
        onClick={onCurrentBalanceClick}
      />
      <CardWithImage
        title="Users that are already paying you"
        value={`${customers} people`}
        image={<People />}
        onClick={onCustomersClick}
      />
      <CardWithImage
        title="Growth rate"
        value={`${growthRate}%`}
        image={<GraphUp />}
        onClick={onGrowthRateClick}
      />
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <div className="inline-flex items-center justify-center gap-2">
          <div className="font-['Noto Sans'] text-base font-semibold leading-normal text-slate-700">
            Your marketing plan
            <button className="text-normal" onClick={onMarketingPlanClick}>
              <PencilIcon />
            </button>
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="relative h-5 w-5" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-2.5 self-stretch">
          <div className="relative h-[102px] w-[413px]">
            <div className="absolute left-0 top-0 h-[102px] w-[413px] rounded-lg bg-white" />
            <div className="absolute left-[23px] top-[12px] inline-flex h-[82px] w-[366px] flex-col items-start justify-start">
              <div className="inline-flex w-[366px] items-center justify-between border-b border-gray-300 py-1.5">
                <div className="font-['Noto Sans'] text-sm font-semibold leading-normal text-slate-700">
                  Monthly leads:
                </div>
                <div className="font-['Noto Sans'] text-base font-bold leading-relaxed text-gray-800">
                  {monthlyLeads}
                </div>
              </div>
              <div className="inline-flex w-[366px] items-center justify-between py-1.5">
                <div className="font-['Noto Sans'] text-sm font-semibold leading-normal text-slate-700">
                  Montly cost:
                </div>
                <div className="font-['Noto Sans'] text-base font-bold leading-relaxed text-gray-800">
                  {monthlyCost}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <div className="inline-flex items-center justify-start gap-2 self-stretch">
          <div className="font-['Noto Sans'] text-base font-semibold leading-normal text-slate-700">
            Taxes and fees
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-2.5 self-stretch">
          <div className="relative h-[102px] w-[413px]">
            <div className="absolute left-0 top-0 h-[102px] w-[413px] rounded-lg bg-white" />
            <div className="absolute left-[23px] top-[12px] inline-flex h-[82px] w-[366px] flex-col items-start justify-start">
              <div className="inline-flex w-[366px] items-center justify-between border-b border-gray-300 py-1.5">
                <div className="font-['Noto Sans'] text-sm font-semibold leading-normal text-slate-700">
                  Tax rate:
                </div>
                <div className="font-['Noto Sans'] text-base font-bold leading-relaxed text-gray-800">
                  {taxRate}
                </div>
              </div>
              <div className="inline-flex w-[366px] items-center justify-between py-1.5">
                <div className="font-['Noto Sans'] text-sm font-semibold leading-normal text-slate-700">
                  Payment gateway:
                </div>
                <div className="font-['Noto Sans'] text-base font-bold leading-relaxed text-gray-800">
                  {paymentGateway}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <div className="flex flex-col items-start justify-center gap-2">
          <div className="inline-flex items-center justify-start gap-2 self-stretch">
            <div className="font-['Noto Sans'] text-base font-semibold leading-relaxed text-slate-700">
              Your products
              <button className="text-normal" onClick={onProductsClick}>
                <PencilIcon />
              </button>
            </div>
            <div className="flex items-center justify-start gap-1">
              <div className="relative h-5 w-5" />
            </div>
          </div>
        </div>
        <div className="w-[413px] rounded-md bg-white px-5">
          {products && (
            <table className="mt-8 w-[373px] table-fixed">
              <thead className="font-['Noto Sans'] border-b-2 text-sm font-semibold leading-normal text-slate-500 ">
                <th className="text-left">Name</th>
                <th className="text-right">Price</th>
                <th className="text-right">Conversion</th>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr className="border-t">
                    <td className="font-['Noto Sans'] overflow-hidden text-ellipsis whitespace-nowrap py-2 text-base font-normal leading-normal text-black">
                      {product.name}
                    </td>
                    <td className="font-['Noto Sans'] text-right text-base font-bold leading-normal text-black">
                      ${product.price}
                    </td>
                    <td className="font-['Noto Sans'] text-right text-base font-bold leading-normal text-black">
                      {product.conversion}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
