import { Disclosure } from "@headlessui/react";

const DisclosureButton = ({
  header,
  description,
}: {
  header: string;
  description: string;
}) => (
  <Disclosure.Button>
    <div className="mb-4 inline-flex flex-col items-start justify-start gap-1">
      <div className="text-sm font-semibold leading-normal  text-slate-500">
        {header}
      </div>
      <div className="text-base font-semibold leading-relaxed  text-gray-800">
        {description}
      </div>
    </div>
  </Disclosure.Button>
);

const Panel = ({ children }: { children: React.ReactNode }) => (
  <Disclosure.Panel className="text-sm font-normal leading-normal text-black">
    {children}
  </Disclosure.Panel>
);

const List = ({ children }: { children: React.ReactNode }) => (
  <ol className="ml-4 list-outside list-decimal text-sm font-normal leading-normal">
    {children}
  </ol>
);

const ButtonLink = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <button
    onClick={onClick}
    className="text-sm font-semibold leading-normal text-normal underline"
  >
    {children}
  </button>
);

export const Recomendations = ({
  onChangePrice,
  onChangeSalaries,
  onMarketing,
  onGrowthRate,
  onPayroll,
  onDepartment,
  onMarketingCost,
}: {
  onChangePrice: () => void;
  onChangeSalaries: () => void;
  onMarketing: () => void;
  onGrowthRate: () => void;
  onPayroll: () => void;
  onDepartment: () => void;
  onMarketingCost: () => void;
}) => {
  return (
    <div className="inline-flex w-[613px] items-center justify-start gap-2.5 rounded-lg border-l-4 border-orange-300 bg-[#FFFBF2] px-5 pb-8 pt-4">
      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2.5">
        <div className="items-center justify-between self-stretch border-b border-red-300 py-1.5">
          <Disclosure>
            <DisclosureButton
              header="Goal #1"
              description="How can I reduce the time needed to become profitable?"
            />

            <Panel>
              <List>
                <li className="mb-4">
                  As quickly as possible, get a version of your product out
                  there that people are willing to pay for. Make it simple and
                  valuable.
                </li>
                <li className="mb-4">
                  Pay attention to lead generation - i.e., attracting as many
                  people ("leads") as you can to your web site / store. This
                  involves advertising and SEO (Search Engine Optimization).
                </li>
                <li className="mb-4">
                  Try to make sure that the leads you are attracting are the
                  most likely to buy your product / service.
                  <p className="border-l-2 border-l-black py-1 pl-2">
                    If you are selling pain medication, then you need to attract
                    people who have the right kind of pain. You can control this
                    by choosing where to advertise and what your ads say.
                  </p>
                </li>
                <li className="mb-4">
                  Make sure that your web site can convince your leads to buy
                  your product / service. This is called "conversion" and is
                  measured as a percentage of the number of people who visit
                  your site vs the number of people who buy your product /
                  service.
                </li>
              </List>
            </Panel>
          </Disclosure>
        </div>
        <div className="items-center justify-between self-stretch border-b border-red-300 py-1.5">
          <Disclosure>
            <DisclosureButton
              header="Goal #2"
              description="How can I increase my profitability?"
            />

            <Panel>
              <List>
                <li className="mb-4">
                  Raise your prices.{" "}
                  <ButtonLink onClick={onChangePrice}>Change price</ButtonLink>
                </li>
                <li className="mb-4">
                  Introduce different pricing levels - e.g., bronze / silver /
                  gold or standard / pro
                </li>
                <li className="mb-4">
                  Lower your costs - e.g., reduce salaries.{" "}
                  <ButtonLink onClick={onChangeSalaries}>
                    Change salaries
                  </ButtonLink>
                  <p className="border-l-2 border-l-black pl-2">
                    Hire part-time or only on-demand freelancers. Use services
                    like Fiverr or UpWork instead of hiring. Delay hiring people
                    until you really need them. Refuse to spend money unless you
                    are forced to do it. Always look for free options.
                  </p>
                </li>
              </List>
            </Panel>
          </Disclosure>
        </div>
        <div className="items-center justify-between self-stretch border-b border-red-300 py-1.5">
          <Disclosure>
            <DisclosureButton
              header="Goal #3"
              description="What would be my ideal pricing?"
            />

            <Panel>
              <p className="mb-4">
                Find people who would be ideal customers. Describe the problem
                and ask them "What would be a crazy price for a solution to that
                problem?" Then lower that price slowly until they stop calling
                it crazy and agree that they would pay it.
              </p>
            </Panel>
          </Disclosure>
        </div>
        <div className="items-center justify-between self-stretch border-b border-red-300 py-1.5">
          <Disclosure>
            <DisclosureButton
              header="Goal #4"
              description="How can I minimize the debt I will have while becoming profitable?"
            />
            <Panel>
              <List>
                <li className="mb-4">
                  Increase revenue as fast as possible.
                  <div className="border-l-2 border-l-black pl-2">
                    <p>
                      <ul className="list-inside list-disc">
                        <li>
                          Increase your prices.{" "}
                          <ButtonLink onClick={onChangePrice}>
                            Change price
                          </ButtonLink>
                        </li>
                        <li>
                          Increase the number of leads you expect your marketing
                          will attract.{" "}
                          <ButtonLink onClick={onMarketing}>
                            Marketing
                          </ButtonLink>
                        </li>
                        <li>
                          Increase your conversion rate (the percentage of leads
                          you expect to become customers).
                        </li>
                        <li>
                          Increase the growth rate based on the belief that your
                          popularity will grow faster than expected.{" "}
                          <ButtonLink onClick={onGrowthRate}>
                            Increasing growth rate
                          </ButtonLink>
                        </li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="mb-4">
                  Decrease costs as much as possible. Run on a minimum budget.
                  <div className="border-l-2 border-l-black pl-2">
                    <p>
                      <ul className="list-inside list-disc">
                        <li>
                          Hire fewer people or delay hiring them until later{" "}
                          <ButtonLink onClick={onPayroll}>Payroll</ButtonLink>
                        </li>
                        <li>
                          Hire contractors or freelancers instead of full-time
                          employees{" "}
                          <ButtonLink onClick={onPayroll}>Payroll</ButtonLink>
                        </li>
                        <li>Pay lower salaries to the people you hire</li>
                        <li>
                          Eliminate or pay less for services or tools in each
                          department{" "}
                          <ButtonLink onClick={onDepartment}>
                            Department
                          </ButtonLink>
                        </li>
                        <li>
                          Lower your marketing costs by relying less on ads and
                          other pay-for services and increasing your use of
                          grassroots marketing, like social media content,
                          word-of-mouth, and referrals.{" "}
                          <ButtonLink onClick={onMarketingCost}>
                            Marketing cost
                          </ButtonLink>
                        </li>
                      </ul>
                    </p>
                  </div>
                </li>
              </List>
            </Panel>
          </Disclosure>
        </div>
      </div>
    </div>
  );
};
