import { SubCaption } from "../Caption";

type SectionProps = {
  children?: React.ReactNode;
  title: React.ReactNode;
  description?: string;
  button?: React.ReactNode;
  className?: string;
  isButtonInEnd?: boolean;
};

export const Section = ({
  children,
  title,
  description,
  button,
  className = "",
  isButtonInEnd = true,
}: SectionProps) => {
  return (
    <div className={className}>
      <div className="flex flex-col">
        <div
          className={`flex w-full  ${isButtonInEnd ? "justify-between" : "justify-normal gap-4"}`}
        >
          <SubCaption>{title}</SubCaption>
          {button}
        </div>
        {description && <p className="text-primaryText">{description}</p>}
        {children}
      </div>
    </div>
  );
};
