import { Navigate, Outlet } from "react-router-dom";
import { Header, ProjectsHeader } from "../Header";
import { useAuth } from "../../hooks/useAuth";
import { locations } from "../../const";

export const HomeLayout = ({
  showProjects = true,
}: {
  showProjects?: boolean;
}) => {
  const { user, loading } = useAuth();
  if (!user && !loading) {
    return <Navigate to={locations.login} />;
  }

  return (
    <>
      {showProjects ? <ProjectsHeader /> : <Header />}
      <div className="flex w-full flex-row">
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </>
  );
};
