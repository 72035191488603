import {
  GroupLineSimple,
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
} from "../../components/Table";
import { ICosts } from "../../models/backend";
import { useGetDepartmentsQuery } from "../../services/patric";

export const ExpensesTable = ({
  data,
  periods,
}: {
  data: ICosts;
  periods: Periods;
}) => {
  const { data: deps } = useGetDepartmentsQuery();

  const totalRevenue: Line = {
    name: "Total Expenses",
    values: data.total_cost,
    children: [
      {
        name: "Payrol",
        values: data.payroll,
      },
      {
        name: "Debt Repayment",
        values: data.debt_repayment,
      },
      {
        name: "Marketing Plan",
        values: data.marketing_plan,
      },
      ...data.departments
        .flatMap((value) => {
          return value.departments.map((val) => ({
            month: value.month - 1,
            department_id: val.department_id,
            cost: val.cost,
          }));
        })
        .reduce(
          (prev, cur) => {
            if (!prev[cur.department_id]) {
              prev[cur.department_id] = [];
            }
            prev[cur.department_id][cur.month] = cur.cost;
            return prev;
          },
          [] as Array<Array<number>>,
        )
        .map(
          (value, index2): Line => ({
            name: deps?.find(({ id }) => id === index2)?.name ?? "",
            values: value,
          }),
        )
        .filter(Boolean),
    ],
  };

  return (
    <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="" />
          {periods.map((month) => (
            <HeaderPeriodSimple
              key={`${month.period}-${month.year}-expenses`}
              period={month.period}
              year={month.year}
              colorIndex={month.colorIndex}
              isLast={month.isLast}
              width="w-28"
            />
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-100 border-t border-gray-100">
        <GroupLineSimple data={totalRevenue} periods={periods} />
      </tbody>
    </table>
  );
};
