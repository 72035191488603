import { formatSalary } from "../../../utils/format";

export const TotalCost = ({ total }: { total: number }) => {
  return (
    <div className="flex-grow">
      <div className="flex items-end justify-end gap-2 pr-12">
        <div className="flex w-64 justify-between">
          <div className="font-bold">Total cost</div>
          <div className="font-bold">$ {formatSalary(String(total))} /mo</div>
        </div>
      </div>
    </div>
  );
};
