import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDepartmentsQuery,
  useGetGeneralInfoQuery,
  useSaveDepartmentsMutation,
  useSaveGeneralInfoMutation,
  useUpdateStepMutation,
} from "../../services/patric";
import {
  Button,
  Tip,
  Section,
  Input,
  DepartmentCard,
  Alert,
  Caption,
  Helper,
  Description,
} from "../../components";
import { IProduct } from "../../models/backend";
import { TrashIcon, PlusCircleFilledIcon } from "../../assets";
import { PAYROLL_VIEW } from "../../const";
import { isBackendError } from "../../models/utils";
import { Reminder } from "../../components/Reminder";
import { useError } from "../../hooks/useError";

export const General = () => {
  const { sid } = useParams();
  const navigate = useNavigate();
  const { data } = useGetGeneralInfoQuery(Number(sid));
  const [
    saveData,
    {
      isError: isSaveGeneralInfoError,
      error: generalInfoError,
      isSuccess: isSaveGeneralInfoSuccess,
    },
  ] = useSaveGeneralInfoMutation();
  const [
    saveDepartments,
    {
      isError: isSaveDepartmentsError,
      error: departmentsError,
      isSuccess: isSaveDepartmentsSuccess,
    },
  ] = useSaveDepartmentsMutation();
  const { data: departmentsData } = useGetDepartmentsQuery();
  const [updateScenario] = useUpdateStepMutation();

  const [currentBalance, setCurrentBalance] = useState(0);
  const [customers, setCustomers] = useState("0");
  const [monthlyLeads, setMonthlyLeads] = useState("0");
  const [monthlyCost, setMonthlyCost] = useState("0");
  const [salesCommission, setSalesCommission] = useState("0");
  const [fees, setFees] = useState("0");
  const [products, setProducts] = useState<IProduct[]>([
    { name: "", price: 0, conversion: 0 },
  ]);
  const [departments, setDepartments] = useState<number[]>([]);
  const { handleError, errorText } = useError({
    overall: "",
  });

  useEffect(() => {
    if (isNaN(Number(sid))) {
      navigate("/home");
    }
  }, [sid, navigate]);

  useEffect(() => {
    let errorMessage = "";

    if (isSaveGeneralInfoError && isBackendError(generalInfoError)) {
      errorMessage = generalInfoError.data.detail;
    }

    if (isSaveDepartmentsError && isBackendError(departmentsError)) {
      errorMessage = departmentsError.data.detail;
    }
    handleError("overall", errorMessage);
  }, [
    isSaveGeneralInfoError,
    generalInfoError,
    isSaveDepartmentsError,
    departmentsError,
    handleError,
  ]);

  useEffect(() => {
    if (isSaveGeneralInfoSuccess && isSaveDepartmentsSuccess) {
      updateScenario({ scenarioId: sid!, step: PAYROLL_VIEW });
      navigate("../payroll");
    }
  }, [isSaveGeneralInfoSuccess, isSaveDepartmentsSuccess, navigate, sid, updateScenario]);

  useEffect(() => {
    if (data) {
      setCurrentBalance(data.current_balance || 0);
      setCustomers(String(data.products?.customers || 0));
      setMonthlyLeads(String(data.marketing?.monthly_leads || 0));
      setMonthlyCost(String(data.marketing?.monthly_cost || 0));
      setSalesCommission(String(data.products?.sales_commission || 0));
      setFees(String(data.products?.payment_gateway || 0));
      setProducts(
        data.products?.products || [{ name: "", price: 0, conversion: 0 }],
      );
      setDepartments(data.selected_departments || []);
    }
  }, [data]);

  const handleClickDepartment = (id: number) => () => {
    if (departments.includes(id)) {
      setDepartments(departments.filter((department) => department !== id));
    } else {
      setDepartments([...departments, id]);
    }
  };

  const handleSave = () => {
    const nonEmptyProducts = products.filter((product) => product.name !== "");
    saveData({
      scenarioId: Number(sid),
      body: {
        current_balance: currentBalance,
        products: {
          customers: Number(customers),
          sales_commission: Number(salesCommission),
          payment_gateway: Number(fees),
          products: nonEmptyProducts,
        },
        marketing: {
          monthly_leads: Number(monthlyLeads),
          monthly_cost: Number(monthlyCost),
        },
        selected_departments: departments,
      },
    })
      .unwrap()
      .then(() => {
        saveDepartments({
          scenarioId: Number(sid),
          body: { departments: departments },
        });
      });
  };

  const handleChange =
    (index: number, field: string) => (e: string | number) => {
      const value = field !== "name" ? Number(e) : e;

      let newProducts = products.map((product, i) => {
        if (i === index) {
          return { ...product, [field]: value };
        }
        return product;
      });
      setProducts(newProducts);
    };

  const handleProductAdd = () => {
    setProducts([...products, { name: "", price: 0, conversion: 0 }]);
  };

  const handleProductDel = (index: number) => () => {
    setProducts(products.filter((_, i) => i !== index));
  };

  return (
    <div className="flex min-h-screen flex-col gap-5 pb-10 pl-0">
      <Caption>General info</Caption>

      <Tip caption="Task №2">
        <p>
          Set the conditions for this particular scenario. The information on
          this page sets the initial assumptions about your business. Each
          scenario you create can have its own assumptions.
        </p>
        <ul className="text-sm font-normal list-disc list-inside mt-2">
          <li>Money you have set aside for this business</li>
          <li>Existing customers, if any</li>
          <li>Marketing expectations</li>
          <li>Product pricing & conversion</li>
          <li>Areas of expenses (departments)</li>
        </ul>
      </Tip>
      <Reminder />

      <Section
        title="Current balance*"
        description="How much cash or credit do you already have today to cover your expenses?"
        className="max-w-md"
      >
        <div className="mt-4 w-64">
          <Input
            id="current_balance"
            label="Amount"
            type="text"
            postfix="$"
            value={String(currentBalance)}
            setValue={(value) =>
              isNaN(Number(value))
                ? setCurrentBalance(0)
                : setCurrentBalance(Number(value))
            }
          />
        </div>
      </Section>

      <Section
        title="Users that are already paying us*"
        description="How many users are already paying you on a recurring basis for your product or service?"
        className="max-w-md"
      >
        <div className="mt-4 w-64">
          <Input
            id="users_paying"
            label="Users"
            type="text"
            value={customers}
            setValue={setCustomers}
          />
        </div>
      </Section>

      <Section
        title="Marketing"
        description="On average, how much money do you intend to spend each month on marketing and how many potential customers (leads) do you think that will attract each month?"
        className="max-w-md"
      >
        <Alert text="If you are not sure, you can enter the information later." />
        <div className="mt-4 flex gap-5">
          <div className="w-40">
            <div className="mb-4 flex items-baseline gap-2 text-sm font-semibold">
              Monthly leads
            </div>
            <Input
              id="monthlyLeads"
              label="Monthly leads"
              type="text"
              value={monthlyLeads}
              setValue={setMonthlyLeads}
              fullWidth
            />
          </div>
          <div className="w-64">
            <div className="mb-4 flex items-baseline gap-2 text-sm font-semibold">
              Monthly costs
            </div>
            <Input
              id="monthlyCosts"
              label="Monthly costs"
              type="text"
              postfix="$"
              value={monthlyCost}
              setValue={setMonthlyCost}
              fullWidth
            />
          </div>
        </div>
      </Section>

      <Section
        title="Products and prices"
        description="Describe the products or services that people will be paying for"
        className="max-w-5xl"
      >
        <Alert text="If you are not sure, you can enter the product information later." />
        <div className="mt-4 flex gap-5">
          <div>
            <div className="mb-4 flex items-baseline gap-2 text-sm font-semibold">
              <div>Sales comission</div>
              <Description>
                If someone else is doing your selling for you, what percentage
                of the price are they keeping for themselves? You can set this
                to zero if the resellers are simply adding their fee on top of
                your price.
              </Description>
            </div>
            <div className="w-72">
              <Input
                id="salesComission"
                type="text"
                postfix="%"
                value={salesCommission}
                setValue={setSalesCommission}
                fullWidth
              />
            </div>
          </div>
          <div>
            <div className="mb-4 flex items-baseline gap-2 text-sm font-semibold">
              <div>Fees for payment systems (PayPal, etc.)</div>
              <Description>
                The system that you use to process credit cards or receive
                payments will often charge a small fee in the form of a
                percentage that they take from your revenue.{" "}
                <p className="font-semibold">A typical amount is 3%</p>
              </Description>
            </div>
            <div className="w-72">
              <Input
                id="fees"
                type="text"
                postfix="%"
                value={fees}
                setValue={setFees}
                fullWidth
              />
            </div>
          </div>
        </div>
        <Helper header="Name, Price and Conversion ">
          <p className="mt-2 text-sm">
            <span className="font-semibold">Name</span> - Put the name of your
            product or service
          </p>
          <p className="mt-2 text-sm">
            <span className="font-semibold">Price</span> - The amount you are
            charging for your product or service.
          </p>
          <p className="ml-10 mt-2 border-l border-black pl-2 text-sm">
            Products should be per item.
            <br />
            Services should be per month.
          </p>
          <p className="mt-2 text-sm">
            <span className="font-semibold">Conversion</span> - this describes
            how attractive your product or service will be to your customers.
            <br />
            It also describes how good your marketing is at attracting the right
            customer.
            <br />
            This is the percentage of people who will choose to pay for your
            product or service vs. the total number who came to see it.
            <br />
            <em className="font-semibold text-successHover">
              For example, if 100 people came to your store and only 21 people
              bought your product or service, then your conversion rate is 21%.
            </em>
            <br />
            rate = [people who bought your product or service] / [people who
            come to your store]
          </p>
        </Helper>
        <div className="mt-10">
          {products.map((product, index) => (
            <div key={index} className="mb-4 flex max-w-5xl">
              <div className="h-24 shrink grow rounded-s bg-outlines px-4 py-5">
                <Input
                  id={`name-${index}`}
                  label="Name"
                  type="text"
                  value={product.name}
                  setValue={handleChange(index, "name")}
                  showLabel
                  fullWidth
                />
              </div>
              <div className="h-24 bg-outlines px-4 py-5">
                <Input
                  id={`price-${index}`}
                  label="Price"
                  type="text"
                  postfix="$"
                  value={String(product.price || "")}
                  setValue={handleChange(index, "price")}
                  showLabel
                  fullWidth
                />
              </div>
              <div className="h-24 rounded-e bg-outlines px-4 py-5">
                <Input
                  id={`conversion-${index}`}
                  label="Conversion"
                  type="text"
                  postfix="%"
                  value={String(product.conversion || "")}
                  setValue={handleChange(index, "conversion")}
                  showLabel
                  fullWidth
                />
              </div>
              <div className="flex w-24 grow-0 items-center justify-center gap-2">
                {products.length > 1 && (
                  <button onClick={handleProductDel(index)}>
                    <TrashIcon />
                  </button>
                )}
                {products.length === index + 1 && (
                  <button onClick={handleProductAdd} className="text-text2">
                    <PlusCircleFilledIcon />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </Section>

      <Section title="Departments*" description="Select departaments">
        <div className="mt-4 flex max-w-6xl flex-wrap gap-5">
          {departmentsData?.map((department) => (
            <DepartmentCard
              key={department.id}
              {...department}
              description={department.desc}
              active={departments.includes(department.id)}
              onClick={handleClickDepartment(department.id)}
            />
          ))}
        </div>
      </Section>
      {errorText && <Alert text={errorText} type="error" />}
      <div className="my-16 flex justify-between gap-5">
        <Button onClick={handleSave}>Save</Button>
        <Button style="outline" isDisabled>
          Save and exit
        </Button>
      </div>
    </div>
  );
};
