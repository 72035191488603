import {
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
  TableLineSimple,
} from "../../components/Table";
import { ICashFlow } from "../../models/backend";

export const ProfitLostTable = ({
  periods,
  data,
}: {
  periods: Periods;
  data: ICashFlow;
}) => {
  const profitAndLosses: Line = {
    name: "Profit and Losses",
    values: data.profit_and_losses,
  };
  const cashFlow: Line = {
    name: "Cash Flow",
    values: data.cash_flows,
  };

  return (
    <>
      <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
        <thead className="bg-gray-200">
          <tr>
            <HeaderCaption caption="" />
            {periods.map((month) => (
              <HeaderPeriodSimple
                key={`${month.period}-${month.year}`}
                period={month.period}
                year={month.year}
                width="w-28"
                isLast={month.isLast}
                colorIndex={month.colorIndex}
              />
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          <TableLineSimple line={profitAndLosses} periods={periods} />
          <TableLineSimple line={cashFlow} periods={periods} />
        </tbody>
      </table>
    </>
  );
};
