type SubCaptionProps = {
  children: React.ReactNode;
};

export const SubCaption = ({ children }: SubCaptionProps) => {
  return (
    <h2 className="text-base font-semibold leading-loose text-gray-800">
      {children}
    </h2>
  );
};
