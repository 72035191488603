import { SVGProps } from "react";
export const ZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#CFD2DB"
      d="M25.831 23.334h-1.316l-.467-.45a10.835 10.835 0 0 0 2.467-8.9c-.784-4.633-4.65-8.333-9.317-8.9-7.05-.866-12.966 5.067-12.116 12.117.566 4.667 4.266 8.533 8.9 9.317a10.834 10.834 0 0 0 8.9-2.467l.45.467v1.316l7.1 7.084a1.74 1.74 0 0 0 2.466 0l.017-.017a1.74 1.74 0 0 0 0-2.467l-7.084-7.1Zm-10 0a7.49 7.49 0 0 1-7.5-7.5c0-4.15 3.35-7.5 7.5-7.5s7.5 3.35 7.5 7.5-3.35 7.5-7.5 7.5Zm0-11.666a.825.825 0 0 0-.833.833v2.5h-2.5a.825.825 0 0 0-.833.833c0 .467.367.834.833.834h2.5v2.5c0 .466.367.833.834.833a.825.825 0 0 0 .833-.833v-2.5h2.5a.825.825 0 0 0 .833-.834.825.825 0 0 0-.833-.833h-2.5v-2.5a.825.825 0 0 0-.833-.833Z"
    />
  </svg>
);
