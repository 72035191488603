import { PlusCircleFilledIcon, TrashIcon } from "../../assets";
import {
  IHpPosition,
  IHpPositionEmployee,
  TypesOfEmployment,
} from "../../models/department";
import { employmentTypes } from "../../models/empolymentTypes";
import { formatSalary } from "../../utils/format";
import { CustomListbox, ListboxItem } from "../CustomListbox";

const PositionRow = ({
  onHpPositionChange,
  onHpPositionAdd,
  onHpPositionRemove,
  availableTypes,
  hpEmployees,
}: {
  onHpPositionChange: (hpPosition: IHpPositionEmployee) => void;
  onHpPositionAdd: () => void;
  onHpPositionRemove: (hpPosition: IHpPositionEmployee) => void;
  availableTypes: ListboxItem[];
  hpEmployees: IHpPositionEmployee[];
}) => {
  return (
    <>
      <td>
        {hpEmployees.map((employee) => (
          <div className="my-1" key={employee.id}>
            <CustomListbox
              selected={
                employmentTypes.find(
                  (type) => type.item === employee.typeOfEmployment,
                ) ?? employmentTypes[0]
              }
              data={availableTypes}
              setSelected={(item: ListboxItem) => {
                onHpPositionChange({
                  ...employee,
                  typeOfEmployment: item.item as TypesOfEmployment,
                });
              }}
            />
          </div>
        ))}
      </td>
      {/* <td>
        {hpEmployees.map((employee) => (
          <div className="my-1" key={employee.id}>
            <input
              placeholder="Enter number"
              className="w-20 rounded-md border border-outlines py-2 pl-3 pr-10"
              value={employee.numberOfEmployees}
              onChange={(e) => {
                onHpPositionChange({
                  ...employee,
                  numberOfEmployees: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
                  count: Array.from({ length: MONTH_COUNT }, () => isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)),
                });
              }}
            />
          </div>
        ))}
      </td> */}
      <td className="flex w-20 flex-row items-center">
        <div className="my-1">
          {hpEmployees.map((employee) => (
            <div key={employee.id}>
              {hpEmployees.length > 0 && (
                <button
                  className="py-2 pl-3"
                  onClick={() => {
                    onHpPositionRemove(employee);
                  }}
                >
                  <TrashIcon />
                </button>
              )}
            </div>
          ))}
        </div>
        <div>
          <button
            className="py-2 pl-3 text-text2"
            onClick={() => {
              onHpPositionAdd();
            }}
            title="Add new for this role"
          >
            <PlusCircleFilledIcon />
          </button>
        </div>
      </td>
    </>
  );
};

export const DepartmentPositions = ({
  isOpen,
  onPositionChange,
  onPositionAdd,
  onPositionRemove,
  hpPosition,
  hpEmployees,
}: {
  isOpen: boolean;
  onPositionChange: (position: IHpPositionEmployee) => void;
  onPositionAdd: () => void;
  onPositionRemove: (position: IHpPositionEmployee) => void;
  hpPosition: IHpPosition | undefined;
  hpEmployees: IHpPositionEmployee[];
}) => {
  if (!hpPosition) return null;

  const className = `bg-gray-50 text-gray-800 text-[14px] font-normal leading-normal border-y ${
    isOpen ? "" : "hidden"
  }`;
  const availableTypes: ListboxItem[] = [];
  if (hpPosition.fulltime.annual) availableTypes.push(employmentTypes[0]);
  if (hpPosition.freelance.annual) availableTypes.push(employmentTypes[1]);
  if (hpPosition.contractor.annual) availableTypes.push(employmentTypes[2]);

  return (
    <tr className={className}>
      <th className="py-3 pl-5 pr-4 text-left text-[14px] font-semibold leading-normal text-gray-800">
        {hpPosition.role_name}
      </th>
      <td className="border px-4 text-right text-[14px] font-semibold leading-normal text-gray-800">
        {formatSalary(hpPosition.annual)}
      </td>
      <td className="px-1 text-center">
        {formatSalary(hpPosition.fulltime.monthly)} /{" "}
        {formatSalary(hpPosition.fulltime.annual)}
      </td>
      <td className="text-center">
        {formatSalary(hpPosition.freelance.monthly)} /{" "}
        {formatSalary(hpPosition.freelance.annual)}
      </td>
      <td className="text-center">
        {formatSalary(hpPosition.contractor.monthly)} /{" "}
        {formatSalary(hpPosition.contractor.annual)}
      </td>
      <PositionRow
        hpEmployees={hpEmployees}
        onHpPositionChange={onPositionChange}
        onHpPositionAdd={onPositionAdd}
        onHpPositionRemove={onPositionRemove}
        availableTypes={availableTypes}
      />
    </tr>
  );
};
