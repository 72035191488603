import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateScenarioMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useGetScenariosQuery,
  useSaveProjectMutation,
} from "../../services/patric";
import { PlusCircleIcon } from "../../assets/icons/PlusCircleIcon";
import { PencilIcon, TrashIcon } from "../../assets";
import { IProject } from "../../models/backend";
import { useError } from "../../hooks/useError";
import { isBackendError } from "../../models/utils";
import { DeleteProjectDialog } from "./DeleteProjectDialog";
import { RenameProjectDialog } from "./RenameProjectDialog";

export const Home = () => {
  const { pid } = useParams();
  const { data } = useGetScenariosQuery(
    { projectID: Number(pid) },
    { skip: isNaN(Number(pid)) },
  );
  const [createScenario] = useCreateScenarioMutation();
  const [deleteProject, deleteProjectResult] = useDeleteProjectMutation();
  const [saveProject, saveProjectResult] = useSaveProjectMutation();
  const [projectToDelete, setProjectToDelete] = useState<IProject | undefined>();
  const [projectToRename, setProjectToRename] = useState<IProject | undefined>();
  const navigate = useNavigate();
  const { handleError } = useError({ "overall": "" })

  const { data: projectsResult, isSuccess: isProjectsSuccess } =
    useGetProjectsQuery();

  useEffect(() => {
    if (pid && data) {
      if (data.length > 0) {
        navigate(`/${pid}/${data[0].scenario_id}`);
      } else {
        createScenario({
          scenario_name: "Scenario #1",
          project_id: Number(pid),
        });
      }
    }
  }, [pid, data, createScenario, navigate]);

  useEffect(function checkDeletionResults() {
    if (deleteProjectResult.isError) {
      isBackendError(deleteProjectResult.error)
        ? handleError("overall", deleteProjectResult.error.data.detail)
        : handleError("overall", "Something went wrong");
    }
  }, [deleteProjectResult.isError, deleteProjectResult.error, handleError])

  useEffect(function checkSaveResults() {
    if (saveProjectResult.isError) {
      isBackendError(saveProjectResult.error)
        ? handleError("overall", saveProjectResult.error.data.detail)
        : handleError("overall", "Something went wrong");
    }
  }, [saveProjectResult.isError, saveProjectResult.error, handleError])

  const handleDelete = (project: IProject) => {
    setProjectToDelete(project);
  }

  const handleDeleteProject = () => {
    if (projectToDelete) {
      deleteProject(projectToDelete.project_id)
    }
    setProjectToDelete(undefined);
  }

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, project: IProject) => {
    e.stopPropagation();
    setProjectToRename(project)
  }

  const handleEditTitle = (newTitle: string) => {
    if (projectToRename) {
      saveProject({ ...projectToRename, project_name: newTitle })
    }
    setProjectToRename(undefined)
  }

  if (!isProjectsSuccess) {
    return null;
  }

  return (
    <div>
      <ul className="m-6 flex flex-row flex-wrap gap-6">
        {projectsResult?.map((project) => (
          <li key={project.project_id} className="relative">
            <button
              className="flex gap-1 h-40 w-64 items-center justify-center rounded-lg border shadow-md"
              onClick={() => navigate(`/${project.project_id}`)}
            >
              <div className="font-bold">{project.project_name}</div>
              <button className="text-normal" onClick={(e) => handleEdit(e, project)}><PencilIcon /></button>
            </button>
            <button className="absolute right-2 top-2" onClick={() => handleDelete(project)}>
              <TrashIcon />
            </button>
          </li>
        ))}
        <li key={"new"}>
          <button
            className="flex h-40 w-64 flex-col items-center justify-center rounded-lg border-dashed border-2 border-normal text-normal"
            onClick={() => navigate(`/new`)}
          >
            <PlusCircleIcon width={64} height={64} strokeWidth={1} />
            <span className="font-semibold text-text2 text-sm">Add new project</span>
          </button>
        </li>
      </ul>
      <DeleteProjectDialog onClose={() => setProjectToDelete(undefined)} onSubmit={handleDeleteProject} show={!!projectToDelete} />
      <RenameProjectDialog onClose={() => setProjectToRename(undefined)} onSubmit={handleEditTitle} show={!!projectToRename} title={projectToRename?.project_name ?? ""} key={projectToRename?.project_name || "newTitle"} />
    </div>
  );
};
