import { SVGProps } from "react";
export const EngineeringDpt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1644 16.011C20.1644 14.9059 19.7254 13.8461 18.944 13.0647C18.1626 12.2833 17.1028 11.8443 15.9977 11.8443C15.4612 11.8599 14.927 11.7684 14.4262 11.5752C13.9255 11.3821 13.4682 11.0911 13.0811 10.7193L10.4561 8.09434C9.73692 7.37637 9.33227 6.40221 9.33108 5.38601C9.35407 4.47898 9.09012 3.58785 8.57686 2.83966C8.0636 2.09147 7.32724 1.52441 6.47275 1.21934C5.94005 1.03589 5.37478 0.966325 4.8135 1.01513C4.25223 1.06394 3.70745 1.23003 3.21441 1.50267L5.00608 2.71101C5.18302 2.829 5.32808 2.98887 5.42838 3.1764C5.52869 3.36393 5.58114 3.57333 5.58108 3.78601V3.99434C5.58136 4.46063 5.44388 4.91662 5.18591 5.30505C4.92794 5.69349 4.56098 5.99707 4.13108 6.17767C3.93788 6.25754 3.7286 6.29086 3.52015 6.27493C3.3117 6.25901 3.10991 6.19429 2.93108 6.08601L1.06441 4.96934H1.00608C0.975481 5.53515 1.06061 6.10125 1.25626 6.63303C1.45191 7.16482 1.75397 7.65111 2.14397 8.06217C2.53398 8.47323 3.00372 8.80043 3.52449 9.02375C4.04526 9.24708 4.60611 9.36183 5.17275 9.36101C5.7386 9.35523 6.30003 9.4612 6.82484 9.67284C7.34966 9.88449 7.82754 10.1976 8.23108 10.5943L10.7311 13.0943C11.1029 13.4814 11.3938 13.9387 11.587 14.4395C11.7801 14.9403 11.8716 15.4745 11.8561 16.011C11.8561 17.1161 12.2951 18.1759 13.0765 18.9573C13.8579 19.7387 14.9177 20.1777 16.0227 20.1777C17.1278 20.1777 18.1876 19.7387 18.969 18.9573C19.7504 18.1759 20.1894 17.1161 20.1894 16.011H20.1644ZM17.2477 18.0943H14.7477L13.4977 16.011L14.7477 13.9277H17.2477L18.4977 16.011L17.2477 18.0943Z"
      stroke="#343B58"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
