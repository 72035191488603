import { Listbox } from "@headlessui/react";
import { useGetLocationsQuery } from "../../services/patric";
import { ILocation } from "../../models/backend";
import { Fragment, useEffect, useState } from "react";
import { ChevronDownIcon } from "../../assets";
import { Input } from "../Input";

const Locations = ({
  data,
  groupName,
}: {
  data: ILocation[];
  groupName: string;
}) => {
  return data.map((location) => (
    <Listbox.Option
      key={location.location_id}
      value={{
        ...location,
        location_name: `${location.location_name}${groupName ? ", " + groupName : ""}`,
      }}
    >
      {location.location_name}
    </Listbox.Option>
  ));
};

const LoacationGroup = ({
  data,
  groupName,
}: {
  data: ILocation[];
  groupName: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <p
        className="flex font-semibold"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {isOpen ? (
          <ChevronDownIcon className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 -rotate-90 text-gray-400" />
        )}
        {groupName}
      </p>
      {isOpen && <Locations data={data} groupName={groupName} />}
    </Fragment>
  );
};

type Params = {
  value?: string;
  onChange: (value: string, taxRate?: number) => void;
  error: boolean;
};

export const LocationSelector = ({ value, onChange, error }: Params) => {
  const { data, isLoading } = useGetLocationsQuery();
  const [selectedValue, setSelectedValue] = useState<ILocation | null>(null);

  useEffect(() => {
    if (value) {
      setSelectedValue({ location_name: value, tax_rate: 0, location_id: 0 });
    } else {
      setSelectedValue(null);
    }
  }, [value, data]);

  const handleOnChange = (value: ILocation) => {
    setSelectedValue(value);
    const taxRate = value.tax_rate || undefined;
    onChange(value.location_name, taxRate);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Listbox
      as="div"
      className="relative inline-block w-full text-left"
      value={selectedValue}
      onChange={handleOnChange}
    >
      <Listbox.Button
        className={`flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-3 shadow-sm ring-1 ring-inset hover:bg-gray-50 ${error ? "ring-red-500" : "ring-gray-300"
          }`}
      >
        {selectedValue?.location_name ?? (
          <span className="text-placeholderText text-opacity-70">
            Your location
          </span>
        )}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Listbox.Button>

      <Listbox.Options className="absolute right-0 z-20 mt-2 max-h-80 w-[576px] origin-top-right cursor-pointer overflow-scroll rounded-md bg-white py-4 pl-3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {data?.map((locationGroup) => (
          <LoacationGroup
            key={locationGroup.group_name}
            data={locationGroup.locations}
            groupName={locationGroup.group_name}
          />
        ))}
        <div className="w-full py-4 pl-0 pr-4">
          <Input
            value={value}
            id="Yourlocation"
            type="text"
            fullWidth
            label="Enter your location (if it is not Canada or USA)"
            onChange={(value) =>
              handleOnChange({
                location_name: value,
                tax_rate: 0,
                location_id: 0,
              })
            }
          />
        </div>
      </Listbox.Options>
    </Listbox>
  );
};
