import { useState } from "react";
import { CollapseIcon } from "../../assets";
import { MONTH_COUNT } from "../../const";
import { Line, TableCell, TableLine, getMonths, getPeriods } from "../Table";

type RoleSectionProps = {
  section: number[];
  sums: RoleCell[];
  isOpen: boolean;
  showMonths: boolean;
  name: string;
  startDate: Date;
};

export type RoleCell = {
  roleID: number;
  roleName?: string;
  month: number;
  amount: string;
  count: number;
};

export const PayrollRoleSection = ({
  isOpen,
  section,
  showMonths,
  sums,
  name,
  startDate,
}: RoleSectionProps) => {
  const [isOpenSection, setIsOpenSection] = useState(false);

  const handleOpenSection = () => {
    setIsOpenSection(!isOpenSection);
  };

  const lines: Line[] = [];
  for (const role of section) {
    let roleName = sums.find((s) => s.roleID === role)?.roleName;
    const data = sums
      .filter((s) => s.roleID === role)
      .sort((a, b) => b.month - a.month)
      .reduce((acc, s) => {
        acc[s.month - 1] = s;
        return acc;
      }, [] as RoleCell[]);

    if (!data[0]) data[0] = { roleID: role, month: 1, amount: "0", count: 0 };
    for (let i = 1; i < MONTH_COUNT; i++)
      if (!data[i]) data[i] = { ...data[i - 1], month: i };

    const line: Line = {
      name: roleName ?? "",
      values: data.map((d) => Number(d.amount)),
    };
    lines.push(line);
  }

  const total = lines.reduce((acc, line) => {
    line.values.forEach((value, index) => {
      acc[index] = (acc[index] || 0) + value;
    });
    return acc;
  }, [] as number[]);

  const months = getMonths(startDate, showMonths, MONTH_COUNT);
  const periods = getPeriods(months, showMonths);
  return (
    <>
      <tr className={`bg-gray-100 ${isOpen ? "" : "hidden"}`}>
        <td className="sticky left-0 bg-gray-100 py-2 pl-8 text-sm font-normal leading-normal text-gray-800">
          <button onClick={handleOpenSection} className="flex">
            <CollapseIcon isOpen={isOpenSection} />
            {name}
          </button>
        </td>
        {total.map((value, index) => (
          <TableCell
            key={index}
            className="py-2 pr-2 text-right text-sm leading-normal text-gray-800"
            value={value}
            months={periods}
            index={index}
            showMonths={showMonths}
          />
        ))}
      </tr>
      {lines.map((line) => (
        <TableLine
          line={line}
          months={months}
          showMonths={showMonths}
          isOpen={isOpenSection}
          variant="regular"
        />
      ))}
    </>
  );
};
