import { InfoIcon } from "../../assets";

type TipProps = {
  text: string;
  type?: "info" | "error";
};

export const Alert = ({ text, type = "info" }: TipProps) => {
  return (
    <div
      className={`my-4 w-fit items-center justify-start gap-2.5 rounded-lg  ${type === "info" ? "bg-[#ECEFFB]" : "bg-errorBackground"} py-4 pr-4`}
    >
      <div
        className={`flex items-start justify-start gap-2 px-4 ${type === "info" ? "text-[#354A97]" : "text-errorNormal"}`}
      >
        <InfoIcon />
        <div className="text-sm font-semibold leading-normal text-[#1F2436]">
          {text}
        </div>
      </div>
    </div>
  );
};
