import { SVGProps } from "react";

export const OperationDpt = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <g>
        <path
          fill="#1F2436"
          d="M8.167 6.833h4.666a.578.578 0 00.584-.583V1.583A.578.578 0 0012.833 1H8.167a.578.578 0 00-.584.583V2.75h-3.5c-.956 0-1.75.793-1.75 1.75v1.517l-.746-.747c-.234-.233-.607-.233-.817 0-.233.233-.233.607 0 .817l1.75 1.75c.233.233.607.233.817 0l1.75-1.75c.233-.234.233-.607 0-.817-.234-.233-.607-.233-.817 0l-.77.747V4.5c0-.327.257-.583.583-.583h3.5V6.25c0 .327.257.583.584.583zm.583-4.666h3.5v3.5h-3.5v-3.5zm11.667 6.416H19.25v-3.5c0-.956-.793-1.75-1.75-1.75h-1.517l.747-.746c.233-.234.233-.607 0-.817-.233-.233-.607-.233-.817 0l-1.75 1.75c-.233.233-.233.607 0 .817l1.75 1.75c.234.233.607.233.817 0 .233-.234.233-.607 0-.817l-.747-.77H17.5c.327 0 .583.257.583.583v3.5H15.75a.578.578 0 00-.583.584v4.666c0 .327.256.584.583.584h4.667a.578.578 0 00.583-.584V9.167a.578.578 0 00-.583-.584zm-.584 4.667h-3.5v-3.5h3.5v3.5zM5.087 16.913c-.234-.233-.607-.233-.817 0-.233.234-.233.607 0 .817l.747.747H3.5a.578.578 0 01-.583-.584v-3.5H5.25a.578.578 0 00.583-.583V9.167a.578.578 0 00-.583-.584H.583A.578.578 0 000 9.167v4.666c0 .327.257.584.583.584H1.75v3.5c0 .956.793 1.75 1.75 1.75h1.517l-.747.746c-.233.234-.233.607 0 .817.233.233.607.233.817 0l1.75-1.75c.233-.233.233-.607 0-.817l-1.75-1.75zM1.167 9.75h3.5v3.5h-3.5v-3.5zm17.336 5.413c-.233-.233-.606-.233-.816 0l-1.75 1.75c-.234.234-.234.607 0 .817.233.233.606.233.816 0l.747-.747V18.5a.578.578 0 01-.583.583h-3.5V16.75a.578.578 0 00-.584-.583H8.167a.578.578 0 00-.584.583v4.667c0 .326.257.583.584.583h4.666a.578.578 0 00.584-.583V20.25h3.5c.956 0 1.75-.793 1.75-1.75v-1.517l.746.747c.234.233.607.233.817 0 .233-.233.233-.607 0-.817l-1.727-1.75zm-6.253 5.67h-3.5v-3.5h3.5v3.5z"
        ></path>
      </g>
    </svg>
  );
};
