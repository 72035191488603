import {
  HeaderCaption,
  HeaderWidePeriod,
  TableLine,
  getMonths,
} from "../../components/Table";
import { startOfQuarter, compareAsc } from "date-fns";
import { MONTH_COUNT } from "../../const";

import { convert } from "./mock";
import { useGetDebtSummaryQuery } from "../../services/patric";

type RepaymentScheduleProps = {
  scenarioID: number;
  showMonths: boolean;
  startDate: Date;
};

export const RepaymentSchedule = ({
  scenarioID,
  showMonths,
  startDate,
}: RepaymentScheduleProps) => {
  const { isLoading, data: debtSummary } = useGetDebtSummaryQuery({
    scenarioID,
  });

  if (isLoading || !debtSummary) {
    return <div>Loading...</div>;
  }

  const { data, total } = convert(debtSummary);

  const months = getMonths(startDate, showMonths, MONTH_COUNT);

  const periods = showMonths
    ? months
    : months
        .map(({ index, date }) => ({ index, date: startOfQuarter(date) }))
        .reduce((acc, { index, date }) => {
          if (acc.find((period) => compareAsc(period.date, date) === 0)) {
            return acc;
          }
          return [...acc, { index, date }];
        }, [] as Array<{ index: number; date: Date }>);

  return (
    <div className="pb-4">
      <table className="table-fixed w-full border-separate border-spacing-0">
        <thead className="bg-gray-200">
          <tr>
            <HeaderCaption caption="Expense" />
            {periods.map((month) => (
              <HeaderWidePeriod month={month.date} showMonths={showMonths} />
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((line) => (
            <TableLine line={line} showMonths={showMonths} months={months} />
          ))}
          <TableLine
            line={total}
            showMonths={showMonths}
            months={months}
            variant="total"
          />
        </tbody>
      </table>
    </div>
  );
};
