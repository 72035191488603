export const departmentProposals = [
  {
    id: 1,
    name: "General",
    fixed: [
      "Rental of physical space",
      "Registration fees for your business",
      "Legal fees for incorporation",
      "Furniture or equipment",
      "Office supplies",
      "Heating fees",
      "Power fees",
      "Water fees",
      "Sewage / Garbage fees",
      "Maintenance fees",
      "Renovation",
      "Vehicles",
    ],
    perEmployee: [
      "Email account",
      "Subscription for software tools"
    ],
  },
  {
    id: 2,
    name: "Product",
    fixed: ["Fees for research & surveys",
      "Subscription for design tools",
      "Subscription for planning tools"],
    perEmployee: ["Subscription for software tools"],
  },
  {
    id: 3,
    name: "Finance",
    fixed: ["Audit", "ADP (Payroll)", "Vertex (taxes)"],
    perEmployee: ["Xero", "Expensify", "Harvest (time tracking)"],
  },
  {
    id: 4,
    name: "Operations",
    fixed: ["Fees for registering domains",
      "Fees for hosting web sites",
      "Fees for hosting applications",
      "Fees for monitoring web sites and applications Delivery vehicles",
      "Regular supplies",
      "Fees for cleaning",
      "Fees for inspections"],
    perEmployee: [
      "Subscriptions for software",
      "Personal equipment",
    ],
  },
  {
    id: 5,
    name: "Engineering / R\u0026D",
    fixed: [
      "Subscription for development tools",
      "Subscription for testing tools",
      "Subscription for hosting environments",
      "Fees for prototyping",
    ],
    perEmployee: ["Subscription for software tools",
      "Physical equipment"],
  },
  {
    id: 6,
    name: "Marketing",
    fixed: ["Subscription to market research results",
      "Fees for design",
      "Fees for copywriting",
      "Fees for social media management"],
    perEmployee: ["Subscription for software tools"],
  },
  {
    id: 7, name: "Sales (owned by CEO)", fixed: [
      "Subscription to customer management tool",
      "Subscription to document management tool",
      "Subscription to sales management tool"
    ], perEmployee: ["Subscription to software tools"]
  },
  { id: 8, name: "Support (owned by COO)", fixed: ["Subscription to problem-tracking tool "], perEmployee: ["Subscription to software tools"] },
];
