import { SVGProps } from "react";

export const FinanceDpt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="lucide:wallet">
      <g id="Group">
        <path
          id="Vector"
          d="M18.375 10.5V6.125H4.375C3.91087 6.125 3.46575 5.94063 3.13756 5.61244C2.80937 5.28425 2.625 4.83913 2.625 4.375C2.625 3.91087 2.80937 3.46575 3.13756 3.13756C3.46575 2.80937 3.91087 2.625 4.375 2.625H16.625V6.125"
          stroke="#343B58"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.625 4.375V16.625C2.625 17.0891 2.80937 17.5342 3.13756 17.8624C3.46575 18.1906 3.91087 18.375 4.375 18.375H18.375V14"
          stroke="#343B58"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M15.75 10.5C15.2859 10.5 14.8408 10.6844 14.5126 11.0126C14.1844 11.3408 14 11.7859 14 12.25C14 12.7141 14.1844 13.1592 14.5126 13.4874C14.8408 13.8156 15.2859 14 15.75 14H19.25V10.5H15.75Z"
          stroke="#343B58"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
