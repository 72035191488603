export const MoneyFinance = () => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.2854 31.0714C47.5816 31.0714 55.9283 27.3618 55.9283 22.7857C55.9283 18.2096 47.5816 14.5 37.2854 14.5C26.9893 14.5 18.6426 18.2096 18.6426 22.7857C18.6426 27.3618 26.9893 31.0714 37.2854 31.0714Z"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6426 22.7852V47.6423C18.6426 52.1994 26.9283 55.928 37.2854 55.928C47.6426 55.928 55.9283 52.1994 55.9283 47.6423V22.7852"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.9284 35.2144C55.9284 39.7715 47.6427 43.5001 37.2856 43.5001C26.9284 43.5001 18.6427 39.7715 18.6427 35.2144M36.8713 6.21437C32.0303 3.22686 26.3954 1.782 20.7141 2.07152C10.3984 2.07152 2.07129 5.80009 2.07129 10.3572C2.07129 12.8015 4.47415 14.9972 8.28557 16.5715"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.28558 41.4289C4.47415 39.8546 2.07129 37.6589 2.07129 35.2146V10.3574"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.28558 28.9994C4.47415 27.4252 2.07129 25.2294 2.07129 22.7852"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
