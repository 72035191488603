import { FC } from "react";

type ButtonVariant = "primary" | "secondary" | "tertiary" | "alert";
type ButtonStyle = "solid" | "outline" | "ghost";

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariant;
  style?: ButtonStyle;
  isDisabled?: boolean;
  width?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const variantClasses = {
  primary: "font-semibold bg-blue-500 hover:bg-blue-700",
  secondary: "bg-gray-500 hover:bg-gray-700",
  tertiary: "bg-white hover:bg-gray-100",
  alert: "bg-errorNormal"
};

const styleClasses = {
  solid: "bg-normal hover:bg-hover active:bg-active text-white",
  outline:
    "bg-transparent rounded-md border border-sky-700 text-gray-800 hover:bg-sky-100 active:bg-sky-900 active:text-white",
  ghost: "bg-white hover:bg-gray-100 text-gray-800",
};

export const Button: FC<ButtonProps> = ({
  type = "button",
  variant = "primary",
  style = "solid",
  isDisabled = false,
  width = "",
  children,
  onClick,
}) => {
  const handleClick = () => {
    if (isDisabled) return;
    onClick?.();
  };

  const enabled = `${variantClasses[variant]} ${styleClasses[style]}`;

  return (
    <button
      className={`py-3 px-4 rounded-md  
      ${
        isDisabled
          ? "bg-disabledButton hover:bg-disabledButton text-disabledButtonText"
          : enabled
      }
      ${width}`}
      type={type}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
