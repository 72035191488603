import { SVGProps } from "react";
export const ProductDpt = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <g>
        <path
          fill="#343B58"
          d="M10.313 1.29l8.937 4.468V16.93l-8.938 4.458-8.937-4.458V5.758l8.938-4.469zm6.713 4.897l-6.713-3.351-2.59 1.289 6.672 3.373 2.631-1.31zM10.313 9.54l2.556-1.268-6.681-3.373-2.59 1.29 6.715 3.351zM2.75 7.305v8.765l6.875 3.438v-8.766L2.75 7.305zM11 19.508l6.875-3.438V7.305L11 10.742v8.766z"
        ></path>
      </g>
    </svg>
  );
};
