import { Bar } from "react-chartjs-2";

type ChartProps = {
  data: {
    id: number;
    year: string;
    userGain: number;
    userLost: number;
  }[];
};

export const TotalRevenueCostChart = ({ data }: ChartProps) => {
  const chartData = {
    labels: data.map((data) => data.year),
    datasets: [
      {
        label: "Total cost",
        data: data.map((data) => data.userLost),
        backgroundColor: ["#ff0000"],
        borderColor: "#ff0000",
        borderWidth: 2,
      },
      {
        label: "Total revenue",
        data: data.map((data) => data.userGain),
        backgroundColor: ["#21D533"],
        borderColor: "#21D533",
        borderWidth: 2,
      },
    ],
  };

  const breakEvenPoint = data.findIndex(
    (data3) => data3?.userGain >= data3?.userLost,
  );
  return (
    <div className="chart-container border border-gray-300">
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "",
              align: "end",
            },
            legend: {
              display: true,
              position: "bottom",
            },
            tooltip: {
              enabled: true,
              backgroundColor: "#fff",
              titleColor: "#000",
              bodyColor: "#000",
            },
            // @ts-ignore
            customBackground: {
              position: breakEvenPoint > 0 ? breakEvenPoint : 13,
              color: "#21D533",
            },
            customTitle: {
              text: [
                {
                  text: `${breakEvenPoint > 0 ? data[breakEvenPoint].year : "-"}: `,
                  color: "#000",
                },
                { text: "Total revenue", color: "#21D533" },
                { text: " > ", color: "#000" },
                { text: "Total cost", color: "#ff0000" },
              ],
            },
          },
          interaction: {
            mode: "index",
          },
          scales: {
            x: {
              grid: { offset: false, color: "#E8E9ED", tickColor: "#656C8A" },
            },
            y: {},
          },
        }}
      />
    </div>
  );
};
