import { SVGProps } from "react";
export const DollarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#656C8A"
      d="M9.375 6.54c-.78.212-1.25.812-1.25 1.377s.47 1.164 1.25 1.376V6.54Zm1.25 4.167v2.753c.78-.213 1.25-.812 1.25-1.377s-.47-1.164-1.25-1.376Z"
    />
    <path
      fill="#656C8A"
      fillRule="evenodd"
      d="M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 1 1 16.666 0ZM10 4.373a.625.625 0 0 1 .625.625v.265c1.358.243 2.5 1.264 2.5 2.652a.625.625 0 0 1-1.25 0c0-.565-.47-1.164-1.25-1.377V9.43c1.358.244 2.5 1.264 2.5 2.653 0 1.388-1.142 2.409-2.5 2.652V15a.625.625 0 0 1-1.25 0v-.264c-1.358-.243-2.5-1.264-2.5-2.652a.625.625 0 0 1 1.25 0c0 .565.47 1.164 1.25 1.376v-2.89c-1.358-.244-2.5-1.265-2.5-2.653 0-1.388 1.142-2.41 2.5-2.652v-.265A.625.625 0 0 1 10 4.374Z"
      clipRule="evenodd"
    />
  </svg>
);
