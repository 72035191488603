import { ReactNode } from "react";

type TipProps = {
  caption: string;
  text?: ReactNode;
  width?: string;
  children?: ReactNode;
};

export const Tip = ({
  caption,
  text,
  children,
  width = "max-w-full",
}: TipProps) => {
  return (
    <div
      className={`items-center justify-start gap-2.5 rounded-lg border-l-4 border-tipBorder bg-yellow-50 py-4 ${width}`}
    >
      <div className="flex-col items-start justify-start pl-4">
        <div className="text-base font-bold leading-loose text-primaryText bg-tipBorder w-fit rounded-full px-4">
          {caption}
        </div>
        <div className="text-base font-semibold leading-normal text-primaryText mt-4 pb-2">
          {text}
          {children}
        </div>
      </div>
    </div>
  );
};
