import {
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
  TableLineSimple,
} from "../../components/Table";
import { IRevenueSales } from "../../models/backend";

export const CustomersTable = ({
  data,
  periods,
}: {
  data: IRevenueSales[];
  periods: Periods;
}) => {
  const payingUsers: Line = {
    name: "Paying users",
    values: data.map((revenue) => revenue.paying_users),
  };

  return (
    <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="" />
          {periods.map((month) => (
            <HeaderPeriodSimple
              key={`${month.period}-${month.year}-customers`}
              period={month.period}
              year={month.year}
              colorIndex={month.colorIndex}
              isLast={month.isLast}
              width="w-28"
            />
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-100 border-t border-gray-100">
        <TableLineSimple line={payingUsers} periods={periods} />
      </tbody>
    </table>
  );
};
