import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../../components/Button";
import { useLoginMutation } from "../../services/patric";
import { Input } from "../../components/Input";

export const Login = () => {
  const { login, user, loading } = useAuth();
  const [doLogin, result] = useLoginMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (result.isSuccess) {
      login(result.data);
    } else if (
      result.isError &&
      "data" in result.error &&
      result.error.data &&
      typeof result.error.data === "object" &&
      "detail" in result.error.data &&
      typeof result.error.data.detail === "string"
    ) {
      if (result.error.status === 403) {
        setErrorMessage("Account not activated");
        setErrorCode(403);
      } else if (result.error.status === 401) {
        setErrorMessage("Wrong username or password");
        setErrorCode(401);
      } else if (result.error.status === "CUSTOM_ERROR") {
        setErrorMessage(result.error.data.detail);
        setErrorCode(0);
      } else {
        setErrorMessage(result.error.data.detail);
        setErrorCode(result.error.status);
      }
    }
  }, [result, login]);

  if (user && !loading) {
    return <Navigate to="/home" />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await doLogin({ username: email, password }).unwrap();
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="w-64">
          <Link to={"/"}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div>
          First time?{" "}
          <Link className="cursor-pointer text-blue-700" to={"/auth/signup"}>
            Registration
          </Link>
        </div>
      </div>
      <h1 className="mt-16 text-3xl">Welcome back!</h1>
      <p className="mt-4">Let's count your business</p>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex w-full flex-col gap-6">
          <Input
            type="text"
            id="username"
            label="Email"
            fullWidth
            value={email}
            onChange={setEmail}
          />

          <Input
            type="password"
            id="password"
            label="Password"
            fullWidth
            value={password}
            onChange={setPassword}
          />

          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </div>
        {errorCode === 401 && (
          <div className="mt-10 w-full">
            <Link
              className="cursor-pointer text-blue-700"
              to={"/auth/forgot-password"}
            >
              Forgot password?
            </Link>
          </div>
        )}

        {errorCode === 403 && (
          <div className="mt-10 w-full">
            <Link
              className="cursor-pointer text-blue-700"
              to={"/auth/activate"}
            >
              Activate account
            </Link>
          </div>
        )}
        <div className="mt-10 flex w-full flex-col gap-10">
          <Button type="submit" width="w-full" isDisabled={!email || !password}>
            Log in
          </Button>
          <Button
            type="button"
            style="outline"
            width="w-full"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};
