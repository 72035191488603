import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ILoginResponse, useLazyGetUserQuery } from "../services/patric";
import {
  tokenAdded,
  logout as authLogout,
  userAdded,
} from "../features/auth/authSlice";
import { RootState } from "../store";
import { STORAGE_CREDENTIALS } from "../const";

interface ProvideAuthProps {
  children: ReactNode;
}

interface IUser {
  username: string;
}

interface IAuth {
  user: IUser | undefined;
  loading: boolean;
  login: (data: ILoginResponse) => void;
  logout: () => void;
}

const authContext = createContext<IAuth>({
  user: undefined,
  loading: true,
  login: () => {},
  logout: () => {},
});

export const ProvideAuth = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [trigger] = useLazyGetUserQuery(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const credentials = localStorage.getItem(STORAGE_CREDENTIALS);
    if (credentials) {
      const { access_token } = JSON.parse(credentials);
      dispatch(tokenAdded(access_token));
      dispatch(userAdded(access_token));

      trigger();
    } else {
      dispatch(authLogout());
    }
    setLoading(false);
  }, [dispatch, trigger]);

  const login = useCallback(
    (data: ILoginResponse) => {
      setLoading(true);
      dispatch(tokenAdded(data.access_token));
      dispatch(userAdded(data.access_token));
      setLoading(false);
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(authLogout());
  }, [dispatch]);

  return {
    user,
    loading,
    login,
    logout,
  };
};
