import { SVGProps } from "react";

export const PlusSquareIcon = ({
  width = 32,
  height = 32,
  fill = "#343B58",
  color = "white",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="6" fill={fill} />
    <g clipPath="url(#clip0_268_4455)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2267 16.85L16.8497 16.85L16.8497 23.227C16.8497 23.4481 16.765 23.6446 16.5911 23.8184C16.4173 23.9923 16.2208 24.077 15.9997 24.077C15.7786 24.077 15.5821 23.9923 15.4083 23.8184C15.2344 23.6446 15.1497 23.4481 15.1497 23.227L15.1497 16.85L8.77269 16.85C8.55162 16.85 8.35511 16.7653 8.18127 16.5914C8.00742 16.4176 7.92265 16.2211 7.92265 16C7.92265 15.7789 8.00742 15.5824 8.18127 15.4086C8.35511 15.2347 8.55162 15.15 8.77269 15.15L15.1497 15.15L15.1497 8.77299C15.1497 8.55192 15.2344 8.35541 15.4083 8.18157C15.5821 8.00772 15.7786 7.92295 15.9997 7.92295C16.2208 7.92295 16.4173 8.00772 16.5911 8.18157C16.765 8.35541 16.8497 8.55192 16.8497 8.77299L16.8497 15.15L23.2267 15.15C23.4478 15.15 23.6443 15.2347 23.8181 15.4086C23.992 15.5824 24.0767 15.7789 24.0767 16C24.0767 16.2211 23.992 16.4176 23.8181 16.5914C23.6443 16.7653 23.4478 16.85 23.2267 16.85Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_268_4455">
        <rect width="16" height="16" fill={color} transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
