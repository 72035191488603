import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateStepMutation } from "../../services/patric";
import { ICost } from "../../models/backend";
import { SubCaption } from "../../components/Caption";
import { Button } from "../../components/Button";
import { SummaryTable } from "./SummaryTable";
import { PeriodSelector } from "../../components/PeriodSelector";
import { departmentProposals } from "./departmentProposals";
import { DEBT_DETAILS_VIEW } from "../../const";
import { Section } from "../../components";
import { TotalCost } from "./components/TotalCost";
import { CostLine } from "./components/CostLine";

type DepartmentCostProps = {
  departmentID: number;
  scenarioID: number;
  startDate: Date;
  setNextIndex: () => void;
  fixedCosts: ICost[];
  setFixedCosts: (costs: ICost[]) => void;
  perEmployeeCosts: ICost[];
  setPerEmployeeCosts: (costs: ICost[]) => void;
  saveCosts: () => void;
  showSummary: boolean;
  setShowSummary: (show: boolean) => void;
};

export const DepartmentCost = ({
  departmentID,
  scenarioID,
  startDate,
  setNextIndex,
  fixedCosts,
  setFixedCosts,
  perEmployeeCosts,
  setPerEmployeeCosts,
  saveCosts,
  showSummary,
  setShowSummary,
}: DepartmentCostProps) => {
  const navigate = useNavigate();
  const [fixedCostTotal, setFixedCostTotal] = useState(0);
  const [perEmployeeCostTotal, setPerEmployeeCostTotal] = useState(0);
  const [showMonth, setShowMonth] = useState(true);
  const [updateScenario] = useUpdateStepMutation();

  const [errorMessage, setErrorMessage] = useState("");
  const { sid } = useParams();

  useEffect(() => {
    setPerEmployeeCostTotal(
      perEmployeeCosts.reduce((acc, cost) => {
        return acc + Number(cost.cost_value);
      }, 0),
    );
  }, [perEmployeeCosts]);

  useEffect(() => {
    setFixedCostTotal(
      fixedCosts.reduce((acc, cost) => {
        return acc + Number(cost.cost_value);
      }, 0),
    );
  }, [fixedCosts]);

  const handleFixedCostNameChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      const newFixedCosts = [...fixedCosts];
      newFixedCosts[index] = {
        ...newFixedCosts[index],
        cost_name: e.target.value,
      };
      setFixedCosts(newFixedCosts);
    };
  };

  const handleFixedCostValueChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      const newFixedCosts = [...fixedCosts];
      newFixedCosts[index] = {
        ...newFixedCosts[index],
        cost_value: e.target.value,
      };
      setFixedCosts(newFixedCosts);
    };
  };

  const handlePerEmployeeCostNameChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      const newPerEmployeeCosts = [...perEmployeeCosts];
      newPerEmployeeCosts[index] = {
        ...newPerEmployeeCosts[index],
        cost_name: e.target.value,
      };
      setPerEmployeeCosts(newPerEmployeeCosts);
    };
  };

  const handlePerEmployeeCostValueChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      const newPerEmployeeCosts = [...perEmployeeCosts];
      newPerEmployeeCosts[index] = {
        ...newPerEmployeeCosts[index],
        cost_value: e.target.value,
      };
      setPerEmployeeCosts(newPerEmployeeCosts);
    };
  };

  const handleNextDepartment = () => {
    saveCosts();
    setNextIndex();
  };

  const handleNextPage = () => {
    saveCosts();
    updateScenario({ scenarioId: sid!, step: DEBT_DETAILS_VIEW });
    navigate("../debt");
  };

  const handleShowSummary = () => {
    saveCosts();
    setShowSummary(true);
  };

  return (
    <>
      <div className="bg-secondaryBackground p-4 border border-t-0">
        <Section
          title="Fixed Cost"
          description="Specify monthly costs that do not increase whenever you hire people."
          className="max-w-3xl border-b pb-4"
        >
          <div className="max-w-4xl">
            {fixedCosts.map((cost, index) => (
              <CostLine
                key={index}
                cost={cost}
                placeholder="Select fixed cost"
                onNameChange={handleFixedCostNameChange(index)}
                onValueChange={handleFixedCostValueChange(index)}
                onRemove={() => {
                  const newFixedCosts = [...fixedCosts];
                  newFixedCosts.splice(index, 1);
                  if (newFixedCosts.length === 0) {
                    newFixedCosts.push({ cost_name: "", cost_value: "" });
                  }
                  setFixedCosts(newFixedCosts);
                }}
                proposals={
                  departmentProposals.find(({ id }) => id === departmentID)
                    ?.fixed
                }
                onAdd={index === fixedCosts.length - 1 ? () => {
                  setFixedCosts([
                    ...fixedCosts,
                    { cost_name: "", cost_value: "" },
                  ]);
                } : undefined}
              />
            ))}
            <TotalCost total={fixedCostTotal} />
          </div>
        </Section>

        <Section
          title="Per Employee Cost"
          description="Specify any per-user monthly costs - i.e., ones that increase whenever you hire someone new. Specify monthly costs that do not increase whenever you hire people."
          className="max-w-3xl border-b pb-4"
        >
          <div className="max-w-4xl">
            {perEmployeeCosts.map((cost, index) => (
              <CostLine
                key={index}
                cost={cost}
                placeholder="Select per employee cost"
                onNameChange={handlePerEmployeeCostNameChange(index)}
                onValueChange={handlePerEmployeeCostValueChange(index)}
                onRemove={() => {
                  const newPerEmployeeCosts = [...perEmployeeCosts];
                  newPerEmployeeCosts.splice(index, 1);
                  if (newPerEmployeeCosts.length === 0) {
                    newPerEmployeeCosts.push({ cost_name: "", cost_value: "" });
                  }
                  setPerEmployeeCosts(newPerEmployeeCosts);
                }}
                proposals={
                  departmentProposals.find(({ id }) => id === departmentID)
                    ?.perEmployee
                }
                onAdd={index === perEmployeeCosts.length - 1 ? () => {
                  setPerEmployeeCosts([
                    ...perEmployeeCosts,
                    { cost_name: "", cost_value: "" },
                  ]);
                } : undefined}
              />
            ))}
            <TotalCost total={perEmployeeCostTotal} />
          </div>
        </Section>
        <div className="mb-5 mt-16 flex items-center gap-3">
          <SubCaption>Summary table</SubCaption>

          <Button onClick={handleShowSummary}>
            {showSummary ? "Recalculate" : "Show summary"}
          </Button>
          {showSummary && (
            <div className="flex-grow">
              <div className="flex items-end justify-end gap-4">
                <PeriodSelector
                  showMonths={showMonth}
                  onChange={setShowMonth}
                />
              </div>
            </div>
          )}
        </div>
        {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
        {showSummary && (
          <div className="mt-5 max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
            <SummaryTable
              departmentID={departmentID}
              scenarioID={scenarioID}
              showMonths={showMonth}
              startDate={startDate}
            />
          </div>
        )}
      </div>
      <div className="mt-16 flex gap-6">
        <Button isDisabled>Save and Exit</Button>
        <Button onClick={handleNextDepartment}>Next department</Button>
        <Button onClick={handleNextPage}>Continue</Button>
      </div>
    </>
  );
};
