export const CollapseIcon = ({ isOpen = true }: { isOpen?: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path transform={`rotate(${isOpen ? 0 : 270} 12 12)`}
      d="M7.56752 8.5C7.1436 8.5 6.91202 8.99443 7.18341 9.32009L11.1159 14.0391C11.3158 14.2789 11.6842 14.2789 11.8841 14.0391L15.8166 9.32009C16.088 8.99443 15.8564 8.5 15.4325 8.5H7.56752Z"
      fill="#656C8A"
    />
  </svg>
);
