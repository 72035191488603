import { Logo } from "../../assets/Logo";
import { UserMenu } from "./UserMenu";

export const Header = () => (
  <div className="flex min-h-16 w-full flex-row items-center border-b px-10">
    <div className="flex w-44">
      <Logo />
    </div>
    <div className="flex-grow items-center"></div>
    <UserMenu />
  </div>
);
