import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon } from "../../assets";

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  error: boolean;
};

function generateYearsBetween(
  startYear: number | undefined = undefined,
  endYear: number | undefined = undefined,
) {
  let startDate = startYear || new Date().getFullYear();
  const endDate = endYear || startDate + 5;
  let years = [];
  for (var i = startDate; i <= endDate; i++) {
    years.push(String(startDate));
    startDate++;
  }
  return years;
}

const years = generateYearsBetween();

export const YearSelector = ({ value, onChange, onBlur, error }: Props) => {
  const [selectedPerson, setSelectedPerson] = useState(value);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelectedPerson(value);
  }, [value]);

  return (
    <Combobox
      as="div"
      className="relative inline-block text-left"
      value={selectedPerson}
      onChange={onChange}
    >
      <div className="flex flex-col">
        <Combobox.Label
          htmlFor="yearCombobox"
          className="mb-2 text-sm font-semibold"
        >
          Year
        </Combobox.Label>
        <Combobox.Input
          className={`inline-flex w-52 justify-between gap-x-1.5 rounded-md bg-white px-3 py-3 shadow-sm ring-1 ring-inset hover:bg-gray-50 ${
            error ? "ring-red-500" : "ring-gray-300"
          }`}
          onChange={(event) => {
            setQuery(event.target.value);
            setSelectedPerson(event.target.value);
          }}
          onBlur={() => {
            onBlur && onBlur(selectedPerson);
          }}
          id="yearCombobox"
          placeholder="Year"
        />

        <Combobox.Button className="absolute inset-y-0 right-0 top-6 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
      </div>
      <Combobox.Options className="absolute right-0 z-10 mt-2 w-52 origin-top-right cursor-pointer rounded-md bg-white pl-3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {query.length > 0 && !years.some((val) => val === query) && (
          <Combobox.Option value={query} hidden>
            {query}
          </Combobox.Option>
        )}
        {years.map((person) => (
          <Combobox.Option key={person} value={person}>
            {person}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};
