import { SVGProps } from "react";
export const PercentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle cx={10.335} cy={10.335} r={8.335} fill="#656C8A" />
    <path
      fill="#fff"
      d="M7.75 9.5c-.483 0-.896-.17-1.237-.512A1.686 1.686 0 0 1 6 7.75c0-.483.17-.896.513-1.237A1.686 1.686 0 0 1 7.75 6c.483 0 .896.17 1.238.513.341.341.512.754.512 1.237 0 .483-.17.896-.512 1.238A1.686 1.686 0 0 1 7.75 9.5Zm0-1a.724.724 0 0 0 .531-.219.721.721 0 0 0 .219-.531.724.724 0 0 0-.219-.532A.721.721 0 0 0 7.75 7a.724.724 0 0 0-.532.219A.721.721 0 0 0 7 7.75c0 .208.073.386.219.531a.721.721 0 0 0 .531.219Zm4.5 5.5c-.483 0-.896-.17-1.238-.512a1.686 1.686 0 0 1-.512-1.238c0-.483.17-.896.512-1.238a1.686 1.686 0 0 1 1.238-.512c.483 0 .896.17 1.238.512.341.342.512.755.512 1.238s-.17.896-.512 1.238A1.686 1.686 0 0 1 12.25 14Zm0-1a.724.724 0 0 0 .531-.219.721.721 0 0 0 .219-.531.724.724 0 0 0-.219-.531.721.721 0 0 0-.531-.219.724.724 0 0 0-.531.219.721.721 0 0 0-.219.531c0 .208.073.386.219.531a.721.721 0 0 0 .531.219ZM6.7 14l-.7-.7L13.3 6l.7.7L6.7 14Z"
    />
  </svg>
);
