import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  isEqual,
  parse,
} from "date-fns";
import { Caption } from "../../components/Caption";
import { Button } from "../../components/Button";
import { CloseIcon } from "../../assets/icons/CloseIconn";
import { IProduct, IRevenueProduct } from "../../models/backend";
import { ProductSummaryCard } from "./ProductSummaryCard";
import { Section } from "../../components/Section";
import { PeriodSelector } from "../../components/PeriodSelector";
import { useParams } from "react-router-dom";
import {
  useGetSummaryQuarterlyQuery,
  useGetSummaryQuery,
} from "../../services/patric";
import { DetailsTableCommon } from "./DetailsTableCommon";

export type ProductDetails = IRevenueProduct & IProduct;

type DetailsTableProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DetailsTable = ({ isOpen, onClose }: DetailsTableProps) => {
  const { sid } = useParams();

  const { data: summaryData } = useGetSummaryQuery({ scenarioID: Number(sid) });
  const { data: summaryQuarterlyData } = useGetSummaryQuarterlyQuery({
    scenarioID: Number(sid),
  });
  const [showMonths, setShowMonths] = useState(false);
  const [showMonthsProducts, setShowMonthsProducts] = useState(false);

  if (!summaryData || !summaryQuarterlyData) {
    return null;
  }

  let colorIndex = 0;
  const periods = summaryData.periods.map((period) => {
    const date = parse(period, "yyyy-MM", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfMonth(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "MMM"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  colorIndex = 0;
  const periodsQuarterly = summaryQuarterlyData.periods.map((period) => {
    const date = parse(period, "yyyy-Q", new Date());
    const lColorIndex = colorIndex;
    const isLast = isEqual(endOfQuarter(date), endOfYear(date));
    if (isLast) colorIndex++;
    return {
      period: format(date, "QQQ"),
      year: format(date, "yyyy"),
      isLast,
      colorIndex: lColorIndex,
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50 flex flex-col"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="mx-auto my-auto max-w-7xl rounded-lg bg-white">
            <div className="flex w-full justify-between p-5">
              <Caption noMargin>Revenue</Caption>
              <Button style="ghost" onClick={onClose}>
                <CloseIcon />
              </Button>
            </div>

            <Dialog.Description className="m-5 flex flex-col gap-10 font-semibold">
              <Section
                title="Predictions"
                description="Based on the information you provided, here is how your marketing efforts translate into customers and what that means for your future sales."
                button={
                  <PeriodSelector
                    showMonths={showMonths}
                    onChange={setShowMonths}
                  />
                }
              >
                <div className="max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
                  <DetailsTableCommon
                    periods={showMonths ? periods : periodsQuarterly}
                    data={
                      showMonths
                        ? summaryData.revenue
                        : summaryQuarterlyData.revenue
                    }
                  />
                </div>
              </Section>
              <Section
                title=""
                button={
                  <PeriodSelector
                    showMonths={showMonthsProducts}
                    onChange={setShowMonthsProducts}
                  />
                }
              >
                {showMonthsProducts
                  ? summaryData.revenue.products.map((value) => (
                      <ProductSummaryCard data={value} periods={periods} />
                    ))
                  : summaryQuarterlyData.revenue.products.map((value) => (
                      <ProductSummaryCard
                        data={value}
                        periods={periodsQuarterly}
                      />
                    ))}
              </Section>
            </Dialog.Description>
            <div className="flex gap-5 p-5">
              <Button style="outline" variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
