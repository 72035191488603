import { Bar } from "react-chartjs-2";

type ChartProps = {
  data: {
    id: number;
    year: string;
    cashFlow: number;
    payingUser: number;
  }[];
};

function createDiagonalPattern(color = "black") {
  // create a 10x10 px canvas for the pattern's base shape
  let shape = document.createElement("canvas");
  shape.width = 10;
  shape.height = 10;

  // get the context for drawing
  const c = shape.getContext("2d");
  if (!c) return color;
  // draw 1st line of the shape
  c.fillStyle = color;
  c.fillRect(0, 0, 10, 10);
  c.strokeStyle = "white";
  c.lineWidth = 2;
  c.beginPath();
  c.moveTo(2, 0);
  c.lineTo(10, 8);
  c.stroke();
  // draw 2nd line of the shape
  c.beginPath();
  c.moveTo(0, 8);
  c.lineTo(2, 10);
  c.stroke();
  // create the pattern from the shape
  return c.createPattern(shape, "repeat");
}

export const CashFlowChart = ({ data }: ChartProps) => {
  const chartData = {
    labels: data.map((data) => data.year),
    datasets: [
      {
        label: "Paying Users",
        data: data.map((data) => data.payingUser),
        backgroundColor: ["#00A3FF"],
        borderColor: "#00A3FF",
        borderWidth: 2,
        yAxisID: "y1",
      },
      {
        label: "Cash Flow",
        data: data.map((data) => data.cashFlow),
        backgroundColor: data.map((data) =>
          data.cashFlow > 0 ? "#FFA800" : createDiagonalPattern("#FFA800"),
        ),
        borderColor: "#FFA800",
        borderWidth: 0,
        yAxisID: "y",
      },
    ],
  };

  const maxTicks = 8;
  const maximumCashFlow = Math.max(...data.map((data) => data.cashFlow)) * 1.01;
  const minimumCashFlow = Math.min(...data.map((data) => data.cashFlow));
  const maximumPayingUser =
    Math.max(...data.map((data) => data.payingUser)) * 1.1;
  const heightCashFlow =
    Math.max(maximumCashFlow, 1000) - Math.min(minimumCashFlow, 0);
  const cashFlowTick = Math.ceil(heightCashFlow / maxTicks);
  const pMultiplyer = Math.ceil(maximumCashFlow / cashFlowTick);
  const nMultiplyer = Math.floor(minimumCashFlow / cashFlowTick);

  const payingUserTick = Math.floor(maximumPayingUser / pMultiplyer);
  const maxCashFlow = pMultiplyer * cashFlowTick;
  const minCashFlow = nMultiplyer * cashFlowTick;
  const maxPayingUser = pMultiplyer * payingUserTick;
  const minPayingUser = nMultiplyer * payingUserTick;

  const breakEvenPoint = data.findIndex((data3) => data3?.cashFlow >= 0);
  return (
    <div className="chart-container border border-gray-300">
      <Bar
        // @ts-ignore
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "",
              align: "end",
            },
            legend: {
              display: true,
              position: "bottom",
              labels: {
                // @ts-ignore
                generateLabels: (chart) => {
                  const { data } = chart;
                  if (data.datasets.length) {
                    return data.datasets.map((label, i) => {
                      return {
                        text: label.label,
                        fillStyle: label.borderColor,
                        strokeStyle: label.borderColor,
                        lineWidth: 0,
                        hidden: isNaN(label.data[i] as number),
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
            },
            tooltip: {
              enabled: true,
              backgroundColor: "#fff",
              titleColor: "#000",
              bodyColor: "#000",
            },
            customBackground: {
              position: breakEvenPoint >= 0 ? breakEvenPoint : 13,
              color: "#FFA800",
              fromStart: true,
            },
            customTitle: {
              text: [
                {
                  text: `${breakEvenPoint >= 0 ? data[breakEvenPoint].year : "-"}: `,
                  color: "#000",
                },
                { text: "Cash Flow", color: "#FFA800" },
                { text: " > ", color: "#000" },
                { text: "0", color: "#00A3FF" },
              ],
            },
            customAxisTitle: {
              leftText: "Cash Flow, $",
              rightText: "Paying Users",
            },
          },
          interaction: {
            mode: "index",
          },
          scales: {
            x: {
              grid: { offset: false, color: "#E8E9ED", tickColor: "#656C8A" },
            },
            y: {
              max: maxCashFlow,
              min: minCashFlow,
              ticks: { stepSize: cashFlowTick },
              title: {
                display: false,
                align: "end",
                text: "Cash Flow",
                // color: "#FFA800",
              },
            },
            y1: {
              position: "right",
              title: {
                display: false,
                align: "end",
                text: "Paying Users",
                //   color: "#00A3FF",
              },
              max: maxPayingUser,
              min: minPayingUser,
              ticks: {
                stepSize: payingUserTick,
              },
            },
          },
        }}
      />
    </div>
  );
};
