import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import styles from "./WelcomePage.module.css";
import { ZoomIn } from "../../assets";
import { InfoPoint } from "./components/InfoPoint";
import { Contact } from "../../components/Contact/Contact";

window.addEventListener("scroll", setScrollVar);
window.addEventListener("resize", setScrollVar);

function setScrollVar() {
  const htmlElement = document.documentElement;
  const percentOfScreenHeightScrolled =
    htmlElement.scrollTop / htmlElement.clientHeight;
  htmlElement.style.setProperty(
    "--scroll",
    String(percentOfScreenHeightScrolled * 100),
  );
  htmlElement.style.setProperty(
    "--scrollHeight",
    String(htmlElement.scrollTop),
  );
}

setScrollVar();

const Amplified = ({ children }: { children: React.ReactNode }) => (
  <span className="text-xl font-semibold">{children}</span>
);

const FullScreenImage = ({
  src,
  onClose,
}: {
  src: string;
  onClose: () => void;
}) => {
  return (
    <div className="fixed inset-5 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="relative max-h-screen overflow-scroll rounded-lg bg-white p-5">
        <img src={src} onClick={onClose} alt="Full screen" />
        <button className="absolute right-2 top-2 text-black" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const OffersCard = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      minHeight: "100vh",
      backgroundColor: "white",
      padding: "5rem 3rem 2rem 3rem",
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    }}
  >
    {children}
    <Link
      className="w-fit rounded-md bg-normal px-6 py-3 text-white hover:bg-hover"
      to="/auth/signup"
    >
      Try it now
    </Link>
  </div>
);

export const WelcomePage = () => {
  const [fullScreenImage, setFullScreenImage] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (let i = entries.length - 1; i >= 0; i--) {
        const entry = entries[i];
        if (entry.isIntersecting) {
          document.querySelectorAll("[data-current]").forEach((img) => {
            (img as HTMLElement).dataset.current = "false";
          });
          const viewSec = (entry.target as HTMLElement).dataset.viewSec;
          if (!viewSec) continue;
          const img = document.querySelector(viewSec) as HTMLElement;
          if (img) img.dataset.current = "true";
          break;
        }
      }
    });

    document.querySelectorAll("[data-view-sec]").forEach((section) => {
      observer.observe(section);
    });
  }, []);

  return (
    <div className={`w-full bg-welcomeBackground`}>
      <main className="mx-auto min-w-[1230px] max-w-8xl px-5">
        <Outlet />
        {fullScreenImage && (
          <FullScreenImage
            src={fullScreenImage}
            onClose={() => setFullScreenImage("")}
          />
        )}
        <div className="flex w-full items-center justify-between py-8">
          <div className="h-12">
            <img src="/logo_main.svg" alt="Logo" />
          </div>
          <div className="flex gap-2">
            <Link
              className="w-fit rounded-md bg-normal px-6 py-3 text-white hover:bg-hover"
              to="/auth/signup"
            >
              Try it now
            </Link>
            <Link
              className="rounded-md bg-primaryText px-6 py-3 text-white"
              to="/auth/login"
            >
              Log in
            </Link>
          </div>
        </div>
        <div className="flex w-full flex-col scroll-smooth">
          <section className={styles.top_section}>
            <div className={styles.top_content}>
              <div className={styles.top_container}>
                <div className={styles.top_image}>
                  <img src="/landing_top.png" />

                  <div className={styles.top_text}>
                    <p className="max-w-lg text-5xl font-medium text-white">
                      It's like having a friendly guide that helps you figure
                      out all the money stuff.
                    </p>
                    <p className="mt-4 max-w-md text-lg font-medium leading-8 text-white">
                      You don't need to be a money expert or have a fancy degree
                      to use it. We make it simple. We show you how much money
                      you have and what you need. So, you can take your idea and
                      make a plan to succeed without worrying.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.slogan}>
              <div className={styles.slogan_top}>
                <p className="text-2xl text-text2">
                  Durning Dreams into Plans:
                </p>
                <p className="text-6xl text-primaryText">
                  Your Goal, Our Guide
                </p>
              </div>
              <div className={styles.slogan_line}>
                <p className="text-base text-black">
                  Starting something new, like your own business can feel really
                  scary because you might not know if you have enough money to
                  make it work.
                </p>
                <p className="text-base font-semibold text-black">
                  That's where our app comes in.
                </p>
              </div>
            </div>
            <div className={styles.top_bars}>
              <div className={styles.top_bar}>
                <div
                  className={styles.hero_bar_top}
                  style={{ height: "296px" }}
                />
                <div className={styles.hero_bar_bottom} />
              </div>
              <div className={styles.between_bar} />
              <div className={styles.top_bar}>
                <div
                  className={styles.hero_bar_top}
                  style={{ height: "212px" }}
                />
                <div className={styles.hero_bar_bottom} />
              </div>
              <div className={styles.between_bar} />
              <div className={styles.top_bar}>
                <div
                  className={styles.hero_bar_top}
                  style={{ height: "150px" }}
                />
                <div className={styles.hero_bar_bottom} />
              </div>
              <div className={styles.between_bar} />
              <div className={styles.top_bar}>
                <div
                  className={styles.hero_bar_top}
                  style={{ height: "70px" }}
                />
                <div className={styles.hero_bar_bottom} />
              </div>
            </div>
            <section className={styles.middle_section}>
              <p className="text-3xl">
                We are here to help you with planning the money part.
              </p>
              <img className="w-96" src="/divider.svg" />
              <p className="max-w-[450px] text-center text-2xl">
                With our app, starting something new doesn't have to be scary
                anymore.
              </p>
            </section>
            <div className="h-screen" />
            <div className="h-screen" />
          </section>
          <section className={styles.offers_section}>
            <div className={styles.offers_left}>
              <div className={styles.offers_left_title}>
                <p className="text-5xl">
                  We offer an all-encompassing financial calculator tailored
                  for:
                </p>
                <ul className={styles.points}>
                  <li
                    data-current="true"
                    id="sec1"
                    className="text-inactiveItem data-[current=true]:text-black [&>div]:data-[current=true]:bg-black"
                  >
                    <div className="z-10 h-5 w-5 shrink-0 items-center justify-center rounded-full bg-inactiveItem" />
                    Simplifying payroll processes
                  </li>
                  <li
                    data-current="false"
                    id="sec2"
                    className="text-inactiveItem data-[current=true]:text-black [&>div]:data-[current=true]:bg-black"
                  >
                    <div className="z-10 h-5 w-5 shrink-0 items-center justify-center rounded-full bg-inactiveItem" />
                    Monitoring departmental expenditures
                  </li>
                  <li
                    data-current="false"
                    id="sec3"
                    className="text-inactiveItem data-[current=true]:text-black [&>div]:data-[current=true]:bg-black"
                  >
                    <div className="z-10 h-5 w-5 shrink-0 items-center justify-center rounded-full bg-inactiveItem" />
                    Strategizing debt repayment
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.offers_right}>
              <OffersCard>
                <div className="relative">
                  <img className="w-full" src="/payroll.png" />
                  <div
                    className="absolute left-0 top-0 flex h-full w-full cursor-pointer items-center justify-center"
                    onClick={() => setFullScreenImage("/payroll_full.png")}
                  >
                    <div className="rounded-full bg-white p-2 shadow-lg">
                      <ZoomIn />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-4xl" data-view-sec="#sec1">
                    Payroll
                  </p>
                  <p className="mt-5 text-base leading-8">
                    Knowing your <Amplified>payroll costs</Amplified> helps you
                    create <Amplified>a realistic budget</Amplified> that
                    accurately reflects your financial commitments. Our app
                    plays a vital role in{" "}
                    <Amplified>simplifying payroll planning</Amplified>. With
                    our app, you can streamline payroll calculations, and make
                    informed decisions, contributing to better budgeting and
                    financial management.
                  </p>
                </div>
              </OffersCard>
              <OffersCard>
                <div className="relative">
                  <img className="w-full" src="/departments.png" />
                  <div
                    className="absolute left-0 top-0 flex h-full w-full cursor-pointer items-center justify-center"
                    onClick={() => setFullScreenImage("/departments_full.png")}
                  >
                    <div className="rounded-full bg-white p-2 shadow-lg">
                      <ZoomIn />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-4xl" data-view-sec="#sec2">
                    Departments
                  </p>
                  <p className="mt-5 text-base leading-8">
                    In any business, there are various departments that play
                    unique roles. It's essential to consider the{" "}
                    <Amplified>expenses of these departments</Amplified>{" "}
                    individually. By doing so, you gain a clear understanding of
                    where resources are allocated, allowing for{" "}
                    <Amplified>better financial planning</Amplified> and
                    informed decision-making. Properly assessing departmental
                    expenses ensures efficient budget management and overall{" "}
                    <Amplified>business success.</Amplified>
                  </p>
                </div>
              </OffersCard>
              <OffersCard>
                <div className="relative">
                  <img className="w-full" src="/debt.png" />
                  <div
                    className="absolute left-0 top-0 flex h-full w-full cursor-pointer items-center justify-center"
                    onClick={() => setFullScreenImage("/debt_full.png")}
                  >
                    <div className="rounded-full bg-white p-2 shadow-lg">
                      <ZoomIn />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-4xl" data-view-sec="#sec3">
                    Debt
                  </p>
                  <p className="mt-5 text-base leading-8">
                    Think of a business like a household. Just as a family might
                    borrow money to buy a house or car, businesses also take
                    loans for big projects or to keep things running. When we
                    look at how a business is doing, it's really important to
                    consider how much it owes, just like we'd consider a
                    family's mortgage or car loan. If a business owes a lot, it
                    could struggle to pay back, especially if things get tough.
                    But{" "}
                    <Amplified>
                      if it manages its debt wisely, it can use that borrowed
                      money to grow even more.
                    </Amplified>{" "}
                    By checking a business's debts, we get a clearer picture of
                    its health and if it's making smart choices. It's like
                    peeking into a family's finances to see if they're living
                    within their means.
                  </p>
                </div>
              </OffersCard>
            </div>
          </section>
          <section className={styles.summary_section}>
            <p className="text-5xl">Summary page</p>
            <p className="max-w-3xl text-center text-2xl">
              Assess how your decisions influence the success of your business
              and receive guidance on enhancing your strategy
            </p>
          </section>
          <section className={styles.summary_image_section}>
            <div className={styles.summary_image}>
              <img src="/summary_full.png" />
              <InfoPoint x={235} y={230}>
                Through easy-to-understand visual graphs, swiftly pinpoint two
                key milestones for your business: the time you'll reach
                profitability and when you're likely to clear your business
                debts."
              </InfoPoint>
              <InfoPoint x={955} y={380}>
                Get immediate insight into how crucial elements like pricing and
                conversion rates shape your forecast. See the effects of any
                changes in real-time.
              </InfoPoint>
              <InfoPoint x={245} y={820}>
                Our engine automatically determines how much money you will need
                to start your project and keep it going through that critical
                first year of business.
              </InfoPoint>
              <InfoPoint x={625} y={1060}>
                Our recommendations help you modify your plan effectively,
                accelerating your progress towards achieving your objectives.
              </InfoPoint>
            </div>
          </section>
          <section className={styles.what_section}>
            <p className="text-5xl">What you will get</p>
            <div className={styles.what_cards}>
              <div>
                <img src="/user-interaction.svg" />
                <p>
                  User-friendly dashboards designed to swiftly show you when
                  your business will become financially independent.
                </p>
              </div>
              <div>
                <img src="/predict.svg" />
                <p>
                  Accurate projections of the initial funding required and what
                  it takes to successfully navigate the crucial first year of
                  your business.
                </p>
              </div>
              <div>
                <img src="/stargazing.svg" />
                <p>
                  Easily tweak key numbers and see the immediate results, all in
                  one simple, user-friendly view.
                </p>
              </div>
              <div>
                <img src="/success.svg" />
                <p>
                  Valuable advice for enhancing your strategy to help you reach
                  success more rapidly
                </p>
              </div>
            </div>
          </section>
          <section className={styles.success_section}>
            <p className="max-w-2xl text-center text-3xl">
              True success depends upon strategic planning
            </p>
            <img className="w-96" src="/divider.svg" />
            <p className="max-w-[861px] text-center text-2xl">
              GrowCalc offers a straightforward approach to mapping out your
              expenditures and anticipated income, ensuring your plan is
              optimally positioned for success.
            </p>
          </section>
          <section className={styles.faq_section}>
            <p className="text-5xl">FAQ</p>
            <details>
              <summary>
                <p>Who should use GrowCalc?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                Our service is ideal for startup founders and anyone involved in
                the financial planning and management of a new business.
              </p>
            </details>
            <details>
              <summary>
                <p>How does GrowCalc help in planning startup expenses?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                We guide you through all the areas where your business is likely
                to have revenue or expenses and then demonstrate the long-term
                consequences of that plan on your profitability, allowing you to
                adjust your strategies to produce the best possible future
                growth
              </p>
            </details>
            <details>
              <summary>
                <p>What features does GrowCalc offer?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                Key features include budget forecasting, financial reporting,
                and scenario analysis, all tailored to the unique needs of
                startups.
              </p>
            </details>
            <details>
              <summary>
                <p>Is there a trial period for new users?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                For now, the tool is completely free and provides full access to
                all features, so you can see how GrowCalc fits your needs
                without any commitment.
              </p>
            </details>
            <details>
              <summary>
                <p>How much does it cost?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                We have not determined pricing yet. For now, the tool is
                completely free and provides full access to all features.
              </p>
            </details>
            <details>
              <summary>
                <p>How do I sign up for GrowCalc?</p>
                <div className={styles.button}>
                  <span />
                  <span />
                </div>
              </summary>
              <p>
                You can sign up directly on our website. Just provide some basic
                information about your startup and you can start using our
                service immediately.
              </p>
            </details>
          </section>
          <section className={styles.contact_section}>
            <Contact />
          </section>
          <div className="flex h-14 w-full items-center justify-between bg-white px-8 py-20">
            <div className="flex h-12 items-center gap-5">
              <img src="/logo_main.svg" alt="Logo" />
              <p className="text-disabledButtonText">
                All&nbsp;rights&nbsp;reserved.&nbsp;©2024&nbsp;Growth
              </p>
            </div>
            <div className="flex gap-2">
              <Link
                className="w-fit rounded-md bg-normal px-6 py-3 text-white hover:bg-hover"
                to="/auth/signup"
              >
                Try it now
              </Link>
              <Link
                className="rounded-md bg-primaryText px-6 py-3 text-white"
                to="/auth/login"
              >
                Log in
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
