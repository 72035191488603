import { SVGProps } from "react";

export const SalesDpt = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_1069_2663)">
        <path
          stroke="#343B58"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M1.771 9.343L18.2 1.87m0 0L15.128.714M18.2 1.871l-1.143 3.072M18.928 19.3h-3.571v-10a.715.715 0 01.714-.714h2.143a.714.714 0 01.714.714v10zm-7.142 0H8.214v-7.857a.714.714 0 01.714-.714h2.143a.714.714 0 01.715.714V19.3zm-7.143 0H1.07v-5.714a.714.714 0 01.715-.715h2.142a.715.715 0 01.715.715V19.3z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1069_2663">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
