import {
  HeaderCaption,
  HeaderPeriodSimple,
  Line,
  Periods,
  TableLineSimple,
} from "../../components/Table";
import { DollarIcon, PercentIcon } from "../../assets";
import { formatSalary } from "../../utils/format";
import { useGetGeneralInfoQuery } from "../../services/patric";
import { useParams } from "react-router-dom";
import { IRevenueProduct } from "../../models/backend";

type ProductSummaryCardProps = {
  data: IRevenueProduct;
  periods: Periods;
};

export const ProductSummaryCard = ({
  data,
  periods,
}: ProductSummaryCardProps) => {
  const { sid } = useParams();
  const { data: generalData } = useGetGeneralInfoQuery(Number(sid));

  const { conversion, price } = generalData?.products.products.find(
    (product) => product.name === data.product_name,
  ) ?? { conversion: 0, price: 0 };
  const grossRevenue: Line = {
    name: "Gross revenue, $",
    values: data.gross_revenues,
  };

  const taxes: Line = {
    name: "Taxes, $",
    values: data.taxes,
  };

  const salesCommission: Line = {
    name: "Sales comission, $",
    values: data.sales_commissions,
  };

  const gatewayFees: Line = {
    name: "Gateway fees, $",
    values: data.gateway_fees,
  };

  const netRevenue: Line = {
    name: "Net revenue, $",
    values: data.net_revenues,
  };
  return (
    <div>
      <div className="flex items-center gap-3">
        <span>{data.product_name}</span>
        <div className="flex items-center">
          <DollarIcon />
          <span>{formatSalary(String(price))}</span>
        </div>
        <div className="flex items-center">
          <PercentIcon />
          <span>{conversion}</span>
        </div>
      </div>
      <div className="mt-5 max-w-7xl overflow-y-clip overflow-x-scroll rounded-lg border border-gray-200">
        <table className="w-full table-fixed border-separate border-spacing-0 overflow-x-scroll border-2">
          <thead className="bg-gray-200">
            <tr>
              <HeaderCaption caption="" />
              {periods.map((month) => (
                <HeaderPeriodSimple
                  period={month.period}
                  year={month.year}
                  width="w-28"
                  isLast={month.isLast}
                  colorIndex={month.colorIndex}
                />
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
            <TableLineSimple line={grossRevenue} periods={periods} />
            <TableLineSimple line={taxes} periods={periods} />
            <TableLineSimple line={salesCommission} periods={periods} />
            <TableLineSimple line={gatewayFees} periods={periods} />
            <TableLineSimple line={netRevenue} periods={periods} />
          </tbody>
        </table>
      </div>
    </div>
  );
};
