import { useEffect, useState } from "react";

export const Input = ({
  id,
  type,
  label,
  value,
  postfix = "",
  fullWidth = false,
  setValue,
  onChange,
  isDisabled = false,
  onBlur,
  error,
  showLabel = false,
}: {
  id: string;
  type: React.HTMLInputTypeAttribute;
  label?: string;
  value?: string;
  postfix?: string;
  fullWidth?: boolean;
  setValue?: (value: string) => void;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  onBlur?: (value: string) => void;
  error?: string;
  showLabel?: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(value ?? "");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(event.target.value);
    }
    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const showValue = !isDirty && (inputValue === "" || inputValue === undefined || inputValue === "0") ? "" : inputValue;
  const isLabelVisible = showLabel && showValue !== "";

  return (
    <div
      className={`relative inline-block text-left ${fullWidth ? "w-full" : "w-64"
        }`}
    >
      <div
        className={`inline-flex w-full justify-between gap-x-1.5 rounded-md px-0 py-0 shadow-sm ring-1 ring-inset
      
      ${isDisabled ? "bg-[#F2F3F5]" : "bg-white hover:bg-gray-50"}
      ${error ? "ring-red-500" : "ring-gray-300"}`}
      >
        <input
          type={type}
          id={id}
          value={showValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={() => setIsDirty(true)}
          className="h-12 w-full bg-transparent px-2"
          disabled={isDisabled}
          placeholder={label}
          onKeyDown={(e) => {
            const allowedHeadlessUIKeys = [
              "ArrowUp",
              "ArrowDown",
              "Enter",
              "Home",
              "End",
              "Escape",
            ];
            if (!allowedHeadlessUIKeys.includes(e.key)) {
              e.stopPropagation();
            }
          }}
        />
        {inputValue && isLabelVisible && (
          <label
            htmlFor={id}
            className="absolute left-2.5 top-5 z-10 origin-[0] -translate-y-7 scale-75 transform  text-base text-secondaryText duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
          >
            {label}
          </label>
        )}
        <div className="absolute bottom-3.5 end-2.5 inline-flex h-5 w-5 items-center justify-center">
          {postfix}
        </div>
      </div>
    </div>
  );
};
