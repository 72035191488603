import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";
import { Button } from "../../components";
import { CloseIcon } from "../../assets/icons/CloseIconn";

export const DeleteProjectDialog = ({
    show,
    onClose,
    onSubmit
}: {
    show: boolean;
    onClose: () => void;
    onSubmit: () => void;
}) =>
(<Transition appear show={show} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <DialogTitle
                            className="flex justify-between"
                        >
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Delete project</h3>
                            <button onClick={onClose}><CloseIcon /></button>
                        </DialogTitle>
                        <div className="mt-8">
                            <p className="text-sm text-gray-500">
                                Are you sure you want to delete the <span className="font-semibold">Project #1</span>?
                            </p>
                            <p className="text-sm text-gray-500">
                                This action cannot be undone
                            </p>
                        </div>

                        <div className="w-full flex justify-between mt-8">
                            <Button style="outline" onClick={onClose}>Cancel</Button>
                            <Button variant="alert" onClick={onSubmit}>Delete</Button>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    </Dialog>
</Transition>)