import { useState } from "react";
import { differenceInCalendarYears } from "date-fns";
import { CollapseIcon } from "../../assets";
import { MONTH_COUNT } from "../../const";
import {
  useGetDepartmentsQuery,
  useGetPayrollSummmaryQuery,
} from "../../services/patric";
import { IDepartment, IPayrollResponse } from "../../models/backend";
import {
  HeaderCaption,
  HeaderWidePeriod,
  TableCell,
  getMonths,
  getPeriods,
  groupMonths,
} from "../Table";
import { PayrollRoleSection, RoleCell } from "./PayrollRoleSection";
import { IHpPosition } from "../../models/department";

type Props = {
  startDate: Date;
  scenarioID: number;
  showMonths: boolean;
  positions: IHpPosition[];
};

type TotalCell = {
  month: number;
  amount: string;
  count: number;
};

type DepartmentPayroll = {
  departmentID: number;
  departmentName: string;
  total: TotalCell[];
  details: {
    full_times: RoleCell[];
    freelancers: RoleCell[];
    contractors: RoleCell[];
  };
};

const fillPositions = (
  hiringType: "full_times" | "freelancers" | "contractors",
  payroll: IPayrollResponse,
  roles: IHpPosition[],
  departments: IDepartment[],
  data: DepartmentPayroll[],
) => {
  payroll[hiringType].forEach((ft) => {
    const role = roles.find((r) => r.role_id === ft.role_id);
    if (!role) {
      console.error("Role not found: ", ft.role_id);
      return;
    }

    const department = departments.find((d) => d.id === role?.department_id);
    if (!department) {
      console.error("Department not found: ", role.department_id);
      return;
    }

    const depRef = data.find((d) => d.departmentID === department.id);
    if (!depRef) {
      console.error("Department not found: ", department.id);
      return;
    }

    depRef.details[hiringType].push({
      roleID: ft.role_id,
      roleName: role.role_name,
      month: ft.month,
      amount: ft.amount,
      count: ft.count,
    });
  });
};

const DepartmentSection = ({
  department,
  showMonths,
  startDate,
}: {
  department: DepartmentPayroll;
  showMonths: boolean;
  startDate: Date;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const full_times = department.details.full_times
    .reduce(
      (acc, ft) => (acc.includes(ft.roleID) ? acc : [...acc, ft.roleID]),
      [] as number[],
    )
    .sort((a, b) => a - b);

  const freelancers = department.details.freelancers
    .reduce(
      (acc, ft) => (acc.includes(ft.roleID) ? acc : [...acc, ft.roleID]),
      [] as number[],
    )
    .sort((a, b) => a - b);

  const contractors = department.details.contractors
    .reduce(
      (acc, ft) => (acc.includes(ft.roleID) ? acc : [...acc, ft.roleID]),
      [] as number[],
    )
    .sort((a, b) => a - b);

  const handleOpenClick = () => {
    setIsOpen((prev) => !prev);
  };

  const months = getMonths(startDate, showMonths, MONTH_COUNT);
  const periods = getPeriods(months, showMonths);

  const totalValues = department.total.map((t) => Number(t.amount));
  const values = groupMonths(months, totalValues);

  return (
    <>
      <tr className="border">
        <td className="sticky left-0 border bg-white py-2 pl-5 text-sm font-semibold leading-normal text-gray-800">
          <button onClick={handleOpenClick} className="flex">
            <CollapseIcon isOpen={isOpen} />
            {department.departmentName}
          </button>
        </td>
        {values.map((total, index) => (
          <TableCell
            key={index}
            className="border px-2 text-right text-sm font-semibold leading-normal text-gray-800"
            value={Number(total || 0)}
            months={periods}
            index={index}
            showMonths={showMonths}
          />
        ))}
      </tr>
      {isOpen && full_times.length > 0 && (
        <PayrollRoleSection
          isOpen={isOpen}
          section={full_times}
          showMonths={showMonths}
          startDate={startDate}
          sums={department.details.full_times}
          name="Full Time"
        />
      )}
      {isOpen && freelancers.length > 0 && (
        <PayrollRoleSection
          isOpen={isOpen}
          section={freelancers}
          showMonths={showMonths}
          startDate={startDate}
          sums={department.details.freelancers}
          name="Freelancers"
        />
      )}
      {isOpen && contractors.length > 0 && (
        <PayrollRoleSection
          isOpen={isOpen}
          section={contractors}
          showMonths={showMonths}
          startDate={startDate}
          sums={department.details.contractors}
          name="Contractors"
        />
      )}
    </>
  );
};

export const TotalPayrollTable = ({
  scenarioID,
  showMonths,
  startDate,
  positions: roles,
}: Props) => {
  const { data: payroll } = useGetPayrollSummmaryQuery(scenarioID);
  const { data: departments } = useGetDepartmentsQuery(scenarioID);

  if (!payroll || !departments) {
    return;
  }

  const data: DepartmentPayroll[] = [];

  payroll.departments.forEach((dep) => {
    let depRef = data.find((d) => d.departmentID === dep.department_id);

    if (!depRef) {
      const departmentName = departments.find(
        (d) => d.id === dep.department_id,
      )?.name;
      depRef = {
        departmentID: dep.department_id,
        departmentName: departmentName ?? "",
        total: [],
        details: {
          full_times: [],
          freelancers: [],
          contractors: [],
        },
      };
      data.push(depRef);
    }

    depRef.total[dep.month - 1] = {
      month: dep.month,
      amount: dep.amount,
      count: dep.count,
    };
  });

  fillPositions("full_times", payroll, roles, departments, data);
  fillPositions("freelancers", payroll, roles, departments, data);
  fillPositions("contractors", payroll, roles, departments, data);

  for (let dep of data) {
    if (!dep.total[0]) dep.total[0] = { month: 0, amount: "0", count: 0 };
    for (let i = 1; i < MONTH_COUNT; i++)
      if (!dep.total[i]) dep.total[i] = { ...dep.total[i - 1], month: i };
  }

  const total: TotalCell[] = payroll.total.reduce((acc, s) => {
    acc[s.month - 1] = s;
    return acc;
  }, [] as TotalCell[]);

  if (!total[0]) total[0] = { month: 0, amount: "0", count: 0 };
  for (let i = 1; i < MONTH_COUNT; i++)
    if (!total[i]) total[i] = { ...total[i - 1], month: i };

  const months = getMonths(startDate, showMonths, MONTH_COUNT);
  const periods = getPeriods(months, showMonths);

  const totalValues = total.map((t) => Number(t.amount));
  const values = groupMonths(months, totalValues);

  return (
    <table className="w-full table-fixed border-separate border-spacing-0">
      <thead className="bg-gray-200">
        <tr>
          <HeaderCaption caption="Total" />
          {periods.map((month) => (
            <HeaderWidePeriod
              key={month.index}
              month={month.date}
              showMonths={showMonths}
              colorIndex={differenceInCalendarYears(month.date, startDate) % 3}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((dep) => (
          <DepartmentSection
            key={dep.departmentID}
            department={dep}
            showMonths={showMonths}
            startDate={startDate}
          />
        ))}
        <tr className="border">
          <td className="sticky left-0 border bg-white pl-8 text-sm  font-semibold leading-normal text-gray-800">
            Total
          </td>
          {values.map((value, index) => (
            <TableCell
              className="border px-2 py-2 text-right text-sm font-normal leading-normal text-gray-800"
              months={periods}
              showMonths={showMonths}
              key={index}
              value={value}
              index={index}
            />
          ))}
        </tr>
      </tbody>
    </table>
  );
};
