export const CloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Stroke / close">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5092 13.7107L8.00001 9.20154L3.49082 13.7107C3.33449 13.8671 3.1356 13.9461 2.88975 13.9461C2.64389 13.9461 2.445 13.8671 2.28867 13.7107C2.13235 13.5544 2.05334 13.3555 2.05334 13.1097C2.05334 12.8638 2.13235 12.6649 2.28867 12.5086L6.79787 7.9994L2.28867 3.49021C2.13235 3.33388 2.05334 3.13499 2.05334 2.88913C2.05334 2.64328 2.13235 2.44438 2.28867 2.28806C2.445 2.13174 2.64389 2.05273 2.88975 2.05273C3.1356 2.05273 3.33449 2.13174 3.49082 2.28806L8.00001 6.79726L12.5092 2.28806C12.6655 2.13174 12.8644 2.05273 13.1103 2.05273C13.3561 2.05273 13.555 2.13174 13.7113 2.28806C13.8677 2.44438 13.9467 2.64328 13.9467 2.88913C13.9467 3.13499 13.8677 3.33388 13.7113 3.49021L9.20215 7.9994L13.7113 12.5086C13.8677 12.6649 13.9467 12.8638 13.9467 13.1097C13.9467 13.3555 13.8677 13.5544 13.7113 13.7107C13.555 13.8671 13.3561 13.9461 13.1103 13.9461C12.8644 13.9461 12.6655 13.8671 12.5092 13.7107Z"
        fill="#1F2436"
      />
    </g>
  </svg>
);
