import { CONTRACTOR_HOURS } from "../../const";
import {
  IDepartment,
  IHiringPlan,
  IHiringPlanCell,
  IPayrollResponse,
  IRole,
} from "../../models/backend";
import {
  Department,
  Employee,
  HeadCountDepartment,
  HeadCountPosition,
  IHpPosition,
  IHpPositionEmployee,
  Position,
} from "../../models/department";

import { MONTH_COUNT } from "../../const";

export const fillEmployees = (hp: IHiringPlan, roleId: number): Employee[] => {
  const result: Employee[] = [];

  if (hp.full_times) {
    const fullTime = hp.full_times.find((ft) => ft.role_id === roleId);
    if (fullTime) {
      result.push({
        typeOfEmployment: "Full time",
        numberOfEmployees: fullTime.count,
      });
    }
  }

  if (hp.freelancers) {
    const freelancer = hp.freelancers.find((ft) => ft.role_id === roleId);
    if (freelancer) {
      result.push({
        typeOfEmployment: "Freelance",
        numberOfEmployees: freelancer.count,
      });
    }
  }

  if (hp.contractors) {
    const contractor = hp.contractors.find((ft) => ft.role_id === roleId);
    if (contractor) {
      result.push({
        typeOfEmployment: "Contract",
        numberOfEmployees: contractor.count,
      });
    }
  }

  return result;
};

export const fillPosition = (
  role: IRole,
  hiringPlan: IHiringPlan | undefined,
  custom: boolean
): Position => {
  return {
    id: role.id,
    name: role.role,
    custom,
    employees: hiringPlan ? fillEmployees(hiringPlan, role.id) : [],
  };
};

export const headcountToHiringPlan = (
  headcount: IHpPositionEmployee[],
  roles: IHpPosition[]
): IHiringPlan => {
  const full_times: IHiringPlanCell[] = [];

  const freelancers: IHiringPlanCell[] = [];

  const contractors: IHiringPlanCell[] = [];
  for (const position of headcount) {
    if (!position.role_name || !position.count) {
      continue;
    }
    if (position.typeOfEmployment === "Full time") {
      if (position.count[0] && position.count[0] !== 0) {
        full_times.push({
          role_id: position.role_id,
          count: position.count[0],
          month: 1,
        });
      }
      for (let i = 1; i < MONTH_COUNT; i++)
        if (position.count[i] && position.count[i] !== position.count[i - 1]) {
          full_times.push({
            role_id: position.role_id,
            count: position.count[i],
            month: i + 1,
          });
        }
    } else if (position.typeOfEmployment === "Freelance") {
      if (position.count[0] !== 0) {
        freelancers.push({
          role_id: position.role_id,
          count: position.count[0],
          month: 1,
        });
      }
      for (let i = 1; i < MONTH_COUNT; i++)
        if (position.count[i] !== position.count[i - 1]) {
          freelancers.push({
            role_id: position.role_id,
            count: position.count[i],
            month: i + 1,
          });
        }
    } else if (position.typeOfEmployment === "Contract") {
      if (position.count[0] !== 0) {
        contractors.push({
          role_id: position.role_id,
          count: position.count[0],
          month: 1,
        });
      }
      for (let i = 1; i < MONTH_COUNT; i++)
        if (position.count[i] !== position.count[i - 1]) {
          contractors.push({
            role_id: position.role_id,
            count: position.count[i],
            month: i + 1,
          });
        }
    }
  }

  const custom_roles = roles.filter((role) => role.custom && role.role_name).map((role) => {
    return {
      role_id: role.role_id,
      role_name: role.role_name,
      department_id: role.department_id,
      salary: role.annual,
    }
  }
  );

  const result: IHiringPlan = {
    contractor_hours: CONTRACTOR_HOURS,
    full_times,
    freelancers,
    contractors,
    custom_roles,
  };

  return result;
};

type DepartmentData = {
  department: IDepartment;
  headcount: number[];
};

type IPayrollTable = DepartmentData[];

export const payrollToTable = (
  summary: IPayrollResponse,
  departments: IDepartment[]
): IPayrollTable => {
  const tableData: IPayrollTable = departments
    .filter((department) =>
      summary.departments.some((s) => s.department_id === department.id)
    )
    .map((department) => ({
      department,
      headcount: summary.departments
        .filter((s) => s.department_id === department.id)
        .reduce((acc, s) => {
          acc[s.month] = s.count;
          return acc;
        }, [] as number[]),
    }));

  return tableData;
};

export const fillHiringPlan = (
  departments: IDepartment[],
  roles: IRole[],
  hiringPlan: IHiringPlan | undefined
) => {
  const hiringPlanDepartments: Department[] = [];
  departments.forEach((department) => {
    const departmentPlan: Department = {
      id: department.id,
      name: department.name,
      active: true,
      positions: [],
    };

    roles.forEach((role) => {
      if (role.department !== department.name) {
        return;
      }

      const position = fillPosition(role, hiringPlan, false);
      departmentPlan.positions.push(position);
    });

    // Custom roles
    hiringPlan?.custom_roles?.forEach((role) => {
      if (role.department_id !== department.id) {
        return;
      }
      const newRole: IRole = {
        department: "",
        id: role.role_id,
        role: role.role_name
      }
      const position = fillPosition(newRole, hiringPlan, true);
      departmentPlan.positions.push(position);
    })

    hiringPlanDepartments.push(departmentPlan);
  });

  return hiringPlanDepartments;
};

export const hiringPlanToHeadcount = (hiringPlan?: IHiringPlanCell[]) => {
  if (!hiringPlan) return [];

  const result: HeadCountPosition[] = [];
  const listRoleID = new Set(hiringPlan.map((hp) => hp.role_id));

  for (const roleID of listRoleID) {
    const role = hiringPlan
      .filter((hp) => hp.role_id === roleID)
      .sort((a, b) => b.month - a.month);
    let currentRoleInfo = role.pop();
    const currentPosition: HeadCountPosition = {
      id: currentRoleInfo?.role_id || 0,
      name: "",
      count: [],
    };
    let currentCount = currentRoleInfo?.month === 1 ? currentRoleInfo.count : 0;
    for (let i = 1; i <= MONTH_COUNT; i++) {
      if (currentRoleInfo?.month === i) {
        currentCount = currentRoleInfo.count;
        currentRoleInfo = role.pop();
      }
      currentPosition.count.push(currentCount);
    }
    result.push(currentPosition);
  }

  return result;
};

export const fillHeadcount = (hiringPlan: IHiringPlan, roles: IRole[]) => {
  const fullTime: Array<HeadCountDepartment> = [];
  const freelance: Array<HeadCountDepartment> = [];
  const contract: Array<HeadCountDepartment> = [];

  const fullTimeHeadcount = hiringPlanToHeadcount(hiringPlan.full_times);
  for (const position of fullTimeHeadcount) {
    const { id } = position;
    const role = roles.find((role) => role.id === id);
    position.name = role?.role ?? "";
    const department = fullTime.find((dep) => dep.name === role?.department);
    if (!department) {
      fullTime.push({
        name: role?.department ?? "",
        positions: [position],
      });
    } else {
      department.positions.push(position);
    }
  }

  const freelanceHeadcount = hiringPlanToHeadcount(hiringPlan.freelancers);
  for (const position of freelanceHeadcount) {
    const { id } = position;
    const role = roles.find((role) => role.id === id);
    position.name = role?.role ?? "";
    const department = freelance.find((dep) => dep.name === role?.department);
    if (!department) {
      freelance.push({
        name: role?.department ?? "",
        positions: [position],
      });
    } else {
      department.positions.push(position);
    }
  }

  const contractHeadcount = hiringPlanToHeadcount(hiringPlan.contractors);
  for (const position of contractHeadcount) {
    const { id } = position;
    const role = roles.find((role) => role.id === id);
    position.name = role?.role ?? "";
    const department = contract.find((dep) => dep.name === role?.department);
    if (!department) {
      contract.push({
        name: role?.department ?? "",
        positions: [position],
      });
    } else {
      department.positions.push(position);
    }
  }

  return {
    "Full time": fullTime,
    Freelance: freelance,
    Contract: contract,
  };
};
