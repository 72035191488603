import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_CREDENTIALS } from "../../const";

const authSlice = createSlice({
  name: "auth",
  initialState: { token: undefined, user: undefined },
  reducers: {
    tokenAdded(state, action) {
      state.token = action.payload;
      localStorage.setItem(
        STORAGE_CREDENTIALS,
        JSON.stringify({ access_token: action.payload }),
      );
    },
    userAdded(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      state.token = undefined;
      state.user = undefined;
      localStorage.removeItem(STORAGE_CREDENTIALS);
    },
  },
});

export const { tokenAdded, userAdded, logout } = authSlice.actions;
export default authSlice.reducer;
