import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectQuery,
  useSaveProjectMutation,
} from "../../services/patric";
import { Tip } from "../../components/Tip";
import { Button } from "../../components/Button";
import { ILocation, IProject } from "../../models/backend";
import { MonthSelector } from "../../components/MonthSelector/MonthSelector";
import { LocationSelector } from "../../components/LocationSelector";
import { YearSelector } from "../../components/YearSelector";
import { Section } from "../../components/Section";
import { Input } from "../../components/Input";
import { PencilIcon } from "../../assets";
import { EditNameDialog } from "../../components/Dialog";
import { Alert, Helper } from "../../components";
import { useError } from "../../hooks/useError";

export const Project = () => {
  const { pid } = useParams();
  const { data, isError, error } = useGetProjectQuery(Number(pid), {
    skip: isNaN(Number(pid)),
  });
  const navigate = useNavigate();
  const [saveProject, saveProjectResult] = useSaveProjectMutation();
  const [createProject, createProjectResult] = useCreateProjectMutation();
  const [deleteProject, deleteProjectResult] = useDeleteProjectMutation();
  const [project, setProject] = useState<IProject | null>(null);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState("");
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const {
    error: errors,
    handleError,
    resetError,
    errorText,
  } = useError({
    start_month: "",
    start_year: "",
    location: "",
    growth_rate: "",
    overall: "",
  });

  useEffect(() => {
    if (
      isError &&
      "status" in error &&
      (error.status === 404 || error.status === 500) &&
      pid !== "new"
    ) {
      console.error(error);
      navigate("/home");
    }
    if (data) {
      if (data.start_month) {
        const [year, month] = data.start_month.split("-");
        setMonth(Number(month));
        setYear(year);
      }
      setProject(data);
    }
  }, [data, isError, error, pid, navigate]);

  useEffect(() => {
    if (isNaN(Number(pid)) && pid !== "new") {
      console.error("Project: invalid project id");
      navigate("/home");
    } else if (pid === "new") {
      setProject({
        project_id: 0,
        project_name: "New project",
        start_month: "",
        growth_rate: 0,
        location: "",
        tax_rate: 0,
        updated_at: "",
      });
      setMonth(0);
      setYear("");
    }
    resetError();
  }, [pid, navigate]);

  useEffect(() => {
    if (saveProjectResult.isSuccess) {
      navigate(`/home/${pid}`);
    } else if (saveProjectResult.isError) {
      handleError("overall", "Please check the fields");
    }

    if (createProjectResult.isSuccess) {
      navigate(`/home/${createProjectResult.data.project_id}`);
    } else if (createProjectResult.isError) {
      console.error(saveProjectResult.error);

      handleError("overall", "Please check the fields");
    }
  }, [saveProjectResult, createProjectResult, navigate, pid]);

  useEffect(
    function deleteProjectEffect() {
      if (deleteProjectResult.isSuccess) {
        navigate(`/home`);
      } else if (deleteProjectResult.isError) {
        handleError("overall", "Can't delete project");
      }
    },
    [deleteProjectResult.isError, deleteProjectResult.isSuccess, navigate],
  );

  const handleSubmit = () => {
    resetError();
    checkMonth(month);
    checkYear(year);
    checkGrowthRate(String(project?.growth_rate || ""));
    checkLocation(project?.location || null);
    if (pid === "new" && project && month && year) {
      const lMonth = month < 10 ? `0${month}` : month;
      const { updated_at, ...newProject } = project;
      createProject({ ...newProject, start_month: `${year}-${lMonth}-01` });
    } else if (project && month && year) {
      const lMonth = month < 10 ? `0${month}` : month;
      const { updated_at, ...newProject } = project;
      saveProject({ ...newProject, start_month: `${year}-${lMonth}-01` });
    } else {
      handleError("overall", "Please fill in all the fields");
    }
  };

  const handleDelete = () => {
    if (project) {
      deleteProject(project.project_id);
    }
  };

  const handleGrowthRate = (value: string) => {
    const growth_rate = isNaN(Number(value)) ? 0 : Number(value);
    setProject((prev) => (prev ? { ...prev, growth_rate } : prev));
    handleError("growth_rate", "");
  };

  const handleTitleChange = (value: string) => {
    setProject((prev) => (prev ? { ...prev, project_name: value } : prev));
  };

  const checkMonth = (value: number) => {
    if (value < 1 || value > 12) {
      handleError("start_month", "Wrong month");
    } else {
      handleError("start_month", "");
    }
  };

  const checkYear = (value: string) => {
    if (value.length !== 4) {
      handleError("start_year", "Wrong year");
    } else {
      handleError("start_year", "");
    }
  };

  const checkLocation = (value: ILocation | null | string) => {
    if (!value) {
      handleError("location", "Please select a location");
    } else {
      handleError("location", "");
    }
  };

  const checkGrowthRate = (value: string) => {
    const growth_rate = isNaN(Number(value)) ? 0 : Number(value);
    if (growth_rate <= 0 || growth_rate > 100) {
      handleError("growth_rate", "Wrong growth rate");
    } else {
      handleError("growth_rate", "");
    }
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen flex-col gap-8 p-10">
      <div>
        <h1 className="text-2xl font-bold leading-loose text-gray-800">
          <div className="flex items-center gap-2">
            <span>{project.project_name}</span>
            <button
              className="text-normal"
              onClick={() => setIsTitleEditable(true)}
            >
              <PencilIcon />
            </button>
          </div>
        </h1>
        {project?.updated_at && (
          <span className="text-sm text-gray-500">
            Last updated: {format(new Date(project.updated_at), "dd/MM/yyyy")}
          </span>
        )}
      </div>

      <Tip caption="Task №1" text="Set project parameters" />

      <Section
        title="Dates"
        description="Enter the a month and a year when you will launch the project"
        className="max-w-xl"
      >
        <div className="mt-4 flex justify-between">
          <MonthSelector
            value={month}
            onChange={setMonth}
            error={errors.start_month !== ""}
          />
          <YearSelector
            value={year}
            onChange={setYear}
            error={errors.start_year !== ""}
          />
        </div>
      </Section>

      <Section
        title="Your location"
        description="Your location will help us determine the taxes you can expect to pay on your revenue"
        className="max-w-xl"
      >
        <div className="mt-3">
          <LocationSelector
            value={project.location}
            error={errors.location !== ""}
            onChange={(location, tax_rate) => {
              if (tax_rate !== undefined) {
                setProject((prev) => {
                  if (!prev) {
                    return prev;
                  }
                  return prev ? { ...prev, location, tax_rate } : prev;
                });
              } else {
                setProject((prev) => (prev ? { ...prev, location } : prev));
              }
            }}
          />
        </div>
      </Section>

      <Section
        title="Taxes"
        description="Taxes will be automatically filled into depending on the location you choose"
        className="max-w-xl"
      >
        <div className="mt-3 flex gap-5">
          <Input
            value={String(project.tax_rate)}
            onChange={(value) => {
              setProject((prev) =>
                prev ? { ...prev, tax_rate: Number(value) } : prev,
              );
            }}
            id="groupTax"
            label="Tax rate"
            type="text"
            postfix="%"
          />
        </div>
      </Section>

      <Section title="Growth rate" className="max-w-xl">
        <div className="mt-6">
          <Input
            value={String(project.growth_rate || "")}
            setValue={handleGrowthRate}
            id="growthRate"
            label="Growth rate"
            type="text"
            postfix="%"
            onBlur={checkGrowthRate}
            error={errors.growth_rate}
          />
        </div>
        <Helper header="Growth rate">
          <p>
            The increase in your revenue from month to month is your Growth
            Rate, measured as a percentage. Revenue increases as you get more
            customers, so a 5% increase in revenue should come from a 5%
            increase in customers.
            <br />
            <span className="font-semibold">
              Typically, a company should experience 1% - 5% growth.
            </span>
          </p>
          <p className="mt-2">
            For a detailed explanation of Compound Monthly Growth Rate (CMGR)
            and how it is calculated,{" "}
            <a
              className="text-blue-500 hover:underline"
              href="https://www.upgrowth.in/compounded-monthly-growth-rate-understanding-and-calculating-compounded-monthly-growth-rates/"
              target="_blank" rel="noreferrer"
            >
              click on the link
            </a>
            .
          </p>
        </Helper>
      </Section>

      {errorText && <Alert text={errorText} type="error" />}

      <div className="flex gap-5">
        <Button onClick={handleDelete} style="outline">
          Delete project
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
      <EditNameDialog
        isOpen={isTitleEditable}
        setIsOpen={setIsTitleEditable}
        confirm={handleTitleChange}
        value={project.project_name}
      />
    </div>
  );
};
