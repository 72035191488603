import { useRef, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { ChevronDownIcon } from "../../assets";

export const InputWithProposals = ({
  value,
  onChange,
  proposals,
  placeholder,
}: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  proposals?: string[];
  placeholder?: string;
}) => {
  const [showProposals, setShowProposals] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const proposalsRef = useClickAway<HTMLDivElement>(() => {
    setShowProposals(false);
  });

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "Other") {
      inputRef.current?.focus();
    } else {
      onChange(e);
    }
  };

  return (
    <div className="relative inline-block w-full max-w-[544px] text-left">
      <div
        className={`inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300
      hover:bg-gray-50`}
      >
        <input
          ref={inputRef}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className="h-12 w-full bg-transparent px-2"
        />
        <button
          className="absolute bottom-3.5 end-2.5 inline-flex h-5 w-5 items-center justify-center"
          onClick={() => setShowProposals((value) => !value)}
        >
          <ChevronDownIcon />
        </button>
        {showProposals && (
          <div
            className="absolute left-0 top-12 z-10 w-full rounded-md border border-gray-300 bg-white shadow-md"
            ref={proposalsRef}
          >
            {proposals?.concat("Other").map((proposal) => (
              <div
                key={proposal}
                className="cursor-pointer px-4 py-3 hover:bg-gray-100"
                onClick={() => {
                  onLocalChange({ target: { value: proposal } } as any);
                  setShowProposals(false);
                }}
              >
                {proposal}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
