import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { patricApi } from "./services/patric";
import { apiErrorMiddleware } from "./utils/apiErrorMiddleware";
import authSlice from "./features/auth/authSlice";

export const store = configureStore({
  reducer: {
    [patricApi.reducerPath]: patricApi.reducer,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(patricApi.middleware, apiErrorMiddleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
