export const GraphUp = () => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.917 33.8346L22.4584 28.2932C22.9116 27.8402 23.5262 27.5857 24.167 27.5857C24.8078 27.5857 25.4224 27.8402 25.8756 28.2932L29.7084 32.1261C30.1616 32.5791 30.7762 32.8336 31.417 32.8336C32.0578 32.8336 32.6724 32.5791 33.1256 32.1261L41.0837 24.168M41.0837 24.168V30.2096M41.0837 24.168H35.042"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.1668 29.0006C53.1668 40.3928 53.1668 46.0889 49.6264 49.6269C46.0908 53.1673 40.3923 53.1673 29.0002 53.1673C17.608 53.1673 11.9119 53.1673 8.3715 49.6269C4.8335 46.0913 4.8335 40.3928 4.8335 29.0006C4.8335 17.6085 4.8335 11.9124 8.3715 8.37198C11.9143 4.83398 17.608 4.83398 29.0002 4.83398C40.3923 4.83398 46.0884 4.83398 49.6264 8.37198C51.9802 10.7258 52.7681 14.0342 53.0339 19.334"
      stroke="#CFD2DB"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
