import { Navigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";

export const PasswordRecovery = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //const username = (event.target as any).username.value;
  };

  return (
    <>
      <div className="flex items-center">
        <div className="w-64">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <h1 className="mt-16 text-3xl">Forgot password</h1>
      <p className="mt-4">
        Please enter the email address associated with your account.
      </p>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6">
          <Input type="text" id="username" label="Email" />
        </div>
        <div className="mt-10 w-full">
          <Button type="submit">Send</Button>
        </div>
      </form>
    </>
  );
};
